


/**
 * Validate the email. The email must not be empty and must be in the correct format.
 *
 * @param {string} email - The email to validate.
 *
 * @return {string} - The error message.
 */
export const validateEmail = (email) => {
    if (email === null) {
        return null;
    }

    if (email === '') {
        return 'El correo electrónico es requerido.';
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        return 'El correo electrónico no es válido.';
    }

    return null;
};


/**
 * Validate the password. The password must not be empty, have at least 8 characters, at least one uppercase letter, at least one lowercase letter, and at least one number.
 *
 * @param {string} password - The password to validate.
 *
 * @return {string} - The error message.
 */
export const validatePassword = (password) => {
    if (password === null) {
        return null;
    }

    if (password === '') {
        return 'La contraseña es requerida.';
    }

    if (password.length < 8) {
        return 'La contraseña debe tener al menos 8 caracteres.';
    }

    if (!/[a-z]/.test(password)) {
        return 'La contraseña debe tener al menos una letra minúscula.';
    }

    if (!/[A-Z]/.test(password)) {
        return 'La contraseña debe tener al menos una letra mayúscula.';
    }

    if (!/[0-9]/.test(password)) {
        return 'La contraseña debe tener al menos un número.';
    }

    return null;
};


/**
 * Validate the password confirmation. The password confirmation must match the password.
 *
 * @param {string} password - The password.
 * @param {string} passwordConfirmation - The password to validate.
 *
 * @return {string} - The error message.
 */
export const validatePasswordConfirmation = (password, passwordConfirmation) => {
    if (passwordConfirmation === null) {
        return null;
    }

    if (passwordConfirmation === '') {
        return 'La confirmación de la contraseña es requerida.';
    }

    if (password !== passwordConfirmation) {
        return 'Las contraseñas no coinciden.';
    }

    return null;
};


/**
 * Validate that the field passed is not empty.
 *
 * @param {string} field - The field to validate.
 *
 * @return {string|null} - The error message or null if the field is not empty.
 */
export const validateNotEmpty = (field, message = 'Este campo es requerido.') => {
    if (field === '') {
        return message;
    }

    return null;
};


/**
 * Validate that the field passed is not empty.
 *
 * @param {string} field - The field to validate.
 *
 * @return {string|null} - The error message or null if the field is not empty.
 */
export const validateBirthdayNotEmpty = (field, message = 'Este campo es requerido.') => {
    if (field === null) {
        return message;
    }

    return null;
};


/**
 * Validate the microchip field. The microchip must have exactly 15 digits and only contain numbers.
 *
 * @param {string} microchip - The microchip to validate.
 *
 * @return {string} - The error message.
 */
export const validateMicrochip = (microchip) => {
    if (microchip === null) {
        return null;
    }

    if (!/^[0-9]+$/.test(microchip)) {
        return 'El microchip solo puede contener números.';
    }

    if (microchip.length !== 15) {
        return 'El microchip debe tener exactamente 15 dígitos.';
    }

    return null;
};


/**
 * Validate the phone field.
 *
 * @param {string} phone - The phone to validate.
 *
 * @return {string} - The error message.
 */
export const validatePhone = (phone) => {
    if (phone === '') {
        return 'El teléfono es requerido.';
    }

    if (!/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(phone)) {
        return 'El teléfono no es válido.';
    }

    return null;
};


export default {
    validateEmail,
    validatePassword,
    validatePasswordConfirmation,
    validateNotEmpty,
    validateBirthdayNotEmpty,
    validateMicrochip,
    validatePhone,
};
