// Import modules.
import { React } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Import pages.
import ChangePassword from './pages/ChangePassword/ChangePassword.jsx';
import Homepage from './pages/Home/Homepage.jsx';
import Login from './pages/Login/Login.jsx';
import Logout from './pages/Logout/Logout.jsx';
import Page404 from './pages/404/Page404.jsx';
import RecoverPassword from './pages/RecoverPassword/RecoverPassword.jsx';
import Register from './pages/Register/Register.jsx';

// Panel de Usuario.
import AddPet from './pages/Pet/Add.jsx';
import EditPet from './pages/Pet/Edit.jsx';
import PetProfile from './pages/Pet/Profile.jsx';
import PrivateProfilePet from './pages/Pet/PrivateProfile.jsx';

import EditUser from './pages/User/Edit.jsx';
import UserPanel from './pages/User/Panel.jsx';

import MedicalRecord from './pages/MedicalRecord/MedicalRecord.jsx';
import Subscription from './pages/Subscription/Subscription.jsx';
import PaymentHistory from './pages/PaymentHistory/PaymentHistory.jsx';
import ActivateQR from './pages/ActivateQR/ActivateQR.jsx';

// Panel de Admin.
import AdminPanel from './pages/AdminPanel/AdminPanel.jsx';
import AdminQRs from './pages/AdminPanel/QRs/QRs.jsx';

// Import assets.
import './custom.css';


function App() {
    return (
        <Router>
            <Routes>
                {/* Generales */}
                <Route path='/' element={<Homepage />} />
                <Route path='/login' element={<Login />} />
                <Route path='/perfil/:token' element={<PetProfile />} />
                <Route path='/registro' element={<Register />} />
                <Route path='/logout' element={<Logout />} />
                <Route path='/recuperar-contraseña' element={<RecoverPassword />} />
                <Route path='/cambiar-contraseña/:token' element={<ChangePassword />} />
                <Route path='*' element={<Page404 />} />

                {/* Panel de Usuario */}
                <Route path='/panel-usuario' element={<UserPanel />} />
                <Route path='/panel-usuario/editar-perfil/:id' element={<EditUser />} />
                
                <Route path='/panel-usuario/agregar-mascota' element={<AddPet />} />
                <Route path='/panel-usuario/mascota/:id' element={<EditPet />} />
                <Route path='/panel-usuario/mascota/:id/perfil' element={<PrivateProfilePet />} />

                <Route path='/panel-usuario/mascota/:id/ficha-medica' element={<MedicalRecord />} />
                <Route path='/panel-usuario/suscripciones/:id' element={<Subscription />} />
                <Route path='/panel-usuario/historial-pagos/:id' element={<PaymentHistory />} />
                <Route path='/panel-usuario/activar-qr/:token' element={<ActivateQR />} />

                {/* Panel de Admin */}
                <Route path='/panel-admin' element={<AdminPanel />} />
                <Route path='/panel-admin/qrs' element={<AdminQRs />} />
            </Routes>
        </Router>
    );
}

export default App;
