import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import AboutUs from '../../components/AboutUs/AboutUs.jsx';
import Banner from '../../components/Banner/Banner.jsx';
import Benefits from '../../components/Benefits/Benefits.jsx';
import Reviews from '../../components/Reviews/Reviews.jsx';
import FAQs from '../../components/FAQs/FAQs.jsx';
import HowItWorks from '../../components/HowItWorks/HowItWorks.jsx';
import PageContent from '../../components/PageContent/PageContent.jsx';

import './Homepage.css';


export default function Homepage2() {
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));

            if (element) {
                const yOffset = -80;

                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({ top: y, behavior: 'smooth' });
            }
        }
    }, [location]);


    return (
        <div id='homepage'>
            <PageContent withBackground={false}>
                <Banner />

                <Benefits />
                
                <HowItWorks />

                <Reviews />

                <FAQs />

                <AboutUs />
            </PageContent>
        </div>
    );
}
