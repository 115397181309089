import React, { useEffect, useState } from 'react';
import { Divider } from '@nextui-org/react';
import Slider from 'react-slick';

import PawCard from '../PawCard/PawCard.jsx';
import FadeInDiv from '../Animated/FadeInDiv.jsx';

import LogoFullColorful from '../Icons/LogoFullColorful.jsx';
import ChevronLeft from '../Icons/ChevronLeft.jsx';
import ChevronRight from '../Icons/ChevronRight.jsx';

import petsImage from '../../assets/images/dog1.png';
import heartGirlDog from '../../assets/images/heart-girl-dog.png';
import './HowItWorks.css';


const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <ChevronLeft />,
    nextArrow: <ChevronRight />,
};

const howItWorks = [
    {
        step: 1,
        title: '¡Protegé a tu mascota!',
        description: ['Completá el perfil', 'de tu mascota', 'y activá su Tag.'],
    },
    {
        step: 2,
        title: '¿Se extravió tu mascota?',
        description: ['¡Avísanos enseguida!', 'Actuar rápido', 'es clave.'],
    },
    {
        step: 3,
        title: 'Comenzamos su búsqueda',
        description: ['con publicidad', 'en la zona', 'de extravío.'],
    },
    {
        step: 4,
        title: 'Sabrás su ubicación',
        description: ['cuando escaneen tu Tag.', 'No olvides', 'revisar tu correo.'],
    },
    {
        step: 5,
        title: '¡Tu mascota vuelve a casa!',
        description: ['Se contactarán contigo', 'tan pronto como', 'escaneen su Tag.'],
    },
    {
        img: heartGirlDog,
    },
];


export default function HowItWorks() {
    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);

    useEffect(() => {
        function handleResize() {
            setDeviceWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div id='how-it-works' className='how-it-works py-8 px-2'>
            <div className='flex flex-col'>
                <div className='flex justify-center items-start relative'>
                    <div className="image-container">
                        <img src={petsImage} alt='Pets' className='pet-image' />
                    </div>

                    <FadeInDiv>
                        <div className='title-content flex flex-col items-center'>
                            <h2 className='title color-dark-light'>¿Cómo funciona?</h2>
                            
                            <LogoFullColorful />
                        </div>
                    </FadeInDiv>

                    <Divider className='divider bg-primary absolute' />
                </div>

                {deviceWidth < 700
                    ? <FadeInDiv>
                        <div className='flex justify-center items-center mt-4'>
                            <Slider {...sliderSettings}>
                                {howItWorks.map((review, index) => (
                                    review.img
                                        ? <img key={index} src={review.img} alt='HeartGirlDog' />
                                        : <PawCard key={index} step={review} className={index % 2 === 0 ? 'fill-dark-light' : 'fill-primary-light'} />
                                ))}
                            </Slider>
                        </div>
                    </FadeInDiv>
                    :
                    <div className='steps-container flex justify-center items-center flex-wrap mt-4'>
                        {howItWorks.map((step, index) => {
                            return (
                                <FadeInDiv key={index} viewportAmount={0.5} delay={index * 0.2}>
                                    {step.img
                                        ? <img key={index} src={step.img} alt='HeartGirlDog' />
                                        : <PawCard key={index} step={step} className={index % 2 === 0 ? 'fill-dark-light' : 'fill-primary-light'} />
                                    }
                                </FadeInDiv>
                            );
                        })}
                    </div>
                }
            </div>
        </div>
    );
}
