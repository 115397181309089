// Import modules.
import React from 'react';
import Slider from 'react-slick';
import { Button } from '@nextui-org/react';

// Import icons.
import ChevronLeft from '../Icons/ChevronLeft.jsx';
import ChevronRight from '../Icons/ChevronRight.jsx';

// Import assets.
import slide1 from '../../assets/images/Slide1.jpg';
import slide2 from '../../assets/images/Slide2.jpg';
import slide3 from '../../assets/images/Slide3.jpg';
import keyChain from '../../assets/images/keyChain.png';

import './Banner.css';

const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    prevArrow: <ChevronLeft />,
    nextArrow: <ChevronRight />,
};

const slides = [
    {
        title: ['Ellos seguros', 'Y vos tranquilo'],
        subtitle: '¡Unite a nuestra comunidad!',
        image: slide1,
    },
    {
        title: ['Formá parte de', 'nuestra comunidad'],
        subtitle: null,
        image: slide2,
    },
    {
        title: ['Formá parte de', 'nuestra comunidad'],
        subtitle: null,
        image: slide3,
    }
];



export default function Banner() {
    return (
        <div className='banner'>
            <Slider {...sliderSettings}>
                {slides.map((slide, index) => <div key={index}>
                    <div className='slide' style={{ backgroundImage: `url(${slide.image})` }}>
                        <div className='container flex h-full'>
                            <div className='img-container'>
                                <img src={keyChain} alt='keyChain' className='keyChain' />
                            </div>

                            <div className='flex flex-col justify-center text-white h-full'>
                                {slide.title.map((title, index) => <h2 key={index}>{title}</h2>)}
                                {slide.subtitle && <h3>{slide.subtitle}</h3>}

                                <Button
                                    color='primary'
                                    className='bg-primary uppercase shadow-lg'
                                    radius='sm'
                                    onClick={() => window.location.href = '/login'}
                                >
                                    ¡Accedé!
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>)}
            </Slider>
        </div>
    );
}
