// Import required modules.
import { React, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
    Button,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from '@nextui-org/react';

// Import required icons.
import DangerIcon from '../../../components/Icons/DangerIcon';
import QRCode from '../../../components/Icons/QRCode.jsx';


export default function DeleteQR({ isOpen, onClose, selectedQR, fetchQRs, setAlertType, setAlertMessage }) {
    // Utilities.
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (selectedQR.id === null) {
            setButtonDisabled(true);
        } else {
            setButtonDisabled(false);
        }
    }, [selectedQR]);


    /**
     * Handle the delete of a QR.
     *
     * @returns {void}
     */
    const handleDeleteQr = () => {
        // Change the loading state.
        setButtonDisabled(true);
        setIsLoading(true);

        // Send the request to the API.
        axios.delete(`${process.env.REACT_APP_API_ENDPOINT}qrs/${selectedQR.id}`)
            .then((response) => {
                response = response.data;

                // Change the loading state.
                setButtonDisabled(false);
                setIsLoading(false);

                // Show a response message.
                setAlertType(response.success ? 'success' : 'error');
                setAlertMessage([response.message]);

                // Close the modal.
                onClose();

                // Fetch the QR list.
                fetchQRs();

                // Remove the message after 5 seconds.
                setTimeout(() => {
                    setAlertType('');
                    setAlertMessage('');
                }, 5000);
            })
            .catch(() => {
                // Change the loading state.
                setButtonDisabled(false);
                setIsLoading(false);

                // Show the alert.
                setAlertType('error');
                setAlertMessage('Hubo un error al eliminar el QR.');
            });
    };


    return (
        <Modal
            backdrop='blur'
            isOpen={isOpen}
            placement='center'
            onClose={onClose}
            className='modal modal-delete'
            classNames={{
                closeButton: 'z-1'
            }}
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className='flex justify-between items-center gap-1 mb-2'>
                            <h2 className='uppercase'>Eliminar QR</h2>
                            <QRCode />
                        </ModalHeader>

                        <ModalBody className='flex flex-col items-center'>
                            <DangerIcon className='fill-danger'/>
                            <h3 className=''>¿Estás seguro que querés eliminar el token <span className='bold'>{selectedQR.tokenQr}</span>?</h3>
                            <h4>Esto hará que el token quede inhabilitado para futuros usos y ya no podrá verlo en esta interfaz.</h4>
                        </ModalBody>

                        {/* Footer */}
                        <ModalFooter className='flex flex-col'>
                            <Button
                                className='uppercase shadow-md mb-1'
                                color='danger'
                                isDisabled={buttonDisabled}
                                isLoading={isLoading}
                                onPress={handleDeleteQr}
                            >
                                Eliminar
                            </Button>

                            <Button className='bg-light uppercase shadow-md' color='light' onPress={onClose}>
                                Cerrar
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
}


DeleteQR.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    selectedQR: PropTypes.object.isRequired,
    fetchQRs: PropTypes.func.isRequired,
    setAlertType: PropTypes.func.isRequired,
    setAlertMessage: PropTypes.func.isRequired,
};