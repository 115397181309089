// Import modules.
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter } from '@nextui-org/react';

// Define properties.
ResponseModal.propTypes = {
    disclosure: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.array.isRequired,
};


export default function ResponseModal({ disclosure, title, content }) {
    return (
        <>
            <Modal
                backdrop='opaque' 
                isOpen={disclosure.isOpen} 
                onOpenChange={disclosure.onOpenChange}
                placement='center'
                classNames={{
                    wrapper: 'modal',
                    backdrop: 'bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20'
                }}
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className='modal-header flex justify-center items-center gap-4 relative'>
                                <h3 className='uppercase text-center'>{title}</h3>
                                {/* <Medical /> */}
                            </ModalHeader>

                            <ModalBody className='flex flex-col px-6 mt-2'>
                                {content && content.map((content, index) => {
                                    return (
                                        <h4 key={index} className='text-center'>{content}</h4>
                                    );
                                })}
                            </ModalBody>

                            {/* Footer */}
                            <ModalFooter className='pt-0'>
                                <Button
                                    color='default'
                                    className='w-full uppercase shadow-lg mt-2 mx-auto'
                                    radius='sm'
                                    onPress={onClose}
                                    style={{ maxWidth: '300px' }}
                                >
                                    Cerrar
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
}
