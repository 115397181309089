// Import modules.
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Autocomplete, AutocompleteItem, Button, DatePicker, Input, Textarea, useDisclosure } from '@nextui-org/react';
import { getLocalTimeZone, parseDate, today } from '@internationalized/date';
import { I18nProvider } from '@react-aria/i18n';
import { species, dogBreeds, catBreeds, sexes, sterilizedArray, parseDate as customParseDate, completeDate } from '../../utils/helpers';
import inputService from '../../services/inputs.js';
import petService from '../../services/pets.js';
import userService from '../../services/users.js';

// Import components.
import MessageModal from './MessageModal.jsx';
import PageContent from '../../components/PageContent/PageContent.jsx';

// Import icons.
import Camera from '../../components/Icons/Camera.jsx';

// Import assets.
import petNoPicture from '../../assets/images/pet-no-picture.png';
import './pets.css';


export default function Update() {
    // Declare states.
    const [photo, setPhoto] = useState('');
    const [photoFile, setPhotoFile] = useState('');
    const [name, setName] = useState(null);
    const [nameError, setNameError] = useState(null);
    const [specie, setSpecie] = useState(null);
    const [specieError, setSpecieError] = useState(null);
    const [breed, setBreed] = useState(null);
    const [breedError, setBreedError] = useState(null);
    const [birthDate, setBirthDate] = useState(null);
    const [birthDateError, setBirthDateError] = useState(null);
    const [sex, setSex] = useState(null);
    const [sexError, setSexError] = useState(null);
    const [sterilized, setSterilized] = useState(false);
    const [sterilizedError, setSterilizedError] = useState(null);
    const [microchip, setMicrochip] = useState(null);
    const [microchipError, setMicrochipError] = useState(null);
    const [clarification, setClarification] = useState(null);
    const [clarificationError, setClarificationError] = useState(null);
    const [pet, setPet] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    // Utilities.
    const [userRole, setUserRole] = useState(null);
    const [isPageLoading, setIsPageLoading] = useState(true);

    // Disclosures.
    const messageModal = useDisclosure();

    // Get the pet ID from the URL.
    const { id } = useParams();
    const isValidId = /^[0-9]{1,}$/.test(id);

    if (!isValidId) {
        window.location.href = '/panel-usuario';
    }

    // Check user session.
    useEffect(() => {
        /**
         * Check user session. The function is declared as async to use the await keyword.
         *
         * @returns {Promise<void>}
         */
        const checkSession = async () => {
            setIsPageLoading(true);

            try {
                const checkSessionResponse = await userService.checkSession();

                if (!checkSessionResponse.success) {
                    throw checkSessionResponse.errors;
                }

                const userSession = JSON.parse(atob(checkSessionResponse.data));

                setUserRole(userSession.role);

                const response = await petService.get(id);

                if (!response.success) {
                    throw response.errors;
                }

                setPet(response.pet);
                setPhoto(response.pet.photo !== null ? `${process.env.REACT_APP_ENDPOINT}img/pets/profile/${response.pet.photo}` : '');
                setName(response.pet.name);
                setSpecie(response.pet.specie);
                setBreed(response.pet.breed);
                setBirthDate(response.pet.birthday !== null ? parseDate(response.pet.birthday) : null);
                setSex(response.pet.sex);
                setSterilized(response.pet.sterilized);
                setMicrochip(response.pet.microchip);
                setClarification(response.pet.clarification);
                setIsPageLoading(false);
            } catch (error) {
                setIsPageLoading(false);
            }
        };

        checkSession();
    }, []);


    // Handle form validation.
    useEffect(() => {
        // Set error messages.
        const nameError = inputService.validateNotEmpty(name);
        const specieError = inputService.validateNotEmpty(specie);
        const breedError = inputService.validateNotEmpty(breed);
        const birthDateError = inputService.validateNotEmpty(birthDate);
        const sexError = inputService.validateNotEmpty(sex);

        setNameError(nameError);
        setSpecieError(specieError);
        setBreedError(breedError);
        setBirthDateError(birthDateError);
        setSexError(sexError);

        // Check if the submit button should be enabled.
        if (name !== null && specie !== null && breed !== null && birthDate !== null && sex !== null && nameError === null && specieError === null && breedError === null && birthDateError === null && sexError === null && sterilizedError === null) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [name, specie, breed, birthDate, sex]);


    // Handle form validation.
    useEffect(() => {
        // Should not be any error.
        if (nameError !== null || specieError !== null || breedError !== null || birthDate !== null || sexError !== null || sterilizedError !== null || microchipError !== null || clarificationError !== null) {
            setIsDisabled(true);
        }
    }, [nameError, specieError, breedError, birthDateError, sexError, sterilizedError, microchipError, clarificationError]);


    // If any field is different from the original pet data, enable the update button.
    useEffect(() => {
        if (pet !== null) {
            let birthday = completeDate(customParseDate(birthDate));
            
            if (name !== pet.name || specie !== pet.specie || breed !== pet.breed || birthday !== pet.birthday || sex !== pet.sex || sterilized != pet.sterilized || microchip !== pet.microchip || clarification !== pet.clarification) {
                setIsDisabled(false);
            } else {
                setIsDisabled(true);
            }
        }
    }, [name, specie, breed, birthDate, sex, sterilized, microchip, clarification]);


    /**
     * Handling the photo change and set the photo state.
     *
     * @param {Object} target The target object.
     *
     * @returns {void}
     */
    const handlePhotoChange = (target) => {
        // Get the file.
        const file = target.files[0];

        // Save the file.
        setPhotoFile(file);
        
        // Check if the file is an image.
        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();

            reader.onload = (event) => {
                setPhoto(event.target.result);
            };

            reader.readAsDataURL(file);
        }
    };


    /**
     * Handle the update pet event.
     *
     * @returns {void}
     */
    const handleUpdatePet = async () => {
        // Change loading state.
        setIsLoading(true);

        try {
            // Change loading state.
            const response = await petService.update(id, photoFile, name, specie, breed, birthDate, sex, sterilized, microchip, clarification);

            // Scroll to top.
            window.scrollTo(0, 0);

            if (!response.success) {
                throw response.errors;
            }

            // Show modal.
            messageModal.onOpen();

            // After 5 seconds, redirect to the user panel.
            setTimeout(() => {
                window.location.href = '/panel-usuario';
            }, 5000);

            // Change loading state.
            setIsLoading(false);
        } catch (errors) {
            // Set error messages.
            setNameError(errors.name !== undefined ? errors.name : '');
            setSpecieError(errors.specie !== undefined ? errors.specie : '');
            setBreedError(errors.breed !== undefined ? errors.breed : '');
            setBirthDateError(errors.birthday !== undefined ? errors.birthday : '');
            setSexError(errors.sex !== undefined ? errors.sex : '');
            setSterilizedError(errors.sterilized !== undefined ? errors.sterilized : '');
            setMicrochipError(errors.microchip !== undefined ? errors.microchip : '');
            setClarificationError(errors.clarification !== undefined ? errors.clarification : '');

            // Change loading state.
            setIsLoading(false);
        }
    };


    return (
        <div id='updatePetPage' className='page'>
            <PageContent withBackground isAwaiting={isPageLoading} userRole={userRole}>
                {!isPageLoading && (
                    <>
                        <div className='title w-full mb-4'>
                            <h1 className='main-title text-center mb-2' onClick={messageModal.onOpen}>Editar mascota</h1>
                        </div>
                        
                        <form className='flex flex-col justify-center items-center relative mb-8'>
                            {/* Photo */}
                            <div id='photoContainer' className='flex flex-col items-center rounded-full shadow-lg mb-4 relative'>
                                {photo !== '' && (
                                    <img src={photo} alt='pet-profile-picture' className='rounded-full'/>
                                ) || (
                                    <img src={petNoPicture} alt='pet-profile-picture' className='rounded-full'/>
                                )}

                                <div id='editPhoto' className='bg-primary shadow-md absolute'>
                                    <div className='change-photo-container relative'>
                                        <input
                                            className='file-input absolute'
                                            type='file'
                                            onChange={(event) => handlePhotoChange(event.target)}
                                        />

                                        <Camera />
                                    </div>
                                </div>
                            </div>

                            {/* Name */}
                            <Input
                                type='text'
                                label='Nombre'
                                variant='underlined'
                                defaultValue=''
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                onBlur={(e) => {
                                    setName(e.target.value);
                                    setNameError(inputService.validateNotEmpty(name, 'Este campo es requerido.'));
                                }}
                                isInvalid={nameError !== null}
                                errorMessage={nameError}
                                isDisabled={isLoading}
                                className='mb-4'
                            />

                            {/* Specie */}
                            <Autocomplete 
                                label='Especie'
                                variant='underlined'
                                disabledKeys={['selectOption']}
                                selectedKey={specie}
                                onSelectionChange={(value) => setSpecie(value)}
                                onBlur={() => {
                                    setSpecieError(inputService.validateNotEmpty(specie, 'Este campo es requerido.'));
                                }}
                                isInvalid={specieError !== null}
                                errorMessage={specieError}
                                isDisabled={isLoading}
                                className='mb-4'
                            >
                                {species.map((animal) => (
                                    <AutocompleteItem 
                                        key={animal.value}
                                    >
                                        {animal.label}
                                    </AutocompleteItem>
                                ))}
                            </Autocomplete>

                            {/* Breed */}
                            {specie === 'perro' && (
                                <Autocomplete 
                                    label='Raza'
                                    variant='underlined'
                                    disabledKeys={['selectOption']}
                                    selectedKey={breed}
                                    onSelectionChange={(value) => setBreed(value)}
                                    onBlur={() => {
                                        setBreedError(inputService.validateNotEmpty(breed, 'Este campo es requerido.'));
                                    }}
                                    isInvalid={breedError !== null}
                                    errorMessage={breedError}
                                    isDisabled={isLoading}
                                    className='mb-4'
                                >
                                    {dogBreeds.map((raza) => (
                                        <AutocompleteItem 
                                            key={raza.value}
                                        >
                                            {raza.label}
                                        </AutocompleteItem>
                                    ))}
                                </Autocomplete>
                            ) || specie === 'gato' && (
                                <Autocomplete 
                                    label='Raza'
                                    variant='underlined'
                                    disabledKeys={['selectOption']}
                                    selectedKey={breed}
                                    onSelectionChange={(value) => setBreed(value)}
                                    onBlur={() => {
                                        setBreedError(inputService.validateNotEmpty(breed, 'Este campo es requerido.'));
                                    }}
                                    isInvalid={breedError !== null}
                                    errorMessage={breedError}
                                    isDisabled={isLoading}
                                    className='mb-4'
                                >   
                                    {catBreeds.map((raza) => (
                                        <AutocompleteItem 
                                            key={raza.value}
                                        >
                                            {raza.label}
                                        </AutocompleteItem>
                                    ))}
                                </Autocomplete>
                            ) || (specie === 'otro' || specie === '')  && (
                                <Input
                                    type='text'
                                    label='Raza'
                                    variant='underlined'
                                    value={breed}
                                    onChange={(event) => setBreed(event.target.value)}
                                    onBlur={() => {
                                        setBreedError(inputService.validateNotEmpty(breed, 'Este campo es requerido.'));
                                    }}
                                    isInvalid={breedError !== null}
                                    errorMessage={breedError}
                                    isDisabled={isLoading}
                                    className='mb-4'
                                />
                            )}

                            {/* Birthday */}
                            <I18nProvider locale='es-ES'>
                                <DatePicker
                                    label='Fecha de nacimiento'
                                    variant='underlined'
                                    showMonthAndYearPickers
                                    value={birthDate}
                                    maxValue={today(getLocalTimeZone())}
                                    onChange={(date) => setBirthDate(date)}
                                    onBlur={() => {
                                        setBirthDateError(inputService.validateBirthdayNotEmpty(birthDate, 'Este campo es requerido.'));
                                    }}
                                    isInvalid={birthDateError !== null}
                                    errorMessage={birthDateError}
                                    isDisabled={isLoading}
                                    className='mb-4'
                                />
                            </I18nProvider>

                            {/* Sex */}
                            <Autocomplete
                                label='Sexo'
                                variant='underlined'
                                selectedKey={sex}
                                disabledKeys={['selectOption']}
                                onSelectionChange={(value) => setSex(value)}
                                onBlur={() => {
                                    setSexError(inputService.validateNotEmpty(sex, 'Este campo es requerido.'));
                                }}
                                isInvalid={sexError !== null}
                                errorMessage={sexError}
                                isDisabled={isLoading}
                                className='mb-4'
                            >
                                {sexes.map((sex) => (
                                    <AutocompleteItem key={sex.value}>{sex.label}</AutocompleteItem>
                                ))}
                            </Autocomplete>

                            {/* Sterilized */}
                            <Autocomplete
                                label={sex !== 'macho' ? '¿Está esterilizada?' : '¿Está esterilizado?'}
                                variant='underlined'
                                selectedKey={sterilized}
                                disabledKeys={['selectOption']}
                                onSelectionChange={(value) => setSterilized(value)}
                                onBlur={() => {
                                    setSterilizedError(inputService.validateNotEmpty(sterilized, 'Este campo es requerido.'));
                                }}
                                isInvalid={sterilizedError !== null}
                                errorMessage={sterilizedError}
                                isDisabled={isLoading}
                                className='mb-4'
                            >
                                {sterilizedArray.map((item) => (
                                    <AutocompleteItem key={item.value}>{item.label}</AutocompleteItem>
                                ))}
                            </Autocomplete>

                            {/* Microchip */} 
                            <Input
                                type='text'
                                label='Microchip'
                                variant='underlined'
                                defaultValue=''
                                value={microchip}
                                onChange={(e) => setMicrochip(e.target.value)}
                                onBlur={() => {
                                    setMicrochipError(inputService.validateNotEmpty(microchip, 'Este campo es requerido.'));
                                }}
                                isInvalid={microchipError !== null}
                                errorMessage={microchipError}
                                isDisabled={isLoading}
                                className='mb-4'
                            />

                            {/* Aclaraciones */}
                            <Textarea
                                label='Observaciones'
                                variant='underlined'
                                minRows={1}
                                placeholder='Agregá información importante como medicamentos, alergias, recompensa, etc.'
                                value={clarification}
                                onChange={(event) => setClarification(event.target.value)}
                                style={{ formSizing: 'fit-content' }}
                                isInvalid={clarificationError !== null}
                                errorMessage={clarificationError}
                                isDisabled={isLoading}
                            />
                            
                            {/* Update Button */}
                            <Button
                                color='primary'
                                className='w-full uppercase shadow-lg mt-4 mx-auto'
                                radius='sm'
                                onClick={handleUpdatePet}
                                isDisabled={isDisabled}
                                isLoading={isLoading}
                                style={{ maxWidth: '300px' }}
                            >
                                Guardar cambios
                            </Button>

                            <Button
                                color='default'
                                className='w-full uppercase shadow-lg mt-2 mx-auto'
                                radius='sm'
                                onClick={() => window.location.href = '/panel-usuario'}
                                isDisabled={isLoading}
                                style={{ maxWidth: '300px' }}
                            >
                                Cancelar
                            </Button>
                        </form>
                    </>
                )}

                <MessageModal disclosure={messageModal} isAddModal={false} />
            </PageContent>
        </div>
    );
}
