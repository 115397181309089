// Import modules.
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Input, ModalBody, ModalFooter, ModalHeader, useDisclosure } from '@nextui-org/react';

import accountService from '../../services/account.js';
import inputService from '../../services/inputs.js';
import userService from '../../services/users.js';

// Import components.
import PageContent from '../../components/PageContent/PageContent.jsx';
import GenericModal from '../../components/Modal/Modal.jsx';

// Import icons.
import Eye from '../../components/Icons/Eye.jsx';
import EyeSlash from '../../components/Icons/EyeSlash.jsx';


export default function ChangePassword() {
    // Declare states.
    const [password, setPassword] = useState(null);
    const [passwordError, setPasswordError] = useState('');
    const [passwordIsVisible, setPasswordIsVisible] = useState(false);
    const [passwordConfirmation, setPasswordConfirmation] = useState(null);
    const [passwordConfirmationError, setPasswordConfirmationError] = useState('');
    const [passwordConfirmationIsVisible, setPasswordConfirmationIsVisible] = useState(false);

    // Modal.
    const [modalStatus, setModalStatus] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [modalMessage, setModalMessage] = useState('');
    
    // Utilities.
    const [userRole, setUserRole] = useState(null);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    // Disclosures.
    const responseModalDisclosure = useDisclosure();

    // Get the token from the url.
    const { token } = useParams();

    // Check session and fetch data.
    useEffect(() => {
        /**
         * Check user session. The function is declared as async to use the await keyword.
         *
         * @returns {Promise<void>}
         */
        const checkSession = async () => {
            try {
                const response = await userService.checkSession();

                if (!response.success) {
                    throw response.errors;
                }

                const userSession = JSON.parse(atob(response.data));

                setUserRole(userSession.role);
            } catch (error) {
                console.error('Error checking session:', error);
            }
        };

        checkSession();
    }, []);


    // Handle form validation.
    useEffect(() => {
        // Set error messages.
        const passwordError = userService.validateNotEmpty(password, 'Este campo es requerido.');
        const passwordConfirmationError = userService.validatePasswordConfirmation(password, passwordConfirmation);

        console.log(passwordError, passwordConfirmationError);

        setPasswordError(passwordError);
        setPasswordConfirmationError(passwordConfirmationError);

        // Check if the submit button should be enabled.
        if (password !== '' && passwordConfirmation !== '' && passwordError === null && passwordConfirmationError === null) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [password, passwordConfirmation]);


    // Handle form validation.
    useEffect(() => {
        if (passwordError !== null || passwordConfirmationError !== null) {
            setIsDisabled(true);
        }
    }, [passwordError, passwordConfirmationError]);


    /**
     * Handle the change password request.
     *
     * @returns {Promise<void>}
     */
    const handleChangePassword = async () => {
        setIsLoading(true);
        
        try {
            const response = await accountService.changePassword(token, password, passwordConfirmation);

            if (!response.success) {
                throw response.errors;
            }

            // Show modal.
            setModalStatus('success');
            setModalTitle('Contraseña actualizada');
            setModalMessage('Tu contraseña ha sido actualizada correctamente.');

            responseModalDisclosure.onOpen();

            setIsLoading(false);
        } catch (error) {
            // Show modal.
            setModalStatus('error');
            setModalTitle('Error');
            setModalMessage(error.message ? error.message : 'Ha ocurrido un error al intentar cambiar la contraseña. Por favor, intenta de nuevo.');

            responseModalDisclosure.onOpen();

            setIsLoading(false);
        }
    };


    return (
        <div id='changePasswordPage' className='page'>
            <PageContent withBackground isAwaiting={false} userRole={userRole}>
                <form className='flex flex-col justify-center items-center z-10'>
                    <h1 className='main-title mb-4 z-10'>Nueva Contraseña</h1>

                    <Input
                        type={passwordIsVisible ? 'text' : 'password'}
                        label='Contraseña'
                        variant='underlined'
                        defaultValue=''
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onBlur={(e) => {
                            setPassword(e.target.value);
                            setPasswordError(inputService.validatePassword(password));
                        }}
                        isInvalid={passwordError !== null}
                        errorMessage={passwordError}                
                        endContent={
                            <button className='focus:outline-none' type='button' onClick={() => setPasswordIsVisible(!passwordIsVisible)}>
                                {passwordIsVisible ? (
                                    <EyeSlash className='text-2xl text-default-400 pointer-events-none w-5 h-5' />
                                ) : (
                                    <Eye className='text-2xl text-default-400 pointer-events-none w-5 h-5' />
                                )}
                            </button>
                        }
                        isDisabled={isLoading}
                        className='mb-4 z-10'
                        classNames={{
                            innerWrapper: 'border-red-500, focus:border-secondary-500',
                        }}
                    />

                    <Input
                        type={passwordConfirmationIsVisible ? 'text' : 'password'}
                        label='Repetir contraseña'
                        variant='underlined'
                        defaultValue=''
                        value={passwordConfirmation}
                        onChange={(e) => setPasswordConfirmation(e.target.value)}
                        onBlur={(e) => {
                            setPasswordConfirmation(e.target.value);
                            setPasswordConfirmationError(inputService.validatePasswordConfirmation(password, passwordConfirmation));
                        }}
                        isInvalid={passwordConfirmationError !== null}
                        errorMessage={passwordConfirmationError}                
                        endContent={
                            <button className='focus:outline-none' type='button' onClick={() => setPasswordConfirmationIsVisible(!passwordConfirmationIsVisible)}>
                                {passwordConfirmationIsVisible ? (
                                    <EyeSlash className='text-2xl text-default-400 pointer-events-none w-5 h-5' />
                                ) : (
                                    <Eye className='text-2xl text-default-400 pointer-events-none w-5 h-5' />
                                )}
                            </button>
                        }
                        isDisabled={isLoading}
                        className='mb-6 z-10'
                    />

                    <Button
                        color='primary'
                        className='w-full uppercase shadow-lg mb-4 z-10'
                        radius='sm'
                        onClick={handleChangePassword}
                        isDisabled={isDisabled || isLoading}
                        isLoading={isLoading}
                        style={{ maxWidth: '300px' }}
                    >
                        Cambiar contraseña
                    </Button>
                </form>
            </PageContent>


            {/* Response modal */}
            <GenericModal
                disclosure={responseModalDisclosure}
                status={modalStatus}
            >
                <ModalHeader className='modal-header flex justify-center items-center text-center relative'>
                    <h3 className='uppercase'>{modalTitle}</h3>
                </ModalHeader>

                <ModalBody>
                    <p className='text-center' style={{margin: '1rem 1rem 2rem'}}>{modalMessage}</p>
                </ModalBody>

                <ModalFooter className='flex flex-col gap-2 pt-0'>
                    {modalStatus && modalStatus === 'success' && (
                        <Button
                            color='primary'
                            className='w-full uppercase shadow-lg mx-auto'
                            radius='sm'
                            onPress={() => window.location.href = '/login'}
                            style={{ maxWidth: '300px' }}
                        >
                            Iniciar sesión
                        </Button>
                    )}

                    <Button
                        color='default'
                        className='w-full uppercase shadow-lg mt-1 mx-auto'
                        radius='sm'
                        onPress={() => responseModalDisclosure.onClose()}
                        style={{ maxWidth: '300px' }}
                    >
                        Cerrar
                    </Button>
                </ModalFooter>
            </GenericModal>
        </div>
    );
}
