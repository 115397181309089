import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Accordion, AccordionItem, Button } from '@nextui-org/react';
import petService from '../../services/pets.js';
import userService from '../../services/users.js';

import PageContent from '../../components/PageContent/PageContent.jsx';

import petNoPicture from '../../assets/images/pet-no-picture.png';
import PawFilled from '../../components/Icons/PawFilled.jsx';
import ChevronRight from '../../components/Icons/ChevronRight.jsx';


export default function PrivateProfile() {
    // Declare states.
    const [pet, setPet] = useState(null);
    const [user, setUser] = useState(null);

    // Utilities.
    const [userRole, setUserRole] = useState(null);
    const [isPageLoading, setIsPageLoading] = useState(false);

    // Get the ID from the URL.
    const { id } = useParams();
    const isValidId = /^[0-9]$/.test(id);

    if (!isValidId) {
        window.location.href = '/panel-usuario';
    }


    // Check user session.
    useEffect(() => {
        /**
         * Check user session. The function is declared as async to use the await keyword.
         *
         * @returns {Promise<void>}
         */
        const checkSession = async () => {
            setIsPageLoading(true);

            try {
                const checkSessionResponse = await userService.checkSession();

                if (!checkSessionResponse.success) {
                    throw checkSessionResponse.errors;
                }

                const userSession = JSON.parse(atob(checkSessionResponse.data));

                // Check that the pet belongs to the user.
                const response = await petService.belongsToUser(id, userSession.id);

                if (!response.success) {
                    throw response.errors;
                }

                setUserRole(userSession.role);

                await fetchPetData();
                setIsPageLoading(false);
            } catch (error) {
                setIsPageLoading(false);
            }
        };

        checkSession();
    }, []);


    /**
     * Fetch the pet by its id.
     *
     * @returns {void}
     */
    const fetchPetData = async () => {
        setIsPageLoading(true);

        const response = await petService.getById(id);

        if (!response.success) {
            throw response.errors;
        }

        // Calculate the age of the pet.
        const age = response.data.pet.birthday !== null ? Math.floor((new Date() - new Date(response.data.pet.birthday)) / (1000 * 60 * 60 * 24 * 365)) : null;

        response.data.pet.age = age;

        setPet(response.data.pet);
        setUser(response.data.user);
    };


    return (
        <div id='privateProfilePage' className='page'>
            <PageContent withBackground isAwaiting={isPageLoading} userRole={userRole}>
                <div className='flex flex-col justify-between' style={{ minHeight: 'calc(100vh - 80px - 194px - 3rem)' }}>
                    <div className='flex flex-col container'>
                        {pet !== null && (
                            <div className='pet-card flex flex-col p-2 mb-2 shadow-lg relative'>
                                <div id='photoContainer'>
                                    {pet.photo !== '' && (
                                        <img src={`${process.env.REACT_APP_ENDPOINT}img/pets/profile/${pet.photo}`} alt={pet.name} className='rounded-full'/>
                                    ) || (
                                        <img src={petNoPicture} alt='pet-profile-picture' className='rounded-full'/>
                                    )}
                                </div>

                                <div className='content'>
                                    <div className='p-2'>
                                        <h2 className='text-slate-50 text-center font-bold mb-2'>Hola! Soy <span className='color-dark-light'>{pet.name}</span></h2>
                                        <p className='text-slate-50 text-center mb-2'>Por favor, si estoy perdido contactá a <span className='color-dark-light font-bold'>{user.name}.</span> Su teléfono es <span className='color-dark-light font-bold'>{user.phone}.</span></p>
                                        <p className='text-center font-bold mb-2'>Te cuento sobre mí:</p>

                                        <div className='pet-info flex flex-col text-slate-50'>
                                            {pet.specie && (
                                                <div className='flex gap-2'>
                                                    <PawFilled className='paw-filled fill-light w-4 h-4' style={{ marginTop: '2px' }} />

                                                    <div className='flex flex-col'>
                                                        <p className='font-bold'>Especie</p>
                                                        <p>{pet.specie.charAt(0).toUpperCase() + pet.specie.slice(1)}</p>
                                                    </div>
                                                </div>
                                            )}

                                            {pet.sex && (
                                                <div className='flex gap-2'>
                                                    <PawFilled className='paw-filled fill-light w-4 h-4' style={{ marginTop: '2px' }} />

                                                    <div className='flex flex-col'>
                                                        <p className='font-bold'>Sexo</p>
                                                        <p>{pet.sex.charAt(0).toUpperCase() + pet.sex.slice(1)}</p>
                                                    </div>
                                                </div>
                                            )}

                                            {pet.breed && (
                                                <div className='flex gap-2'>
                                                    <PawFilled className='paw-filled fill-light w-4 h-4' style={{ marginTop: '2px' }} />

                                                    <div className='flex flex-col'>
                                                        <p className='font-bold'>Raza</p>
                                                        <p>{pet.breed.charAt(0).toUpperCase() + pet.breed.slice(1)}</p>
                                                    </div>
                                                </div>
                                            )}

                                            {pet.birthday && (
                                                <div className='flex gap-2'>
                                                    <PawFilled className='paw-filled fill-light w-4 h-4' style={{ marginTop: '2px' }} />

                                                    <div className='flex flex-col'>
                                                        <p className='font-bold'>Fecha de nacimiento</p>
                                                        <p>{pet.birthday}</p>
                                                    </div>
                                                </div>
                                            )}

                                            {pet.microchip && 
                                                <div className='flex gap-2'>
                                                    <PawFilled className='paw-filled fill-light w-4 h-4' style={{ marginTop: '2px' }} />

                                                    <div className='flex flex-col'>
                                                        <p className='font-bold'>Microchip</p>
                                                        <p>{pet.microchip}</p>
                                                    </div>
                                                </div>
                                            }
                                        </div>


                                        {pet.clarification && (
                                            <Accordion
                                                classNames={{
                                                    base: 'px-0'
                                                }}
                                            >
                                                <AccordionItem
                                                    title='Más información'
                                                    indicator={({ isOpen }) => 
                                                        <ChevronRight className='w-4 h-4' style={{ transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)' }} />
                                                    }
                                                    classNames={{
                                                        trigger: 'flex justify-center items-center',
                                                        titleWrapper: 'flex-initial',
                                                        title: 'text-base font-bold',
                                                        indicator: 'rotate-90'
                                                    }}
                                                >
                                                    <div className='pet-info flex flex-col text-slate-50'>
                                                        {pet.clarification}
                                                    </div>
                                                </AccordionItem>
                                            </Accordion>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <Button
                        color='default'
                        className='w-full uppercase shadow-lg mt-2 mx-auto'
                        radius='sm'
                        onClick={() => window.location.href = '/panel-usuario'}
                        style={{ maxWidth: '300px' }}
                    >
                        Volver atrás
                    </Button>
                </div>
            </PageContent>
        </div>
    );
}
