import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Input, Tabs, Tab } from '@nextui-org/react';
import userService from '../../services/users.js';
import subscriptionService from '../../services/subscriptions.js';

import PageContent from '../../components/PageContent/PageContent.jsx';

import heartGirlDog from '../../assets/images/heart-girl-dog.png';
import './ActivateQR.css';

const prices = [
    {
        id: 1,
        monthly: 1000,
        annual: 12000
    },
    {
        id: 2,
        monthly: 2000,
        annual: 24000
    },
    {
        id: 3,
        monthly: 3000,
        annual: 36000
    }
];


export default function ActivateQR() {
    // Declare states.
    const [frequency, setFrequency] = useState('');
    const [amount, setAmount] = useState(null);

    const [amountError, setAmountError] = useState('');

    const [userId, setUserId] = useState('');
    const [userRole, setUserRole] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(true);

    // Get the user ID from the URL.
    const { token } = useParams();
    const isValidId = /^[a-zA-Z0-9]{1,}$/.test(token);

    if (!isValidId) {
        window.location.href = '/panel-usuario';
    }


    // Check session and fetch data.
    useEffect(() => {
        /**
         * Check user session. The function is declared as async to use the await keyword.
         *
         * @returns {Promise<void>}
         */
        const checkSession = async () => {
            setIsPageLoading(true);

            try {
                const checkSessionResponse = await userService.checkSession();

                if (!checkSessionResponse.success) {
                    throw checkSessionResponse.errors;
                }

                const userSession = JSON.parse(atob(checkSessionResponse.data));

                setUserId(userSession.id);
                setUserRole(userSession.role);

                // Check the status of the tag.
                const response = await subscriptionService.get(userSession.id, token);

                if (!response.success) {
                    throw response.errors;
                }

                // #TODO: Mostrar mensaje de que la suscripción ya está activa.
                if (response.data.subscription_status && response.data.subscription_status === 'approved') {
                    window.location.href = '/panel-usuario';
                    return;
                }

                setIsPageLoading(false);
            } catch (error) {
                setIsPageLoading(false);
            }
        };

        checkSession();
    }, []);


    /**
     * Handle the creation of a subscription.
     *
     * @return void.
     */
    const handleCreateSubscription = async () => {
        // Change loading state.
        setIsLoading(true);

        let subscriptionAmount = 0;

        const activeTab = document.querySelector('.tabs .tab.active');

        if (activeTab !== null) {
            const tabId = parseInt(activeTab.getAttribute('value'), 10);
            const selectedPrice = prices.find((price) => price.id === tabId);

            if (selectedPrice !== undefined) {
                subscriptionAmount = frequency === 'mensual' ? selectedPrice.monthly : selectedPrice.annual;
            }
        } else {
            subscriptionAmount = amount;

            const minAmount = frequency === 'mensual' ? 550 : 6600;
            const amountError = frequency === 'mensual' ? 'El monto mínimo es de $550 / mes' : 'El monto mínimo es de $6600 / año';

            if (subscriptionAmount < minAmount) {
                setAmountError(amountError);
                setIsLoading(false);

                return;
            } else {
                setAmountError('');
            }
        }

        try {
            const response = await subscriptionService.create(userId, token, frequency, subscriptionAmount);

            if (!response.success) {
                throw response.errors;
            }

            if (response.data.subscription_active) {
                // #TODO: Mostrar modal con el mensaje de respuesta.
                window.location.href = '/panel-usuario';
                return;
            }

            // Create a new tab with the payment gateway.
            const a = document.createElement('a');
            a.href = response.data.init_point;
            a.target = '_blank';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            // Change loading state.
            setIsLoading(false);
        } catch (error) {
            // Change loading state.
            setIsLoading(false);

            console.error(error);
        }
    };


    /**
     * Handle the tab change.
     *
     * @param {Object} event The event object.
     *
     * @return void.
     */
    const handleTabChange = (event) => {
        if (!event.target.classList.contains('tab')) {
            return;
        }

        if (event.target.classList.contains('active')) {
            return;
        }

        const tabs = document.querySelectorAll('.tabs .tab');
        tabs.forEach((tab) => {
            tab.classList.remove('active');
        });

        event.target.classList.add('active');

        setAmount('');
    };


    return (
        <div id='activateQRPage' className='page'>
            <PageContent withBackground isAwaiting={isPageLoading} userRole={userRole}>
                <div className='title w-full mb-4'>
                    <h1 className='main-title text-center mb-2'>Activar Tag</h1>
                </div>
                
                <div className='flex flex-col container'>
                    <div className='flex flex-col relative'>
                        <div className='flex items-center mb-6'>
                            {/* <Heart /> */}
                            <img src={heartGirlDog} alt='HeartGirlDog' className='heart-girl-dog' />

                            <p className='text'>Que el costo no sea impedimento para proteger a tu mascota. Elegí el precio que mejor se adapte a tus posibilidades.</p>
                        </div>

                        <div className='payment-form shadow-lg'>
                            <Tabs
                                variant='solid'
                                aria-label='frequency'
                                selectedKey={frequency}
                                onSelectionChange={setFrequency}
                                classNames={{
                                    base: 'frequency-tabs w-full',
                                    tabList: 'tab-list gap-6 w-full relative rounded-none p-0',
                                    cursor: 'w-full rounded-none',
                                    tab: 'tab px-0 h-12',
                                    tabContent: 'tab-content',
                                }}
                            >
                                <Tab
                                    key='mensual'
                                    title='Mensual'
                                />
                                <Tab
                                    key='anual'
                                    title='Anual'
                                />
                            </Tabs>

                            <div className='flex flex-col items-center bg-primary-light p-2'>
                                <p className='color-light text-center p-2'>Elegí el monto que te gustaría pagar para proteger a tu mascota</p>

                                <div className='tabs' onClick={handleTabChange}>
                                    <div className='tab' value='1'>
                                        {frequency === 'mensual' ? `$${prices[0].monthly} ARS / mes` : `$${prices[0].annual} ARS / año`}
                                    </div>

                                    <div className='tab active' value='2'>
                                        {frequency === 'mensual' ? `$${prices[1].monthly} ARS / mes` : `$${prices[1].annual} ARS / año`}
                                        <span className='small'>Precio sugerido</span>
                                    </div>

                                    <div className='tab' value='3'>
                                        {frequency === 'mensual' ? `$${prices[2].monthly} ARS / mes` : `$${prices[2].annual} ARS / año`}
                                    </div>
                                </div>

                                <div className='p-2' style={{ width: '300px' }}>
                                    <Input
                                        type='number'
                                        label='Otro monto'
                                        radius='sm'
                                        placeholder='0.00'
                                        value={amount}
                                        onChange={(e) => {
                                            setAmount(e.target.value);
                                            const activeTab = document.querySelector('.tabs .tab.active');
                                            activeTab !== null ? activeTab.classList.remove('active') : null;
                                        }}
                                        onBlur={(e) => {
                                            setAmount(e.target.value);
                                        }}
                                        endContent={
                                            <div className='pointer-events-none flex items-center'>
                                                <span className='text-default-400 text-small'>ARS</span>
                                            </div>
                                        }
                                        isInvalid={amountError !== ''}
                                        errorMessage={amountError}
                                        className='mb-2 z-10'
                                    />
                                    <p className='small text-center color-light mt-1'>El monto mínimo es de {frequency === 'mensual' ? '$550 / mes' : '$6600 / año'}</p>

                                    <Button
                                        color='success'
                                        className='w-full color-light uppercase shadow-md mt-2 mx-auto z-10'
                                        radius='sm'
                                        onClick={() => handleCreateSubscription()}
                                        isLoading={isLoading}
                                        style={{ maxWidth: '300px' }}
                                    >
                                        Pagar
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PageContent>
        </div>
    );
}
