// Import modules.
import React from 'react';
import PropTypes from 'prop-types';

// Define properties.
ChevronRight.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
};


export default function ChevronRight(props) {
    const { className, onClick } = props;

    return (
        <svg className={'chevron-right ' + className} onClick={onClick} width='13' height='22' viewBox='0 0 13 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M1.59998 21.8C1.29998 21.8 0.999951 21.6999 0.699951 21.3999C0.199951 20.8999 0.199951 20.1 0.699951 19.7L9.40002 11L0.699951 2.30005C0.199951 1.80005 0.199951 1.0001 0.699951 0.600098C1.19995 0.100098 2.00002 0.100098 2.40002 0.600098L11.9 10.1001C12.4 10.6001 12.4 11.4 11.9 11.8L2.40002 21.3C2.20002 21.7 1.89998 21.8 1.59998 21.8Z' fill='#1C1D1E'/>
        </svg>
    );
}
