// Import modules.
import React from 'react';
import PropTypes from 'prop-types';

// Import icons.
import PawShape from '../Icons/PawShape';

// Import assets.
import './PawCard.css';

// Define properties.
PawCard.propTypes = {
    step: PropTypes.shape({
        step: PropTypes.number,
        title: PropTypes.string,
        description: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    className: PropTypes.string,
};


export default function PawCard({ step, className }) {
    return (
        <div className='paw-card text-white text-center relative'>
            <PawShape className={className} />

            <div className={'paw-content absolute ' + className}>
                <p className='number font-semibold'>{step.step}</p>
                <p className='title'>{step.title}</p>
                {step.description && step.description.map((text, index) => (
                    <p key={index} className='description'>{text}</p>
                ))}
            </div>
        </div>
    );
}
