import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Accordion, AccordionItem, Button, Input, useDisclosure } from '@nextui-org/react';
import { completeDate, medicalRecordTypes } from '../../utils/helpers.js';
import userService from '../../services/users.js';
import medicalRecordService from '../../services/medicalRecords.js';

import AddMedicalRecordModal from './components/AddMedicalRecordModal.jsx';
import DeleteMedicalRecordModal from './components/DeleteMedicalRecordModal.jsx';
import EditMedicalRecordModal from './components/EditMedicalRecordModal.jsx';
import PageContent from '../../components/PageContent/PageContent.jsx';

import Pencil from '../../components/Icons/Pencil.jsx';
import Plus from '../../components/Icons/Plus.jsx';
import Trash from '../../components/Icons/Trash.jsx';

import petNoPicture from '../../assets/images/pet-no-picture.png';
import './MedicalRecord.css';


export default function MedicalRecord() {
    // Declare states.
    const [selectedMedicalRecord, setSelectedMedicalRecord] = useState(null);
    const [medicalRecords, setMedicalRecords] = useState([]);
    const [medicalRecordsToShow, setMedicalRecordsToShow] = useState([]);

    // Utilities.
    const [userRole, setUserRole] = useState(null);
    const [pet, setPet] = useState(null);
    const [isPageLoading, setIsPageLoading] = useState(true);

    // Disclosures.
    const addMedicalRecordModal = useDisclosure();
    const editMedicalRecordModal = useDisclosure();
    const deleteMedicalRecordModal = useDisclosure();

    // Get the pet ID from the URL.
    const { id } = useParams();
    const isValidId = /^[0-9]{1,}$/.test(id);

    if (!isValidId) {
        window.location.href = '/panel-usuario';
    }


    // Check user session and fetch the medical records.
    useEffect(() => {
        /**
         * Check user session. The function is declared as async to use the await keyword.
         *
         * @returns {Promise<void>}
         */
        const checkSession = async () => {
            setIsPageLoading(true);

            try {
                const checkSessionResponse = await userService.checkSession();

                if (!checkSessionResponse.success) {
                    throw checkSessionResponse.errors;
                }

                const userSession = JSON.parse(atob(checkSessionResponse.data));

                setUserRole(userSession.role);

                await fetchMedicalRecords();

                setIsPageLoading(false);
            } catch (error) {
                setIsPageLoading(false);
            }
        };

        checkSession();
    }, []);


    /**
     * Fetch the Medical records.
     *
     * @returns {void}
     */
    const fetchMedicalRecords = async () => {
        const response = await medicalRecordService.getMedicalRecords(id);
        
        setPet(response.pet);
        setMedicalRecords(response.medicalRecords);
        setMedicalRecordsToShow(response.medicalRecords);
    };

    
    /**
     * Handle the search input.
     *
     * @param {Event} event - The event object.
     *
     * @returns {void}
     */
    const handleSearch = (event) => {
        const search = event.target.value.toLowerCase();

        if (search === '') {
            setMedicalRecordsToShow(medicalRecords);
            return;
        }

        const filteredRecords = medicalRecords.filter(record => {
            const title = medicalRecordTypes.find(type => type.value === record.type).label;
            const description = record.description.toLowerCase();

            return title.toLowerCase().includes(search) || description.includes(search);
        });

        setMedicalRecordsToShow(filteredRecords);
    };


    return (
        <div id='medicalRecordPetPage' className='page'>
            <PageContent withBackground isAwaiting={isPageLoading} userRole={userRole}>
                <div className='title w-full mb-4'>
                    <h1 className='main-title text-center mb-2'>Ficha Médica</h1>
                </div>
                
                <div className='flex flex-col'>
                    <div className='pet flex justify-center items-center mb-3'>
                        <div className='image'>
                            {pet && pet.photo !== '' && (
                                <img src={`${process.env.REACT_APP_ENDPOINT}img/pets/profile/${pet.photo}`} alt={pet.name} className='rounded-full'/>
                            ) || (
                                <img src={petNoPicture} alt='pet-profile-picture' className='rounded-full'/>
                            )}
                        </div>

                        <h2 className='ml-2'>Rocco</h2>
                    </div>

                    {/* Search */}
                    {medicalRecords && medicalRecords.length > 0 && (
                        <Input
                            type='text'
                            variant='underlined'
                            textValue=''
                            onChange={handleSearch}
                            label='Buscar'
                            className='mb-4'
                            classNames={{
                                inputWrapper: 'search-wrapper',
                            }}
                        />
                    )}

                    <Button
                        color='primary'
                        radius='sm'
                        onPress={() => addMedicalRecordModal.onOpen('blur')}
                        className='w-full uppercase shadow-lg mx-auto'
                        style={{ maxWidth: '300px' }}
                    >
                        <Plus className='fill-light p-1' />
                        Añadir registro
                    </Button>

                    {medicalRecordsToShow && medicalRecordsToShow.length > 0 ? (
                        <Accordion
                            variant='splitted'
                            className='accordion mt-4 mx-auto p-0'
                            style={{ maxWidth: '500px' }}
                        >
                            {medicalRecordsToShow.map((record, index) => {
                                const title = medicalRecordTypes.find(type => type.value === record.type).label;
                                const description = record.description.charAt(0).toUpperCase() + record.description.slice(1);
                                
                                return (
                                    <AccordionItem
                                        key={index}
                                        title={
                                            <div className='flex justify-between'>
                                                <div className='flex flex-col'>
                                                    <div>{title}</div>
                                                    {record.date && <div className='date'>{record.date}</div>}
                                                </div>
                                            </div>
                                        }
                                        className='item'
                                        classNames={{ indicator: 'indicator' }}
                                    >
                                        <div className='medical-record'>
                                            {record.date && <div className='date'><span className='fw-500 color-primary mb-1'>Fecha: </span>{completeDate(record.date)}</div>}
                                            <div className='title'><span className='fw-500 color-primary mb-1'>Registro: </span>{title}</div>
                                            <div className='category'><span className='fw-500 color-primary mb-1'>Categoría: </span>{description}</div>
                                            {record.observations && <div className='description'><span className='fw-500 color-primary mb-1'>Detalle: </span>{record.observations}</div>}

                                            <div className='icon-container flex'>
                                                <span className='icon' onClick={() => {
                                                    setSelectedMedicalRecord(record);
                                                    editMedicalRecordModal.onOpen('blur');
                                                }}>
                                                    <Pencil />
                                                </span>

                                                <span className='icon' onClick={() => {
                                                    setSelectedMedicalRecord(record);
                                                    deleteMedicalRecordModal.onOpen('blur');
                                                }}>
                                                    <Trash />
                                                </span>
                                            </div>
                                        </div>
                                    </AccordionItem>
                                );
                            })}
                        </Accordion>
                    ) : (
                        <div className='flex justify-center items-center mt-4'>
                            <p>No se encontraron registros médicos.</p>
                        </div>
                    )}
                </div>

                <AddMedicalRecordModal disclosure={addMedicalRecordModal} petId={id} petSpecie={pet ? pet.specie : ''} callback={fetchMedicalRecords} />
                <EditMedicalRecordModal disclosure={editMedicalRecordModal} medicalRecord={selectedMedicalRecord} petSpecie={pet ? pet.specie : ''} callback={fetchMedicalRecords} />
                <DeleteMedicalRecordModal disclosure={deleteMedicalRecordModal} id={selectedMedicalRecord ? selectedMedicalRecord.id : ''} callback={fetchMedicalRecords} />
            </PageContent>
        </div>
    );
}