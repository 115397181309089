// Import modules.
import React from 'react';
import PropTypes from 'prop-types';

// Define properties.
Crown.propTypes = {
    className: PropTypes.string,
};


export default function Crown({ className = '' }) {
    return (
        <svg className={'crown ' + className} xmlns='http://www.w3.org/2000/svg' width='1.2rem' height='1.2rem' viewBox='0 0 24 24'>
            <path fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5' d='m12 6l4 6l5-4l-2 10H5L3 8l5 4z' />
        </svg>
    );
}
