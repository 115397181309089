// Import modules.
import React from 'react';
import PropTypes from 'prop-types';

// Define properties.
Star.propTypes = {
    className: PropTypes.string,
};


export default function Star({ className }) {
    return (
        <svg className={'star ' + className} width='26' height='24' viewBox='0 0 26 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M12.9999 1.80005L16.3999 8.80005L24.2 9.8999L18.5999 15.3999L19.8999 23.1001L12.9999 19.5L5.99994 23.1001L7.39993 15.3999L1.69995 9.8999L9.49994 8.80005L12.9999 1.80005Z' stroke='#D6D5D5' strokeWidth='0.949' strokeMiterlimit='10'/>
        </svg>
    );
}
