/**
 * Create a QR token.
 *
 * @returns {string} The QR token.
 */
const createQrToken = () => {
    // Define the available characters.
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    // Initialize the token.
    let token = '';

    // Create a token with 8 characters.
    for (let i = 0; i < 8; i++) {
        token += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    // Return the token.
    return token;
};


/**
 * Parse a date object to a string formatted as 'yyyy-mm-dd'.
 *
 * @param {object} date - The date object.
 *
 * @returns {string} - The formatted date.
 */
const parseDate = (date) => {
    if (!date) {
        return '';
    }

    // Get the year, month, and day.
    const year = date.year;
    const month = date.month;
    const day = date.day;

    // Return the formatted date.
    return `${year}-${month}-${day}`;
};


/**
 * Complete a date string with zeros.
 *
 * @param {string} date - The date string.
 *
 * @returns {string} - The completed date string.
 */
const completeDate = (date) => {
    if (!date) {
        return '';
    }

    // Split the date string.
    const dateArray = date.split('-');

    // Get the year, month, and day.
    const year = dateArray[0];
    const month = dateArray[1].padStart(2, '0');
    const day = dateArray[2].padStart(2, '0');

    // Return the completed date string.
    return `${year}-${month}-${day}`;
};


/**
 * Append a key-value pair to the FormData object in case the value is not null or undefined.
 *
 * @param {FormData} formData - The FormData object.
 * @param {string} key - The key of the pair.
 * @param {string} value - The value of the pair.
 *
 * @returns {void}
 */
const appendFormData = (formData, key, value) => {
    if (value !== null && value !== undefined) {
        formData.append(key, value);
    }

    return formData;
};


const statuses = {
    1: {
        description: 'Activo',
        color: 'success',
    },
    2: {
        description: 'Inactivo',
        color: 'warning',
    },
    3: {
        description: 'Bloqueado',
        color: 'danger',
    },
    4: {
        description: 'Eliminado',
        color: 'error',
    },
};

const species = [
    { value: 'selectOption', label: 'Seleccione una opción', disabled: true },
    { value: 'perro', label: 'Perro' },
    { value: 'gato', label: 'Gato' },
    { value: 'otro', label: 'Otro' }
];

const dogBreeds = [
    { value: 'selectOption', label: 'Seleccione una opción', disabled: true },
    { value: 'basset hound', label: 'Basset Hound' },
    { value: 'beagle', label: 'Beagle' },
    { value: 'boxer', label: 'Boxer' },
    { value: 'bulldog francés', label: 'Bulldog Francés' },
    { value: 'bulldog inglés', label: 'Bulldog Inglés' },
    { value: 'bull terrier', label: 'Bull Terrier' },
    { value: 'Chihuahua', label: 'Chihuahua' },
    { value: 'cocker spaniel', label: 'Cocker Spaniel' },
    { value: 'dálmata', label: 'Dálmata' },
    { value: 'doberman pinscher', label: 'Doberman Pinscher' },
    { value: 'golden retriever', label: 'Golden Retriever' },
    { value: 'gran danés', label: 'Gran Danés' },
    { value: 'husky siberiano', label: 'Husky Siberiano' },
    { value: 'labrador retriever', label: 'Labrador Retriever' },
    { value: 'maltese', label: 'Maltese' },
    { value: 'mestizo', label: 'Mestizo' },
    { value: 'otro', label: 'Otro' },
    { value: 'pastor alemán', label: 'Pastor Alemán' },
    { value: 'pomerania', label: 'Pomerania' },
    { value: 'poodle', label: 'Poodle (Caniche)' },
    { value: 'rottweiler', label: 'Rottweiler' },
    { value: 'schnauzer', label: 'Schnauzer' },
    { value: 'shih tzu', label: 'Shih Tzu' },
    { value: 'staffordshire bull terrier', label: 'Staffordshire Bull Terrier' },
    { value: 'west highland white terrier', label: 'West Highland White Terrier' },
    { value: 'yorkshire terrier', label: 'Yorkshire Terrier' },
    { value: 'australian shepherd', label: 'Australian Shepherd' },
    { value: 'border collie', label: 'Border Collie' },
    { value: 'corgi', label: 'Corgi' },
    { value: 'dachshund', label: 'Dachshund (Teckel)' },
    { value: 'english springer spaniel', label: 'English Springer Spaniel' },
    { value: 'german shorthaired pointer', label: 'German Shorthaired Pointer' },
];

const catBreeds = [
    { value: 'selectOption', label: 'Seleccione una opción', disabled: true },
    { value: 'angora turco', label: 'Angora Turco' },
    { value: 'azul ruso', label: 'Azul Ruso' },
    { value: 'bengal', label: 'Bengal' },
    { value: 'birmano', label: 'Birmano' },
    { value: 'bombay', label: 'Bombay' },
    { value: 'bosque de noruega', label: 'Bosque de Noruega' },
    { value: 'british shorthair', label: 'British Shorthair' },
    { value: 'burmés', label: 'Burmés' },
    { value: 'chartreux', label: 'Chartreux' },
    { value: 'cornish rex', label: 'Cornish Rex' },
    { value: 'devon rex', label: 'Devon Rex' },
    { value: 'exótico de pelo corto', label: 'Exótico de Pelo Corto' },
    { value: 'himalayo', label: 'Himalayo' },
    { value: 'mestizo', label: 'Mestizo' },
    { value: 'maine coon', label: 'Maine Coon' },
    { value: 'manx', label: 'Manx' },
    { value: 'oriental de pelo corto', label: 'Oriental de Pelo Corto' },
    { value: 'otro', label: 'Otro' },
    { value: 'persa', label: 'Persa' },
    { value: 'ragdoll', label: 'Ragdoll' },
    { value: 'scottish fold', label: 'Scottish Fold' },
    { value: 'siamés', label: 'Siamés' },
    { value: 'sphynx', label: 'Sphynx' },
    { value: 'tonkinés', label: 'Tonkinés' },
    { value: 'van turco', label: 'Van Turco' },
    { value: 'somali', label: 'Somali' },
    { value: 'siambés', label: 'Siambés' },
    { value: 'scottish straight', label: 'Scottish Straight' },
    { value: 'snowshoe', label: 'Snowshoe' },
    { value: 'singapura', label: 'Singapura' },
    { value: 'selkirk rex', label: 'Selkirk Rex' },
];

const sexes = [
    { value: 'selectOption', label: 'Seleccione una opción', disabled: true },
    { value: 'macho', label: 'Macho' },
    { value: 'hembra', label: 'Hembra' },
];

const sterilizedArray = [
    { value: 'selectOption', label: 'Seleccione una opción', disabled: true },
    { value: 'si', label: 'Sí' },
    { value: 'no', label: 'No' },
    { value: 'no se', label: 'No sé' },
];

const medicalRecordTypes = [
    { value: 'selectOption', label: 'Seleccione una opción', disabled: true },
    { value: 'accidente', label: 'Accidente' },
    { value: 'alergia', label: 'Alergia' },
    { value: 'cirugía', label: 'Cirugía' },
    { value: 'desparacitación', label: 'Desparasitación' },
    { value: 'enfermedad', label: 'Enfermedad' },
    { value: 'peso', label: 'Peso' },
    { value: 'vacuna', label: 'Vacuna' },
    { value: 'otro', label: 'Otro' },
];

const medicalRecordVaccines = [
    { value: 'selectOption', label: 'Seleccione una opción', disabled: true },
    { value: 'antirrábica', label: 'Antirrábica', specie: 'both' },
    { value: 'bordetella', label: 'Bordetella', specie: 'perro' },
    { value: 'leptospirosis', label: 'Leptospirosis', specie: 'perro' },
    { value: 'leucemia', label: 'Leucemia', specie: 'gato' },
    { value: 'quintuple', label: 'Quintuple', specie: 'both' },
    { value: 'séxtuple', label: 'Séxtuple', specie: 'perro' },
    { value: 'triple', label: 'Triple', specie: 'both' },
    { value: 'otra', label: 'Otra', specie: 'both' },
];

const medicalRecordSurgery = [
    { value: 'selectOption', label: 'Seleccione una opción', disabled: true },
    { value: 'dental', label: 'Dental' },
    { value: 'esterilización', label: 'Esterilización' },
    { value: 'extracción de tumores', label: 'Extracción de Tumores' },
    { value: 'oftalmológica', label: 'Oftalmológica' },
    { value: 'oído', label: 'Oído' },
    { value: 'ortopédica', label: 'Ortopédica' },
    { value: 'trastornos urinarios', label: 'Trastornos Urinarios' },
    { value: 'otra', label: 'Otra' },
];

const medicalRecordsDiseases = [
    { value: 'selectOption', label: 'Seleccione una opción', disabled: true },
    { value: 'dermatitis atópica', label: 'Dermatitis Atópica', specie: 'perro' },
    { value: 'epilepsia', label: 'Epilepsia', specie: 'both' },
    { value: 'gusano del corazón', label: 'Gusano del Corazón', specie: 'perro' },
    { value: 'hepatitis infecciosa canina', label: 'Hepatitis Infecciosa Canina', specie: 'perro' },
    { value: 'leishmaniosis', label: 'Leishmaniosis', specie: 'perro' },
    { value: 'leptospirosis', label: 'Leptospirosis', specie: 'perro' },
    { value: 'moquillo canino', label: 'Moquillo Canino', specie: 'perro' },
    { value: 'parvovirosis canina', label: 'Parvovirosis Canina', specie: 'perro' },
    { value: 'tos de las perreras', label: 'Tos de las Perreras', specie: 'perro' },
    { value: 'calicivirus', label: 'Calicivirus', specie: 'gato' },
    { value: 'diabetes', label: 'Diabetes', specie: 'gato' },
    { value: 'enfermedad renal crónica', label: 'Enfermedad Renal Crónica', specie: 'gato' },
    { value: 'infección del tracto urinario', label: 'Infección del Tracto Urinario', specie: 'gato' },
    { value: 'leucemia', label: 'Leucemia', specie: 'gato' },
    { value: 'panleucopenia', label: 'Panleucopenia', specie: 'gato' },
    { value: 'peritonitis infecciosa', label: 'Peritonitis Infecciosa', specie: 'gato' },
    { value: 'rinotraqueitis', label: 'Rinotraqueitis', specie: 'gato' },
    { value: 'otra', label: 'Otra', specie: 'both' },
];

const medicalRecordsAccidents = [
    { value: 'selectOption', label: 'Seleccione una opción', disabled: true },
    { value: 'atropello', label: 'Atropello' },
    { value: 'caída', label: 'Caída' },
    { value: 'intoxicación', label: 'Intoxicación' },
    { value: 'mordedura', label: 'Mordedura' },
    { value: 'quemadura', label: 'Quemadura' },
    { value: 'otra', label: 'Otra' },
];

const countries = [
    { value: 'selectOption', label: 'Seleccione una opción', disabled: true },
    { value: 'Afghanistan', label: 'Afghanistan' },
    { value: 'Albania', label: 'Albania' },
    { value: 'Algeria', label: 'Algeria' },
    { value: 'Andorra', label: 'Andorra' },
    { value: 'Angola', label: 'Angola' },
    { value: 'Antigua and Barbuda', label: 'Antigua and Barbuda' },
    { value: 'Argentina', label: 'Argentina' },
    { value: 'Armenia', label: 'Armenia' },
    { value: 'Australia', label: 'Australia' },
    { value: 'Austria', label: 'Austria' },
    { value: 'Azerbaijan', label: 'Azerbaijan' },
    { value: 'Bahamas', label: 'Bahamas' },
    { value: 'Bahrain', label: 'Bahrain' },
    { value: 'Bangladesh', label: 'Bangladesh' },
    { value: 'Barbados', label: 'Barbados' },
    { value: 'Belarus', label: 'Belarus' },
    { value: 'Belgium', label: 'Belgium' },
    { value: 'Belize', label: 'Belize' },
    { value: 'Benin', label: 'Benin' },
    { value: 'Bhutan', label: 'Bhutan' },
    { value: 'Bolivia', label: 'Bolivia' },
    { value: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina' },
    { value: 'Botswana', label: 'Botswana' },
    { value: 'Brazil', label: 'Brazil' },
    { value: 'Brunei', label: 'Brunei' },
    { value: 'Bulgaria', label: 'Bulgaria' },
    { value: 'Burkina Faso', label: 'Burkina Faso' },
    { value: 'Burundi', label: 'Burundi' },
    { value: 'Cabo Verde', label: 'Cabo Verde' },
    { value: 'Cambodia', label: 'Cambodia' },
    { value: 'Cameroon', label: 'Cameroon' },
    { value: 'Canada', label: 'Canada' },
    { value: 'Central African Republic', label: 'Central African Republic' },
    { value: 'Chad', label: 'Chad' },
    { value: 'Chile', label: 'Chile' },
    { value: 'China', label: 'China' },
    { value: 'Colombia', label: 'Colombia' },
    { value: 'Comoros', label: 'Comoros' },
    { value: 'Congo', label: 'Congo' },
    { value: 'Costa Rica', label: 'Costa Rica' },
    { value: 'Croatia', label: 'Croatia' },
    { value: 'Cuba', label: 'Cuba' },
    { value: 'Cyprus', label: 'Cyprus' },
    { value: 'Czech Republic', label: 'Czech Republic' },
    { value: 'Democratic Republic of the Congo', label: 'Democratic Republic of the Congo' },
    { value: 'Denmark', label: 'Denmark' },
    { value: 'Djibouti', label: 'Djibouti' },
    { value: 'Dominica', label: 'Dominica' },
    { value: 'Dominican Republic', label: 'Dominican Republic' },
    { value: 'East Timor', label: 'East Timor' },
    { value: 'Ecuador', label: 'Ecuador' },
    { value: 'Egypt', label: 'Egypt' },
    { value: 'El Salvador', label: 'El Salvador' },
    { value: 'Equatorial Guinea', label: 'Equatorial Guinea' },
    { value: 'Eritrea', label: 'Eritrea' },
    { value: 'Estonia', label: 'Estonia' },
    { value: 'Eswatini', label: 'Eswatini' },
    { value: 'Ethiopia', label: 'Ethiopia' },
    { value: 'Fiji', label: 'Fiji' },
    { value: 'Finland', label: 'Finland' },
    { value: 'France', label: 'France' },
    { value: 'Gabon', label: 'Gabon' },
    { value: 'Gambia', label: 'Gambia' },
    { value: 'Georgia', label: 'Georgia' },
    { value: 'Germany', label: 'Germany' },
    { value: 'Ghana', label: 'Ghana' },
    { value: 'Greece', label: 'Greece' },
    { value: 'Grenada', label: 'Grenada' },
    { value: 'Guatemala', label: 'Guatemala' },
    { value: 'Guinea', label: 'Guinea' },
    { value: 'Guinea-Bissau', label: 'Guinea-Bissau' },
    { value: 'Guyana', label: 'Guyana' },
    { value: 'Haiti', label: 'Haiti' },
    { value: 'Honduras', label: 'Honduras' },
    { value: 'Hungary', label: 'Hungary' },
    { value: 'Iceland', label: 'Iceland' },
    { value: 'India', label: 'India' },
    { value: 'Indonesia', label: 'Indonesia' },
    { value: 'Iran', label: 'Iran' },
    { value: 'Iraq', label: 'Iraq' },
    { value: 'Ireland', label: 'Ireland' },
    { value: 'Israel', label: 'Israel' },
    { value: 'Italy', label: 'Italy' },
    { value: 'Ivory Coast', label: 'Ivory Coast' },
    { value: 'Jamaica', label: 'Jamaica' },
    { value: 'Japan', label: 'Japan' },
    { value: 'Jordan', label: 'Jordan' },
    { value: 'Kazakhstan', label: 'Kazakhstan' },
    { value: 'Kenya', label: 'Kenya' },
    { value: 'Kiribati', label: 'Kiribati' },
    { value: 'Kuwait', label: 'Kuwait' },
    { value: 'Kyrgyzstan', label: 'Kyrgyzstan' },
    { value: 'Laos', label: 'Laos' },
    { value: 'Latvia', label: 'Latvia' },
    { value: 'Lebanon', label: 'Lebanon' },
    { value: 'Lesotho', label: 'Lesotho' },
    { value: 'Liberia', label: 'Liberia' },
    { value: 'Libya', label: 'Libya' },
    { value: 'Liechtenstein', label: 'Liechtenstein' },
    { value: 'Lithuania', label: 'Lithuania' },
    { value: 'Luxembourg', label: 'Luxembourg' },
    { value: 'Madagascar', label: 'Madagascar' },
    { value: 'Malawi', label: 'Malawi' },
    { value: 'Malaysia', label: 'Malaysia' },
    { value: 'Maldives', label: 'Maldives' },
    { value: 'Mali', label: 'Mali' },
    { value: 'Malta', label: 'Malta' },
    { value: 'Marshall Islands', label: 'Marshall Islands' },
    { value: 'Mauritania', label: 'Mauritania' },
    { value: 'Mauritius', label: 'Mauritius' },
    { value: 'Mexico', label: 'Mexico' },
    { value: 'Micronesia', label: 'Micronesia' },
    { value: 'Moldova', label: 'Moldova' },
    { value: 'Monaco', label: 'Monaco' },
    { value: 'Mongolia', label: 'Mongolia' },
    { value: 'Montenegro', label: 'Montenegro' },
    { value: 'Morocco', label: 'Morocco' },
    { value: 'Mozambique', label: 'Mozambique' },
    { value: 'Myanmar', label: 'Myanmar' },
    { value: 'Namibia', label: 'Namibia' },
    { value: 'Nauru', label: 'Nauru' },
    { value: 'Nepal', label: 'Nepal' },
    { value: 'Netherlands', label: 'Netherlands' },
    { value: 'New Zealand', label: 'New Zealand' },
    { value: 'Nicaragua', label: 'Nicaragua' },
    { value: 'Niger', label: 'Niger' },
    { value: 'Nigeria', label: 'Nigeria' },
    { value: 'North Korea', label: 'North Korea' },
    { value: 'North Macedonia', label: 'North Macedonia' },
    { value: 'Norway', label: 'Norway' },
    { value: 'Oman', label: 'Oman' },
    { value: 'Pakistan', label: 'Pakistan' },
    { value: 'Palau', label: 'Palau' },
    { value: 'Palestine', label: 'Palestine' },
    { value: 'Panama', label: 'Panama' },
    { value: 'Papua New Guinea', label: 'Papua New Guinea' },
    { value: 'Paraguay', label: 'Paraguay' },
    { value: 'Peru', label: 'Peru' },
    { value: 'Philippines', label: 'Philippines' },
    { value: 'Poland', label: 'Poland' },
    { value: 'Portugal', label: 'Portugal' },
    { value: 'Qatar', label: 'Qatar' },
    { value: 'Republic of the Congo', label: 'Republic of the Congo' },
    { value: 'Romania', label: 'Romania' },
    { value: 'Russia', label: 'Russia' },
    { value: 'Rwanda', label: 'Rwanda' },
    { value: 'Saint Kitts and Nevis', label: 'Saint Kitts and Nevis' },
    { value: 'Saint Lucia', label: 'Saint Lucia' },
    { value: 'Saint Vincent and the Grenadines', label: 'Saint Vincent and the Grenadines' },
    { value: 'Samoa', label: 'Samoa' },
    { value: 'San Marino', label: 'San Marino' },
    { value: 'Sao Tome and Principe', label: 'Sao Tome and Principe' },
    { value: 'Saudi Arabia', label: 'Saudi Arabia' },
    { value: 'Senegal', label: 'Senegal' },
    { value: 'Serbia', label: 'Serbia' },
    { value: 'Seychelles', label: 'Seychelles' },
    { value: 'Sierra Leone', label: 'Sierra Leone' },
    { value: 'Singapore', label: 'Singapore' },
    { value: 'Slovakia', label: 'Slovakia' },
    { value: 'Slovenia', label: 'Slovenia' },
    { value: 'Solomon Islands', label: 'Solomon Islands' },
    { value: 'Somalia', label: 'Somalia' },
    { value: 'South Africa', label: 'South Africa' },
    { value: 'South Korea', label: 'South Korea' },
    { value: 'South Sudan', label: 'South Sudan' },
    { value: 'Spain', label: 'Spain' },
    { value: 'Sri Lanka', label: 'Sri Lanka' },
    { value: 'Sudan', label: 'Sudan' },
    { value: 'Suriname', label: 'Suriname' },
    { value: 'Sweden', label: 'Sweden' },
    { value: 'Switzerland', label: 'Switzerland' },
    { value: 'Syria', label: 'Syria' },
    { value: 'Tajikistan', label: 'Tajikistan' },
    { value: 'Tanzania', label: 'Tanzania' },
    { value: 'Thailand', label: 'Thailand' },
    { value: 'Togo', label: 'Togo' },
    { value: 'Tonga', label: 'Tonga' },
    { value: 'Trinidad and Tobago', label: 'Trinidad and Tobago' },
    { value: 'Tunisia', label: 'Tunisia' },
    { value: 'Turkey', label: 'Turkey' },
    { value: 'Turkmenistan', label: 'Turkmenistan' },
    { value: 'Tuvalu', label: 'Tuvalu' },
    { value: 'Uganda', label: 'Uganda' },
    { value: 'Ukraine', label: 'Ukraine' },
    { value: 'United Arab Emirates', label: 'United Arab Emirates' },
    { value: 'United Kingdom', label: 'United Kingdom' },
    { value: 'United States', label: 'United States' },
    { value: 'Uruguay', label: 'Uruguay' },
    { value: 'Uzbekistan', label: 'Uzbekistan' },
    { value: 'Vanuatu', label: 'Vanuatu' },
    { value: 'Vatican City', label: 'Vatican City' },
    { value: 'Venezuela', label: 'Venezuela' },
    { value: 'Vietnam', label: 'Vietnam' },
    { value: 'Yemen', label: 'Yemen' },
    { value: 'Zambia', label: 'Zambia' },
    { value: 'Zimbabwe', label: 'Zimbabwe' },
];



// Export all functions.
export {
    createQrToken,
    parseDate,
    completeDate,
    appendFormData,
    statuses,
    species,
    dogBreeds,
    catBreeds,
    sexes,
    sterilizedArray,
    medicalRecordTypes,
    medicalRecordVaccines,
    medicalRecordSurgery,
    medicalRecordsDiseases,
    medicalRecordsAccidents,
    countries,
};
