import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter } from '@nextui-org/react';


MedicalRecordModal.propTypes = {
    disclosure: PropTypes.object.isRequired,
    medicalRecords: PropTypes.string.isRequired,
};


export default function MedicalRecordModal({ disclosure, medicalRecords }) {
    return (
        <>
            <Modal
                backdrop='opaque' 
                isOpen={disclosure.isOpen} 
                onOpenChange={disclosure.onOpenChange}
                placement='center'
                classNames={{
                    wrapper: 'modal',
                    backdrop: 'bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20'
                }}
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className='modal-header flex justify-center items-center relative'>
                                <h3 className='uppercase'>Ficha Médica</h3>
                            </ModalHeader>

                            <ModalBody className='px-2'>
                                {medicalRecords && medicalRecords.map((medicalRecord, index) => {
                                    const type = medicalRecord.type.charAt(0).toUpperCase() + medicalRecord.type.slice(1);
                                    const description = medicalRecord.description.charAt(0).toUpperCase() + medicalRecord.description.slice(1);

                                    return (
                                        <div key={medicalRecord.id} className={`flex flex-col gap-2 ${index % 2 !== 0 ? 'bg-gray-100' : ''} py-1 px-2 rounded-md`}>
                                            <div className='flex justify-between'>
                                                <p className='font-bold text-lg'>{type}</p>
                                                <p className='text-sm'>{medicalRecord.date}</p>
                                            </div>
                                            <p className=''>{description}. {medicalRecord.observation}</p>
                                        </div>
                                    );
                                })}
                            </ModalBody>

                            <ModalFooter className='flex flex-col gap-2 pt-0'>
                                <Button
                                    color='default'
                                    className='w-full uppercase shadow-lg mt-1 mx-auto'
                                    radius='sm'
                                    onPress={onClose}
                                    style={{ maxWidth: '300px' }}
                                >
                                    Cerrar
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
}
