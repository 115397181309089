// Import modules.
import React from 'react';
import PropTypes from 'prop-types';

// Define properties.
Paw.propTypes = {
    className: PropTypes.string,
};


export default function Paw({ className = '' }) {
    return (
        <svg className={'paw ' + className} width='103' height='97' viewBox='0 0 103 97' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M78.4 86.0001C77.1 86.3001 75.8 86.4001 74.5 86.4001C71.4 86.4001 68.4 86.3001 65.3 86.4001C62 86.5001 59.1 87.4001 56.3 88.8001C54.2 89.8001 52.2 90.9001 50.2 92.0001C48 93.2001 45.7 94.0001 43.1 94.0001C33.5 93.9001 27.3 83.3001 32.3 74.8001C33.2 73.2001 34.3 71.7001 35.2 70.2001C37.5 66.6001 39.1 62.7001 39.7 58.5001C40.1 56.1001 40.4 53.8001 40.8 51.4001C41.4 47.2001 43.4 43.8001 47.2 41.7001C52.5 38.6001 59.1 39.6001 63.1 44.3001C64.7 46.2001 66 48.3001 67.5 50.3001C67.7 50.5001 67.8 50.8001 68 51.0001C71 56.1001 75.6 59.5001 80.9 62.0001C83.2 63.1001 85.2 64.6001 86.5 66.8001C91.9 75.7001 85.6 84.9001 78.4 86.0001Z' stroke='black' strokeWidth='4.5' strokeMiterlimit='10'/>
            <path d='M48.3999 21.6001C47.1999 15.4001 48.7999 10.1002 53.3999 5.80015C56.8999 2.50015 61.0999 1.60014 65.6999 3.60014C66.4999 4.00014 67.1999 4.40017 67.8999 5.00017C70.8999 7.80017 72.4999 11.2002 72.4999 15.3002C72.4999 21.0002 70.4998 25.7001 65.7998 29.1001C63.1998 31.0001 60.3999 31.6002 57.1999 31.0002C53.7999 30.4002 51.4998 28.4002 49.7998 25.5002C49.0998 24.4002 48.5999 23.1001 48.3999 21.6001Z' stroke='black' strokeWidth='4.5' strokeMiterlimit='10'/>
            <path d='M2.79991 59.0002C1.89991 54.4002 2.1999 51.3002 4.0999 48.4002C6.0999 45.4002 8.99992 43.9002 12.4999 43.7002C16.9999 43.5002 20.6999 45.2002 23.8999 48.2002C25.9999 50.2002 27.5999 52.6002 28.3999 55.4002C29.5999 59.8002 29.1999 63.9002 25.6999 67.3002C22.9999 69.9002 19.6999 70.7002 15.9999 70.2002C8.59992 69.3002 3.39991 62.8002 2.79991 59.0002Z' stroke='black' strokeWidth='4.5' strokeMiterlimit='10'/>
            <path d='M99.4 35.4002C100.9 41.2002 97.8999 49.6002 90.7999 53.1002C87.9999 54.5002 85.1999 54.7002 82.2999 53.9002C80.9999 53.5002 79.7999 52.9002 78.7999 52.0002C75.9999 49.5002 74.4999 46.3002 74.5999 42.5002C74.7999 35.6002 77.8999 30.5002 83.9999 27.4002C87.2999 25.8002 90.7999 25.8002 94.0999 27.6002C96.5999 28.9002 98.1999 31.1002 99.0999 33.7002C99.1999 34.3002 99.3 34.9002 99.4 35.4002Z' stroke='black' strokeWidth='4.5' strokeMiterlimit='10'/>
            <path d='M41.7 24.2002C42.3 26.3002 42.2 29.2002 40.8 31.9002C38.9 35.7002 35.9 37.9002 31.5 37.9002C25.2 38.0002 20.8 34.6002 17.9 29.4002C15.9 25.9002 15.3 22.1002 16.5 18.1002C17.7 14.4002 20.1 11.8002 24.1 10.9002C27.5 10.1002 30.6 10.7002 33.5 12.4002C37.8 15.0002 40.4 18.6002 41.7 24.2002Z' stroke='black' strokeWidth='4.5' strokeMiterlimit='10'/>
        </svg>
    );
}
