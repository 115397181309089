// Import modules.
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter } from '@nextui-org/react';
import { QrScanner } from '@yudiel/react-qr-scanner';
import qrService from '../../../services/qrs.js';

// Import icons.
import { QRCode as QRCodeIcon } from '../../../components/Icons/QRCode.jsx';

// Define properties.
ViewTagModal.propTypes = {
    disclosure: PropTypes.object.isRequired,
    petId: PropTypes.string.isRequired,
};


export default function ViewTagModal({ disclosure, petId }) {
    const [scanQRMessage, setScanQRMessage] = useState('');
    const [scanQRMessageType, setScanMessageType] = useState('');
    const [isLoading, setIsLoading] = useState(false);


    /**
     * Get the complete url of the QR code, and set the token state.
     *
     * @param {string} result The result of the QR scan.
     *
     * @returns {void}
     */
    const handleQRScan = async (result) => {
        if (scanQRMessage === 'El código QR se encuentra disponible.') { return; }

        // Get the token.
        const scannedToken = result.split('perfil/')[1];
        
        // Set QR message.
        setScanQRMessage('Validando el QR escaneado');
        setScanMessageType('');

        if (isLoading) { return; }

        // Change the loading state.
        setIsLoading(true);

        const response = await qrService.link(petId, scannedToken);

        setIsLoading(false);
        setScanQRMessage(response.message);
        setScanMessageType(response.success ? 'success' : 'danger');

        if (response.success) {
            setTimeout(() => {
                // Close the modal.
                disclosure.onClose();

                // Set QR message.
                setScanQRMessage('');
                setScanMessageType('');

                window.location.reload();
            }, 1000);
        }
    };

    return (
        <>
            <Modal
                backdrop='opaque' 
                isOpen={disclosure.isOpen}
                onOpenChange={disclosure.onOpenChange}
                placement='center'
                classNames={{
                    backdrop: 'bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20'
                }}
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className='modal-header flex justify-center items-center gap-4 relative'>
                                <h3 className='uppercase'>Vincular Tag</h3>
                                <QRCodeIcon />
                            </ModalHeader>

                            <ModalBody className='flex flex-col items-center'>
                                <p>Escanee el código QR de su mascota</p>
                                
                                <QrScanner
                                    onDecode={(result) => handleQRScan(result)}
                                    onError={(error) => console.log(error?.message)}
                                    scanDelay={isLoading ? 1000000 : 750}
                                />

                                {isLoading && (
                                    <CircularProgress aria-label='Validando token'/>
                                )}

                                {scanQRMessage && (
                                    <p className={'text-center mt-1 text-' + scanQRMessageType} style={{fontWeight: 'bold'}}>{scanQRMessage}</p>
                                )}
                            </ModalBody>

                            <ModalFooter className='pt-0'>
                                <Button
                                    color='default'
                                    className='w-full uppercase shadow-lg mx-auto'
                                    radius='sm'
                                    onPress={onClose}
                                    style={{ maxWidth: '300px' }}
                                >
                                    Cerrar
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
}
