// Import modules.
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter } from '@nextui-org/react';
import medicalRecordService from '../../../services/medicalRecords.js';

// Import icons.
import Trash from '../../../components/Icons/Trash.jsx';
import Danger from '../../../components/Icons/DangerIcon.jsx';

// Define properties.
DeleteMedicalRecordModal.propTypes = {
    disclosure: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    callback: PropTypes.func,
};


export default function DeleteMedicalRecordModal({ disclosure, id, callback }) {
    const [isLoading, setIsLoading] = useState(false);
    

    /**
     * Handle the delete pet action.
     *
     * @returns {void}
     */
    const handleDeleteMedicalRecord = async () => {
        // Change loading state.
        setIsLoading(true);

        const response = await medicalRecordService.remove(id);

        if (response.success) {
            // Close the modal.
            disclosure.onClose();

            // Reload the page.
            await callback();
        }

        // Change loading state.
        setIsLoading(false);
    };


    return (
        <>
            <Modal
                backdrop='opaque' 
                isOpen={disclosure.isOpen} 
                onOpenChange={disclosure.onOpenChange}
                placement='center'
                classNames={{
                    wrapper: 'modal-danger',
                    backdrop: 'bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20'
                }}
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className='modal-header flex justify-center items-center gap-4 relative'>
                                <h3 className='uppercase'>Eliminar registro</h3>
                                <Trash />
                            </ModalHeader>

                            <ModalBody>
                                <Danger className='fill-danger mx-auto' />

                                <p className='text-center'>¿Estás seguro de que deseas eliminar este?</p>
                                <p className='text-center'>Esta acción no se puede deshacer.</p>
                            </ModalBody>

                            <ModalFooter className='flex flex-col gap-2 pt-0'>
                                <Button
                                    color='danger'
                                    className='w-full uppercase shadow-lg mt-1 mx-auto'
                                    radius='sm'
                                    onPress={handleDeleteMedicalRecord}
                                    style={{ maxWidth: '300px' }}
                                    isLoading={isLoading}
                                >
                                    <Trash className='p-1'/>
                                    Eliminar
                                </Button>

                                <Button
                                    color='default'
                                    className='w-full uppercase shadow-lg mt-1 mx-auto'
                                    radius='sm'
                                    onPress={onClose}
                                    style={{ maxWidth: '300px' }}
                                    isDisabled={isLoading}
                                >
                                    Cerrar
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
}
