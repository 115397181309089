// Import modules.
import React from 'react';
import PropTypes from 'prop-types';

// Define properties.
PawShape.propTypes = {
    className: PropTypes.string,
};


export default function PawShape({ className }) {
    return (
        <svg className={'paw-shape ' + className} width='310' height='308' viewBox='0 0 310 308' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M308.1 136.8C304.2 115 292.4 99 274.2 88.5C271 86.6 267.5 85.3 264.1 83.8C262.6 83.1001 261.9 82 261.9 80.3C262 76.3 262.1 72.3999 262.1 68.3999C261.9 49.5999 255.2 33.7001 243 20.6001C230.3 7.0001 214.8 0.199951 196.6 0.199951C182.8 0.199951 170.4 4.6 159.3 13C154.7 16.4 154.7 16 150.8 13.3C150.3 12.9 149.8 12.5 149.3 12.2C133.6 1.59995 116.6 -1.19995 98.7 3.30005C69.8 10.6 48.3 39.6999 48.8 72.8999C48.9 76.1999 48.9 79.5 49.6 82.7C49.9 83.8999 49.3 84.8 48.2 85.3C47.1 85.8 45.9 86.2001 44.7 86.6001C38.1 89.1001 32 92.6001 26.4 97.1001C6.89997 112.9 -3.00002 140.4 1.59998 166C5.19998 185.9 14.8 201.3 30.4 212.3C33.6 214.6 36.2 217 37.2 221.1C37.3 221.5 37.5 221.9 37.7 222.3C47 246.5 61.7 266.2 81.4 281.4C104.1 299 129.6 307.8 162 307.4C166.8 307.5 175.7 306.4 184.5 304.3C212.3 297.5 235.5 282.6 254.2 259.4C264.7 246.3 272.8 231.6 278.1 215.2C279.2 211.7 281 209.1 283.6 206.8C304.6 188.7 313.3 165.3 308.1 136.8Z' />
        </svg>        
    );
}
