// Import required modules.
import { React, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
    Button,
    Input,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from '@nextui-org/react';

// Import required icons.
import QRCode from '../../../components/Icons/QRCode.jsx';


export default function AddQR({ isOpen, onClose, fetchQRs, setAlertType, setAlertMessage }) {
    // Declare states.
    const [amount, setAmount] = useState(1);

    // Declare utilities.
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // Check if the amount is a valid number.
        if (amount > 0) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [amount]);


    /**
     * Handle the creation of one or more QRs.
     *
     * @returns {void}
     */
    const handleAddQr = () => {
        // Change the loading state.
        setButtonDisabled(true);
        setIsLoading(true);

        // Send the request to the API.
        axios.post(`${process.env.REACT_APP_API_ENDPOINT}qrs`, {
            amount: amount,
        })
            .then((response) => {
                // Change the loading state.
                setButtonDisabled(false);
                setIsLoading(false);

                // Replace the response object.
                response = response.data;

                // Show a response message.
                setAlertType(response.success ? 'success' : 'error');
                setAlertMessage(response.message);

                // Check if the request was successful.
                if (response.success) {
                    // Reset the amount.
                    setAmount(1);

                    // Close the modal.
                    onClose();

                    // Fetch the QR list.
                    fetchQRs();
                }

                // Remove the message after 5 seconds.
                setTimeout(() => {
                    setAlertType('');
                    setAlertMessage('');
                }, 5000);
            })
            .catch((error) => {
                if (error.request) {
                    // Change the loading state.
                    setButtonDisabled(false);
                    setIsLoading(false);

                    // Show an error message.
                    setAlertType('error');
                    setAlertMessage(['Ha ocurrido un error al agregar el QR.', 'Por favor, recargue la página e intente nuevamente.']);

                    // Remove the message after 5 seconds.
                    setTimeout(() => {
                        setAlertType('');
                        setAlertMessage('');
                    }, 5000);
                }
            });
    };


    return (
        <Modal
            backdrop='blur'
            isOpen={isOpen}
            placement='center'
            onClose={onClose}
            className='modal modal-add'
            classNames={{
                closeButton: 'z-1'
            }}
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className='flex gap-1 mb-2'>
                            <h2 className='uppercase'>Agregar nuevo QR</h2>
                            <QRCode />
                        </ModalHeader>

                        <ModalBody>
                            {/* Amount */}
                            <Input
                                type='number'
                                label='Cantidad de QR a generar'
                                className='input'
                                classNames={{
                                    inputWrapper: 'shadow-md',
                                }}
                                radius='sm'
                                value={amount}
                                onChange={(event) => setAmount(event.target.value)}
                                min={1}
                                isDisabled={isLoading}
                            />
                        </ModalBody>

                        {/* Footer */}
                        <ModalFooter className='flex flex-col'>
                            <Button
                                className='uppercase shadow-md mb-1'
                                color='secondary'
                                isDisabled={buttonDisabled}
                                isLoading={isLoading}
                                onPress={handleAddQr}
                            >
                                Crear
                            </Button>

                            <Button
                                className='bg-light uppercase shadow-md'
                                color='light'
                                onPress={onClose}
                                isDisabled={isLoading}
                            >
                                Cerrar
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
}

AddQR.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    fetchQRs: PropTypes.func.isRequired,
    setAlertType: PropTypes.func.isRequired,
    setAlertMessage: PropTypes.func.isRequired,
};
