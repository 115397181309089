// Import modules.
import axios from 'axios';
import { parseDate } from '../utils/helpers.js';


/**
 * Get all Medical Records of a pet.
 *
 * @param {string} petId - The ID of the pet.
 *
 * @returns {Promise} - The axios promise.
 */
export const getMedicalRecords = async (petId) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}pets/${petId}/medical-records`);

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};


/**
 * Add a new medical record.
 *
 * @param {string} petId - The ID of the pet.
 * @param {string} type - The type of the medical record.
 * @param {string} description - The description of the medical record.
 * @param {string} date - The date of the medical record.
 * @param {string} observations - The observations of the medical record.
 *
 * @returns {Promise} - The axios promise.
 */
export const addMedicalRecord = async (id, type, description, date = '', observations = '') => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}pets/${id}/medical-records`, {
            type,
            description,
            date: parseDate(date),
            observations,
        });

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};


/**
 * Edit a medical record.
 *
 * @param {string} id - The ID of the medical record.
 * @param {string} type - The type of the medical record.
 * @param {string} description - The description of the medical record.
 * @param {string} date - The date of the medical record.
 * @param {string} observations - The observations of the medical record.
 *
 * @returns {Promise} - The axios promise.
 */
export const editMedicalRecord = async (id, type, description, date = '', observations = '') => {
    console.log('editMedicalRecord', id, type, description, date, observations);
    try {
        const response = await axios.put(`${process.env.REACT_APP_API_ENDPOINT}medical-records/${id}`, {
            type,
            description,
            date: parseDate(date),
            observations,
        });

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};


/**
 * Delete a medical record.
 *
 * @param {string} id - The ID of the medical record.
 *
 * @returns {Promise} - The axios promise.
 */
export const remove = async (id) => {
    try {
        const response = await axios.delete(`${process.env.REACT_APP_API_ENDPOINT}medical-records/${id}`);

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};


export default {
    getMedicalRecords,
    addMedicalRecord,
    editMedicalRecord,
    remove
};