// Import modules.
import axios from 'axios';


/**
 * Check the status of a QR code. If the QR is unused it will be assigned to the pet.
 *
 * @param {string} petId - The pet id.
 * @param {string} scannedToken - The scanned token.
 *
 * return {Promise<object>} The response object.
 */
export const link = async (petId, scannedToken) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}qrs/pet/${petId}`, {
            token: scannedToken,
        });

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};


export default {
    link,
};
