// Import modules.
import React from 'react';
import PropTypes from 'prop-types';

// Import icons.
import Star from '../Icons/Star.jsx';
import StarFilled from '../Icons/StarFilled.jsx';

// Import assets.
import './Review.css';


// Define properties.
Review.propTypes = {
    review: PropTypes.shape({
        name: PropTypes.string,
        petName: PropTypes.string,
        petPhoto: PropTypes.string,
        rating: PropTypes.number,
        review: PropTypes.string,
    }),
};


export default function Review({ review }) {
    return (
        <div className='review flex flex-col justify-start items-center bg-primary-light text-white shadow-lg relative p-4'>
            <div className='stars flex mb-2'>
                {[1, 2, 3, 4, 5].map((star, index) => {
                    return review.rating >= star ? <StarFilled key={index} className='mx-1' /> : <Star key={index} className='mx-1' />;
                })}
            </div>

            <div className='name-pet flex justify-center items-center mb-2'>
                <span>{review.name} y {review.petName}</span>
            </div>

            <div className='body text-center'>
                {review.review.map((line, index) => <p key={index}>{line}</p>)}
            </div>
            
            <div className='image shadow-lg'>
                <img src={review.petPhoto} alt={review.petName} />
            </div>
        </div>
    );
}
