// Import required modules.
import { React, useEffect, useState } from 'react';
import propTypes from 'prop-types';
import axios from 'axios';
import {
    Button,
    Input,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Select,
    SelectItem,
    Tooltip,
} from '@nextui-org/react';
import QRCode from 'react-qr-code';

// Import required icons.
import DownloadIcon from '../../../components/Icons/DownloadIcon';
import QRCodeIcon from '../../../components/Icons/QRCode';

const petProfileURL = 'https://patatag.com/perfil/';

const statuses = [
    { id: '1', description: 'Activo' },
    { id: '2', description: 'Inactivo' },
    { id: '3', description: 'Bloqueado' },
];


export default function EditQR({ isOpen, onClose, selectedQR, fetchQRs, setAlertType, setAlertMessage }) {
    // Declare states.
    const [status, setStatus] = useState(selectedQR.status);
    const [user, setUser] = useState(selectedQR.user);

    // Utilities.
    const [isLoading, setIsLoading] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);


    // Check if the button should be disabled. It
    useEffect(() => {
        if (status === selectedQR.status && user === selectedQR.user) {
            setButtonDisabled(true);
        } else {
            setButtonDisabled(false);
        }
    }, [user, status]);


    // Handle the update of the selectedQR.
    useEffect(() => {
        // console.log('Patam: selectedQr: ', selectedQR);
        setStatus(selectedQR.status);
        setUser(selectedQR.ownerId);
    }, [selectedQR]);


    /**
     * Handle the download of the QR image.
     *
     * @returns {void}
     */
    const handleDownloadQR = () => {
        // Get the content of the SVG as plain element.
        const tokenElement = document.getElementById('qrCode');

        // Transform the html element into plain text.
        const tokenXML = new XMLSerializer().serializeToString(tokenElement);

        // Create a new canvas element.
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Load the SVG into the canvas.
        const img = new Image();
        img.src = 'data:image/svg+xml;base64,' + btoa(tokenXML);

        // Draw the image into the canvas.
        img.onload = () => {
            canvas.width = img.width * 2 + 16;
            canvas.height = img.height * 2 + 16;
            ctx.fillStyle = '#fff';
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(img, 8, 8, img.width * 2, img.height * 2);

            // Create a link to download the image.
            const a = document.createElement('a');
            a.download = `qr-${selectedQR.tokenQr}.png`;
            a.href = canvas.toDataURL('image/png');
            a.click();
        };
    };

    
    /**
     * Handle the edit of a new QR.
     *
     * @returns {void}
     */
    const handleEditQr = () => {
        // Change the loading state.
        setButtonDisabled(true);
        setIsLoading(true);

        // Send the request to the API.
        axios.put(`${process.env.REACT_APP_API_ENDPOINT}qrs/${selectedQR.id}`, {
            statusId: parseInt(status),
        })
            .then((response) => {
                // Change the loading state.
                setButtonDisabled(false);
                setIsLoading(false);
                
                // Replace the response content.
                response = response.data;
                
                // Show a success message.
                setAlertType(response.success ? 'success' : 'error');
                setAlertMessage([response.message]);

                // Check if the response was successful.
                if (response.success) {
                    // Close the modal.
                    onClose();

                    // Fetch the QR list.
                    fetchQRs();
                }

                // Remove the message after 5 seconds.
                setTimeout(() => {
                    setAlertType('');
                    setAlertMessage('');
                }, 5000);
            })
            .catch((error) => {
                console.log(error);
            });
    };



    return (
        <Modal
            backdrop='blur'
            isOpen={isOpen}
            placement='center'
            onClose={onClose}
            className='modal modal-edit'
            classNames={{
                closeButton: 'z-1'
            }}
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className='flex justify-between items-center gap-1 mb-2'>
                            {/* <h2 className='uppercase'>Editar QR</h2> */}
                            <h2 className='uppercase'>Ver QR</h2>
                            <QRCodeIcon />
                        </ModalHeader>

                        <ModalBody>
                            {/* QR Code */}
                            <QRCode
                                id='qrCode'
                                size={120}
                                value={petProfileURL + selectedQR.tokenQr}
                                className='mx-auto mb-1'
                            />

                            <div className='flex items-center gap-2 mb-1'>
                                {/* Token */}
                                <Input
                                    type='text'
                                    label='Token'
                                    className='input'
                                    classNames={{
                                        inputWrapper: 'shadow-md',
                                    }}
                                    radius='sm'
                                    value={selectedQR.tokenQr}
                                    isDisabled
                                />
                                
                                {/* Download Link */}
                                <Tooltip content='Descargar imagen QR'>
                                    <Button
                                        className='text-lg text-default-400 bg-light cursor-pointer active:opacity-50 shadow-md p-1'
                                        key='editQR'
                                        radius='sm'
                                        onPress={() => handleDownloadQR()}
                                        style={{ minWidth: 'unset', height: '56px' }}
                                    >
                                        <DownloadIcon />
                                    </Button>
                                </Tooltip>
                            </div>

                            {/* Status */}
                            {/* <Select
                                label='Estado'
                                classNames={{
                                    trigger: 'shadow-md mb-1',
                                }}
                                radius='sm'
                                disabledKeys={['selectOption']}
                                selectedKeys={[status]}
                                isDisabled={isLoading}
                            >
                                <SelectItem
                                    key=''
                                    value=''
                                >
                                    Seleccione un estado
                                </SelectItem>

                                {statuses.map((status) => (
                                    <SelectItem
                                        key={status.id}
                                        value={status.id}
                                        onClick={() => setStatus(status.id)}
                                    >
                                        {status.description}
                                    </SelectItem>
                                ))}
                            </Select> */}
                        </ModalBody>

                        {/* Footer */}
                        <ModalFooter className='flex flex-col'>
                            {/* <Button
                                className='uppercase shadow-md mb-1'
                                color='secondary'
                                isDisabled={buttonDisabled}
                                isLoading={isLoading}
                                onPress={handleEditQr}
                            >
                                Actualizar
                            </Button> */}

                            <Button className='bg-light uppercase shadow-md' color='light' onPress={onClose}>
                                Cerrar
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
}

EditQR.propTypes = {
    isOpen: propTypes.bool.isRequired,
    onClose: propTypes.func.isRequired,
    selectedQR: propTypes.object.isRequired,
    fetchQRs: propTypes.func.isRequired,
    setAlertType: propTypes.func.isRequired,
    setAlertMessage: propTypes.func.isRequired,
};