// Import modules.
import axios from 'axios';
import { appendFormData, parseDate } from '../utils/helpers.js';


/**
 * Get a pet by its ID.
 *
 * @param {string} petId - The ID of the pet.
 *
 * @returns {Promise} - The axios promise.
 */
export const get = async (petId) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}pets/${petId}`);

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};


/**
 * Get a pet by its id.
 *
 * @param {string} id - The ID of the pet.
 *
 * @returns {Promise} - The axios promise.
 */
export const getById = async (id) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}pets/id/${id}`);

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};


/**
 * Get a pet by its token.
 *
 * @param {string} token - The QR token associated with the pet.
 *
 * @returns {Promise} - The axios promise.
 */
export const getByToken = async (token) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}pets/token/${token}`);

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};


/**
 * Add a new pet.
 *
 * @param {string} userId - The ID of the user.
 * @param {string} photoFile - The photo of the pet.
 * @param {string} name - The name of the pet.
 * @param {string} specie - The specie of the pet.
 * @param {string} breed - The breed of the pet.
 * @param {string} birthday - The birthday of the pet.
 * @param {string} sex - The sex of the pet.
 * @param {boolean} sterilized - If the pet is sterilized or not.
 * @param {string} microchip - The number of microchip of the pet.
 * @param {string} clarification - The clarification of the pet.
 */
export const add = async (userId, photoFile, name, specie, breed, birthday, sex, sterilized, microchip, clarification) => {
    try {
        let formData = new FormData();

        formData = appendFormData(formData, 'userId', userId);
        formData = appendFormData(formData, 'photoFile', photoFile);
        formData = appendFormData(formData, 'name', name);
        formData = appendFormData(formData, 'specie', specie);
        formData = appendFormData(formData, 'breed', breed);
        formData = appendFormData(formData, 'birthday', birthday);
        formData = appendFormData(formData, 'sex', sex);
        formData = appendFormData(formData, 'sterilized', sterilized);
        formData = appendFormData(formData, 'microchip', microchip);
        formData = appendFormData(formData, 'clarification', clarification);

        const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}pets`, formData);

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};


/**
 * Check if a pet belongs to a user.
 *
 * @param {string} petId - The ID of the pet.
 * @param {string} userId - The ID of the user.
 *
 * @returns {Promise} - The axios promise.
 */
export const belongsToUser = async (petId, userId) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}pets/${petId}/user/${userId}`);

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};


/**
 * Update a pet.
 *
 * @param {string} petId - The ID of the pet.
 * @param {string} photoFile - The photo of the pet.
 * @param {string} name - The name of the pet.
 * @param {string} specie - The specie of the pet.
 * @param {string} breed - The breed of the pet.
 * @param {string} birthday - The birthday of the pet.
 * @param {string} sex - The sex of the pet.
 * @param {boolean} sterilized - If the pet is sterilized or not.
 * @param {string} microchip - The number of microchip of the pet.
 * @param {string} clarification - The clarification of the pet.
 *
 * @returns {Promise} - The axios promise.
 */
export const update = async (petId, photoFile, name, specie, breed, birthday, sex, sterilized, microchip, clarification) => {
    try {
        const response = await axios.put(`${process.env.REACT_APP_API_ENDPOINT}pets/${petId}`, {
            name,
            specie,
            breed,
            birthday: parseDate(birthday),
            sex,
            sterilized,
            microchip,
            clarification,
        });

        if (response.data.success) {
            // Update image.
            if (photoFile) {
                const updateImageResponse = await updateImage(petId, photoFile);

                return updateImageResponse;
            }
        }

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};


/**
 * Update the image of a pet.
 *
 * @param {string} id - The ID of the pet.
 * @param {string} photoFile - The photo of the pet.
 *
 * @returns {Promise} - The axios promise.
 */
const updateImage = async (id, photoFile) => {
    try {
        let formData = new FormData();
        formData = appendFormData(formData, 'photoFile', photoFile);

        const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}pets/${id}/image`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};


/**
 * Delete a pet.
 *
 * @param {string} petId - The ID of the pet.
 *
 * @returns {Promise} - The axios promise.
 */
export const remove = async (petId) => {
    try {
        const response = await axios.delete(`${process.env.REACT_APP_API_ENDPOINT}pets/${petId}`);

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};


export default {
    get,
    getById,
    getByToken,
    add,
    belongsToUser,
    update,
    remove,
};