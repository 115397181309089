// Import required modules.
import React from 'react';
import PropTypes from 'prop-types';
import { Button as NextUIButton } from '@nextui-org/react';
import { Link } from 'react-router-dom';

// Import required styles.
import './Button.css';

function Button({ id, to, type, icon, text, onClick, className, isDisabled, isLoading }) {
    return (
        <Link
            id={id}
            className={'button mt-2 mx-auto w-full z-10 button-' + type + ' ' + className }
            to={to || null}
            style={{maxWidth: '300px'}}
        >
            <NextUIButton
                className='uppercase shadow-lg w-full'
                onClick={onClick}
                isDisabled={isDisabled}
                isLoading={isLoading}
                radius='sm'
            >
                <div className='content'>
                    {icon}
                    <span className={icon && 'ml-2'} style={{maxWidth: '200px'}}>{text}</span>
                </div>
            </NextUIButton>
        </Link>
    );
}

export default Button;

// PropType definitions.
Button.propTypes = {
    id: PropTypes.string,
    to: PropTypes.string,
    type: PropTypes.string.isRequired,
    icon: PropTypes.element,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
};