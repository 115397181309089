import React from 'react';
import PropTypes from 'prop-types';
import './alert.css';

function Alert( { type, messages, className } ) {
    return (
        <div className={'alert bg-white border p-1 px-3 mb-4 z-10 border-left-' + type + ' ' + className}>
            {messages && messages.map((message, key) => (
                <p className='mb-0 ml-2' key={key}>{message}</p>
            ))}
        </div>
    );
}

export default Alert;

Alert.propTypes = {
    type: PropTypes.string.isRequired,
    messages: PropTypes.array.isRequired,
    className: PropTypes.string,
};
