// Import modules.
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalContent } from '@nextui-org/react';

// Define properties.
GenericModal.propTypes = {
    disclosure: PropTypes.object.isRequired,
    status: PropTypes.string,
    children: PropTypes.node.isRequired
};


export default function GenericModal({ disclosure, status, children }) {
    return (
        <>
            <Modal
                backdrop='opaque' 
                isOpen={disclosure.isOpen} 
                onOpenChange={disclosure.onOpenChange}
                placement='center'
                classNames={{
                    wrapper: 'modal ' + status,
                    backdrop: 'bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20'
                }}
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            {children}
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
}
