// Import modules.
import React from 'react';
import PropTypes from 'prop-types';

// Define properties.
ChevronLeft.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
};


export default function ChevronLeft(props) {
    const { className, onClick } = props;

    return (
        <svg className={'chevron-left ' + className} onClick={onClick} width='13' height='22' viewBox='0 0 13 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M10.8 0.300049C11.1 0.300049 11.4 0.399951 11.7 0.699951C12.2 1.19995 12.2 1.9999 11.7 2.3999L3 11.1001L11.7 19.8C12.2 20.3 12.2 21.1 11.7 21.5C11.2 22 10.4 22 10 21.5L0.5 12C0 11.5 0 10.7 0.5 10.3L10 0.800049C10.1 0.400049 10.5 0.300049 10.8 0.300049Z' fill='#1C1D1E'/>
        </svg>        
    );
}
