import { React, useState, useEffect } from 'react';
import { Button } from '@nextui-org/react';
import userService from '../../services/users.js';

import PageContent from '../../components/PageContent/PageContent.jsx';
import PetCard from '../../components/PetCard/PetCard.jsx';
import FadeInDiv from '../../components/Animated/FadeInDiv.jsx';

import CreditCard from '../../components/Icons/CreditCard.jsx';
import Crown from '../../components/Icons/Crown.jsx';
import Pencil from '../../components/Icons/Pencil.jsx';
import Plus from '../../components/Icons/Plus.jsx';

import './Panel.css';


export default function Panel() {
    // Declare states.
    const [user, setUser] = useState(null);

    // Utilities.
    const [userRole, setUserRole] = useState(null);
    const [isPageLoading, setIsPageLoading] = useState(true);


    useEffect(() => {
        /**
         * Check user session. The function is declared as async to use the await keyword.
         *
         * @returns {Promise<void>}
         */
        const checkSession = async () => {
            setIsPageLoading(true);

            try {
                const checkSessionResponse = await userService.checkSession();

                if (!checkSessionResponse.success) {
                    throw checkSessionResponse.error;
                }

                const userSession = JSON.parse(atob(checkSessionResponse.data));

                setUserRole(userSession.role);

                const response = await userService.getUserProfile(userSession.id);
                
                setUser(response.user);
                setIsPageLoading(false);
            } catch (error) {
                setIsPageLoading(false);
            }
        };

        checkSession();
    }, []);


    return (
        <div id='panelPage' className='page'>
            <PageContent withBackground isAwaiting={isPageLoading} userRole={userRole}>
                <FadeInDiv>
                    <div className='title w-full mb-4'>
                        <h1 className='main-title text-center mb-2 z-10'>Perfil</h1>
                    </div>
                </FadeInDiv>

                <div className='flex flex-col mb-8'>
                    <div className='user-data flex flex-col mx-auto w-full' style={{ maxWidth: '300px' }}>
                        {user && (
                            <FadeInDiv>
                                <p className='mb-1'><span className='bold'>Nombre:</span> {user.name + ' ' + user.last_name}</p>
                                <p className='mb-1'><span className='bold'>Email:</span> {user && user.email}</p>
                                {user.phone ? <p className='mb-1'><span className='bold'>Teléfono:</span> {user.phone}</p> : ''}
                                {user.country ? <p className='mb-1'><span className='bold'>País:</span> {user.country}</p> : ''}
                                {user.city ? <p className='mb-1'><span className='bold'>Ciudad:</span> {user.city}</p> : ''}
                                {user.address ? <p className='mb-1'><span className='bold'>Dirección:</span> {user.address}</p> : ''}
                            </FadeInDiv>
                        )}
                    </div>

                    <FadeInDiv className='flex flex-col items-center gap-3 mt-2'>
                        <Button
                            color='primary'
                            className='w-full uppercase shadow-lg mx-auto z-10'
                            radius='sm'
                            onClick={() => window.location.href = '/panel-usuario/editar-perfil/1'}
                            style={{ maxWidth: '300px' }}
                        >
                            <Pencil />
                            Editar mis datos
                        </Button>

                        <Button
                            color='primary'
                            className='w-full uppercase shadow-lg mx-auto z-10'
                            radius='sm'
                            onClick={() => window.location.href = `/panel-usuario/historial-pagos/${user && user.id}`}
                            style={{ maxWidth: '300px' }}
                        >
                            <CreditCard />
                            Historial de pagos
                        </Button>

                        <Button
                            color='primary'
                            className='w-full uppercase shadow-lg mx-auto z-10'
                            radius='sm'
                            onClick={() => window.location.href = `/panel-usuario/suscripciones/${user && user.id}`}
                            style={{ maxWidth: '300px' }}
                        >
                            <Crown />
                            Suscripciones
                        </Button>
                    </FadeInDiv>
                </div>

                <FadeInDiv>
                    <div className='title w-full mb-4'>
                        <h1 className='main-title text-center mb-2 z-10'>Mascotas</h1>
                    </div>
                </FadeInDiv>

                <div className='flex flex-col'>
                    {user && user.pets.length > 0 ? (
                        <div className='flex flex-wrap justify-between items-center gap-4'> 
                            {user.pets.map(pet => (
                                <FadeInDiv className='w-full' key={pet.id}>
                                    <PetCard pet={pet} />
                                </FadeInDiv>
                            ))}
                        </div>
                    ) : (
                        <FadeInDiv>
                            <p className='text-center'>No tienes mascotas agregadas</p>
                        </FadeInDiv>
                    )}
                    
                    <FadeInDiv className='text-center'>
                        <Button
                            color='primary'
                            className='w-full uppercase shadow-lg mt-4 mx-auto z-10'
                            radius='sm'
                            style={{ maxWidth: '300px' }}
                            onClick={() => window.location.href = '/panel-usuario/agregar-mascota'}
                        >
                            <Plus className='fill-light' />
                            Agregar mascota
                        </Button>
                    </FadeInDiv>
                </div>
            </PageContent>
        </div>
    );
}
