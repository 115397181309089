import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, useDisclosure } from '@nextui-org/react';
import userService from '../../services/users.js';
import subscriptionService from '../../services/subscriptions.js';
                        
import PageContent from '../../components/PageContent/PageContent.jsx';
import CancelSubscriptionModal from './components/CancelSubscriptionModal.jsx';
import FadeInDiv from '../../components/Animated/FadeInDiv.jsx';

import CheckCircled from '../../components/Icons/CheckCircled.jsx';

import './Subscription.css';


export default function Subscription() {
    // Declare states.
    const [subscriptions, setSubscriptions] = useState(null);
    const [subscriptionId, setSubscriptionId] = useState(null);
    const [petName, setPetName] = useState(null);

    // Utilities.
    const [userRole, setUserRole] = useState(null);
    const [isPageLoading, setIsPageLoading] = useState(true);
    
    // Disclosures.
    const cancelSubscriptionModal = useDisclosure();

    // Get the user ID from the URL.
    const { id } = useParams();
    const isValidId = /^[0-9]{1,}$/.test(id);

    if (!isValidId) {
        window.location.href = '/panel-usuario';
    }


    // Check user session and fetch the medical records.
    useEffect(() => {
        /**
         * Check user session. The function is declared as async to use the await keyword.
         *
         * @returns {Promise<void>}
         */
        const checkSession = async () => {
            setIsPageLoading(true);

            try {
                const checkSessionResponse = await userService.checkSession();

                if (!checkSessionResponse.success) {
                    throw checkSessionResponse.errors;
                }

                const userSession = JSON.parse(atob(checkSessionResponse.data));

                setUserRole(userSession.role);

                const response = await subscriptionService.getActiveSubscriptions(userSession.id);

                if (!response.success) {
                    throw response.errors;
                }

                setSubscriptions(response.data.subscriptions);
                setIsPageLoading(false);
            } catch (error) {
                setIsPageLoading(false);
            }
        };

        checkSession();
    }, []);


    return (
        <div id='subscriptionPage' className='page'>
            <PageContent withBackground isAwaiting={isPageLoading} userRole={userRole}>
                <div className='flex flex-col justify-between' style={{ minHeight: 'calc(100vh - 80px - 194px - 3rem)' }}>
                    <div className='flex flex-col'>
                        <FadeInDiv>
                            <div className='title w-full mb-4'>
                                <h1 className='main-title text-center mb-2'>Suscripción</h1>
                            </div>
                        </FadeInDiv>
                        
                        <div className='flex flex-col'>
                            {subscriptions && subscriptions.length === 0 && (
                                <FadeInDiv>
                                    <p className='text-center'>No tienes suscripciones activas.</p>
                                </FadeInDiv>
                            )}

                            {subscriptions && subscriptions.map((subscription, index) => (
                                <FadeInDiv key={index}>
                                    <div className='flex flex-col mb-5'>
                                        <div className='card flex flex-col color-light shadow-lg p-3 mb-2'>
                                            <div className='flex items-center mb-2'>
                                                <div className='check bg-white mr-2'>
                                                    <CheckCircled />
                                                </div>

                                                <div className='title'>{subscription.name} está protegido</div>
                                            </div>

                                            <div className='flex flex-col'>
                                                <p>Vigente desde el {subscription.start_date && subscription.start_date.slice(0, 10)} hasta el {subscription.end_date && subscription.end_date.slice(0, 10)}</p>
                                                <p>Valor del plan: ${subscription.amount} ARS al mes</p>
                                                <p>El importe se cobrará el día 10 de cada mes</p>
                                            </div>
                                        </div>

                                        <Button
                                            color='danger'
                                            radius='sm'
                                            onPress={() => {
                                                setSubscriptionId(subscription.id);
                                                setPetName(subscription.name);
                                                cancelSubscriptionModal.onOpen('blur');
                                            }}
                                            className='w-full uppercase shadow-lg mx-auto'
                                            style={{ maxWidth: '300px' }}
                                        >
                                            Cancelar Suscripción
                                        </Button>
                                    </div>
                                </FadeInDiv>
                            ))}
                        </div>
                    </div>
    
                    <FadeInDiv className='text-center'>
                        <Button
                            color='default'
                            className='w-full uppercase shadow-lg mt-2 mx-auto'
                            radius='sm'
                            onClick={() => window.location.href = '/panel-usuario'}
                            style={{ maxWidth: '300px' }}
                        >
                                Volver atrás
                        </Button>
                    </FadeInDiv>
                </div>

                <CancelSubscriptionModal disclosure={cancelSubscriptionModal} id={subscriptionId} petName={petName} />
            </PageContent>
        </div>
    );
}

// #TODO: Estaría bueno hacer un fetch de nuevo después de cancelar la suscripción para actualizar la lista de suscripciones.
