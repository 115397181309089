// Import modules.
import React, { useState, useEffect } from 'react';
import { Button, Input, ModalBody, ModalFooter, ModalHeader, useDisclosure } from '@nextui-org/react';
import userService from '../../services/users.js';

// Import components.
import GenericModal from '../../components/Modal/Modal.jsx';
import PageContent from '../../components/PageContent/PageContent.jsx';

// Import icons.
import Eye from '../../components/Icons/Eye.jsx';
import CheckCircled from '../../components/Icons/CheckCircled.jsx';
import EyeSlash from '../../components/Icons/EyeSlash.jsx';


export default function Register() {
    // Declare states.
    const [name, setName] = useState(null);
    const [nameError, setNameError] = useState('');
    const [lastName, setLastName] = useState(null);
    const [email, setEmail] = useState(null);
    const [emailError, setEmailError] = useState('');
    const [password, setPassword] = useState(null);
    const [passwordError, setPasswordError] = useState('');
    const [passwordIsVisible, setPasswordIsVisible] = useState(false);
    const [repeatPassword, setRepeatPassword] = useState(null);
    const [repeatPasswordError, setRepeatPasswordError] = useState('');
    const [repeatPasswordIsVisible, setRepeatPasswordIsVisible] = useState(false);

    const [isDisabled, setIsDisabled] = useState(true);

    const [userRole, setUserRole] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // Modal.
    const [modalStatus, setModalStatus] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [modalMessage, setModalMessage] = useState('');

    // Disclosures.
    const responseModalDisclosure = useDisclosure();


    // Check session and fetch data.
    useEffect(() => {
        /**
         * Check user session. The function is declared as async to use the await keyword.
         *
         * @returns {Promise<void>}
         */
        const checkSession = async () => {
            try {
                const checkSessionResponse = await userService.checkSession();

                if (!checkSessionResponse.success) {
                    throw checkSessionResponse.errors;
                }

                const userSession = JSON.parse(atob(checkSessionResponse.data));

                setUserRole(userSession.role);
            } catch (error) {
                console.error(error);
            }
        };

        checkSession();
    }, []);


    // Handle form validation.
    useEffect(() => {
        // Set error messages.
        const nameError = userService.validateNotEmpty(name, 'Este campo es requerido.');
        const emailError = userService.validateEmail(email);
        const passwordError = userService.validatePassword(password);
        const repeatPasswordError = userService.validatePasswordConfirmation(password, repeatPassword);

        setNameError(nameError);
        setEmailError(emailError);
        setPasswordError(passwordError);
        setRepeatPasswordError(repeatPasswordError);

        // Check if the submit button should be enabled.
        if (name !== null && email !== null && password !== null && repeatPassword !== null && nameError === null && emailError === null && passwordError === null && repeatPasswordError === null) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [name, lastName, email, password, repeatPassword]);


    // Handle form validation.
    useEffect(() => {
        if (nameError !== null || emailError !== null || passwordError !== null || repeatPasswordError !== null) {
            setIsDisabled(true);
        }
    }, [nameError, emailError, passwordError, repeatPasswordError]);


    /**
     * Handle the register request.
     *
     * @returns {void}
     */
    const handleRegister = async () => {
        // Change the loading state.
        setIsDisabled(true);
        setIsLoading(true);

        // Login request.
        try {
            const response = await userService.register(name, lastName, email, password, repeatPassword);

            if (!response.success) {
                throw response.errors;
            }

            // Show modal.
            setModalStatus('success');
            setModalTitle('Registro exitoso');
            setModalMessage(['Se ha creado tu cuenta en PataTag.', 'Recibirás un correo de confirmación para activar tu cuenta.']);

            responseModalDisclosure.onOpen();

            setIsLoading(false);
        } catch (errors) {
            // Change states.
            setIsLoading(false);

            // Set error messages.
            setNameError(errors.name ? errors.name : null);
            setEmailError(errors.email ? errors.email : null);
            setPasswordError(errors.password ? errors.password : null);
            setRepeatPasswordError(errors.repeatPassword ? errors.repeatPassword : null);
        }
    };



    return (
        <div id='registerPage' className='page'>
            <PageContent withBackground isAwaiting={false} userRole={userRole}>
                <form className='flex flex-col justify-center items-center'>
                    <h1 className='main-title mb-4'>Crear Cuenta</h1>

                    <Input
                        type='text'
                        label='Nombre'
                        variant='underlined'
                        defaultValue=''
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        onBlur={(e) => {
                            setName(e.target.value);
                            setNameError(userService.validateNotEmpty(name, 'Este campo es requerido.'));
                        }}
                        isInvalid={nameError !== null}
                        errorMessage={nameError}
                        className='mb-3'
                    />

                    <Input
                        type='text'
                        label='Apellido'
                        variant='underlined'
                        defaultValue=''
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        className='mb-3'
                    />

                    <Input
                        type='email'
                        label='Mail'
                        variant='underlined'
                        defaultValue=''
                        onChange={(e) => setEmail(e.target.value)}
                        onBlur={(e) => {
                            setEmail(e.target.value);
                            setEmailError(userService.validateEmail(email));
                        }}
                        isInvalid={emailError !== null}
                        errorMessage={emailError}
                        className='mb-3'
                    />

                    <Input
                        type={passwordIsVisible ? 'text' : 'password'}
                        label='Contraseña'
                        variant='underlined'
                        defaultValue=''
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onBlur={(e) => {
                            setPassword(e.target.value);
                            setPasswordError(userService.validatePassword(password));
                        }}
                        isInvalid={passwordError !== null}
                        errorMessage={passwordError}                
                        endContent={
                            <button className='focus:outline-none' type='button' onClick={() => setPasswordIsVisible(!passwordIsVisible)}>
                                {passwordIsVisible ? (
                                    <EyeSlash className='text-2xl text-default-400 pointer-events-none w-5 h-5' />
                                ) : (
                                    <Eye className='text-2xl text-default-400 pointer-events-none w-5 h-5' />
                                )}
                            </button>
                        }
                        className='mb-3'
                    />

                    <Input
                        type={repeatPasswordIsVisible ? 'text' : 'password'}
                        label='Repetir contraseña'
                        variant='underlined'
                        defaultValue=''
                        value={repeatPassword}
                        onChange={(e) => setRepeatPassword(e.target.value)}
                        onBlur={(e) => {
                            setRepeatPassword(e.target.value);
                            setRepeatPasswordError(userService.validatePasswordConfirmation(password, repeatPassword));
                        }}
                        isInvalid={repeatPasswordError !== null}
                        errorMessage={repeatPasswordError}                
                        endContent={
                            <button className='focus:outline-none' type='button' onClick={() => setRepeatPasswordIsVisible(!repeatPasswordIsVisible)}>
                                {repeatPasswordIsVisible ? (
                                    <EyeSlash className='text-2xl text-default-400 pointer-events-none w-5 h-5' />
                                ) : (
                                    <Eye className='text-2xl text-default-400 pointer-events-none w-5 h-5' />
                                )}
                            </button>
                        }
                        className='mb-6'
                    />

                    <Button
                        color='primary'
                        className='w-full uppercase shadow-lg mb-2'
                        radius='sm'
                        onClick={handleRegister}
                        isDisabled={isDisabled || isLoading}
                        isLoading={isLoading}
                        style={{ maxWidth: '300px' }}
                    >
                        Crear cuenta
                    </Button>
                </form>
            </PageContent>


            {/* Response modal */}
            <GenericModal
                disclosure={responseModalDisclosure}
                status={modalStatus}
            >
                <ModalHeader className='modal-header flex justify-center items-center text-center relative'>
                    <h3 className='uppercase'>{modalTitle}</h3>
                </ModalHeader>

                <ModalBody>
                    <CheckCircled className='mx-auto' />
                    
                    <div style={{margin: '1rem 1rem 2rem'}}>
                        {modalMessage && modalMessage.map((message, index) => (
                            <p key={index} className='text-center'>{message}</p>
                        ))}
                    </div>
                </ModalBody>

                <ModalFooter className='flex flex-col gap-2 pt-0'>
                    {modalStatus && modalStatus === 'success' && (
                        <Button
                            color='primary'
                            className='w-full uppercase shadow-lg mx-auto'
                            radius='sm'
                            onPress={() => window.location.href = '/login'}
                            style={{ maxWidth: '300px' }}
                        >
                            Iniciar sesión
                        </Button>
                    )}

                    <Button
                        color='default'
                        className='w-full uppercase shadow-lg mt-1 mx-auto'
                        radius='sm'
                        onPress={() => window.location.href = '/'}
                        style={{ maxWidth: '300px' }}
                    >
                        Cerrar
                    </Button>
                </ModalFooter>
            </GenericModal>
        </div>
    );
}
