// Import required modules.
import { React } from 'react';
import axios from 'axios';


function Logout() {
    // Make request to logout.
    const response = axios.get(`${process.env.REACT_APP_ENDPOINT}logout`);

    response.then(() => {
        // Redirect to homepage.
        window.location.href = '/';
    });

    return (
        <div></div>
    );
}

export default Logout;
