// Import required modules.
import { React, useCallback, useMemo, useState } from 'react';
import propTypes from 'prop-types';
import {
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    Input,
    Pagination,
} from '@nextui-org/react';

// Import required styles.
import './AdminTable.css';


const INITIAL_VISIBLE_COLUMNS = ['id', 'tokenQr', 'specie', 'breed', 'petName', 'sex', 'sterilized', 'name', 'email', 'phone', 'country', 'city', 'address', 'token', 'status', 'actions'];


export default function AdminTable({ columns, filtereableColumns, data, renderCell, type }) {
    const visibleColumns = new Set(INITIAL_VISIBLE_COLUMNS);
    const [filterValue, setFilterValue] = useState('');
    const [selectedKeys, setSelectedKeys] = useState(new Set([]));
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortDescriptor, setSortDescriptor] = useState({
        column: 'age',
        direction: 'ascending',
    });
    const [page, setPage] = useState(1);

    const pages = Math.ceil(data.length / rowsPerPage);

    const hasSearchFilter = Boolean(filterValue);


    const headerColumns = useMemo(() => {
        if (visibleColumns === 'all') return columns;

        return columns.filter((column) => Array.from(visibleColumns).includes(column.uid));
    }, [visibleColumns]);


    const filteredItems = useMemo(() => {
        let filteredData = [...data];

        if (hasSearchFilter) {
            filteredData = filteredData.filter((item) => {
                return filtereableColumns.some((column) => {
                    const value = item[column.uid];

                    if (typeof value === 'string') {
                        return value.toLowerCase().includes(filterValue.toLowerCase());
                    }

                    return false;
                });
            });
        }

        return filteredData;
    }, [data, filterValue]);


    const items = useMemo(() => {
        const start = (page - 1) * rowsPerPage;
        const end = start + rowsPerPage;

        return filteredItems.slice(start, end);
    }, [page, filteredItems, rowsPerPage]);


    const sortedItems = useMemo(() => {
        return [...items].sort((a, b) => {
            const first = a[sortDescriptor.column];
            const second = b[sortDescriptor.column];
            const cmp = first < second ? -1 : first > second ? 1 : 0;

            return sortDescriptor.direction === 'descending' ? -cmp : cmp;
        });
    }, [sortDescriptor, items]);


    const onRowsPerPageChange = useCallback((e) => {
        setRowsPerPage(Number(e.target.value));
        setPage(1);
    }, []);


    const onSearchChange = useCallback((value) => {
        if (value) {
            setFilterValue(value);
            setPage(1);
        } else {
            setFilterValue('');
        }
    }, []);


    const topContent = useMemo(() => {
        return (
            <div className='flex flex-col gap-4'>
                <div className='table-top flex justify-between gap-3'>
                    <span className='text-default-400 text-small'>Cantidad total de {type}: {data.length}</span>

                    <Input
                        isClearable
                        className='search-input'
                        classNames={{
                            base: 'w-full sm:max-w-[44%]',
                            inputWrapper: 'border-1 bg-white px-2 shadow-md',
                        }}
                        placeholder='Buscar..'
                        size='sm'
                        value={filterValue}
                        variant='bordered'
                        onClear={() => setFilterValue('')}
                        onValueChange={onSearchChange}
                    />
                </div>
            </div>
        );
    }, [filterValue, visibleColumns, onSearchChange, onRowsPerPageChange, data.length, hasSearchFilter]);


    const bottomContent = useMemo(() => {
        return (
            <div className='table-bottom flex items-center mt-3'>
                <div className='flex justify-between items-center'>
                    <label className='flex items-center text-default-400 text-small'>
                        Filas por página: &nbsp;
                        <select
                            className='bg-transparent outline-none text-default-400 text-small'
                            onChange={onRowsPerPageChange}
                        >
                            <option value='5'>5</option>
                            <option value='10' selected>10</option>
                            <option value='15'>15</option>
                        </select>
                    </label>
                </div>

                <Pagination
                    showControls
                    classNames={{
                        cursor: 'bg-foreground text-background',
                    }}
                    color='default'
                    isDisabled={hasSearchFilter}
                    page={page}
                    total={pages}
                    variant='light'
                    onChange={setPage}
                />
            </div>
        );
    }, [page, pages, hasSearchFilter]);


    const classNames = useMemo(
        () => ({
            wrapper: 'wrapper p-3 my-3',
            table: 'w-full',
            th: ['bg-transparent', 'text-default-500', 'border-b', 'border-divider'],
            td: [
                // changing the rows border radius
                // first
                'group-data-[first=true]:first:before:rounded-none',
                'group-data-[first=true]:last:before:rounded-none',
                // middle
                'group-data-[middle=true]:before:rounded-none',
                // last
                'group-data-[last=true]:first:before:rounded-none',
                'group-data-[last=true]:last:before:rounded-none',
            ],
        }),
        [],
    );

    return (
        <Table
            isCompact
            aria-label='Tabla del listado de códigos QR'
            bottomContent={bottomContent}
            bottomContentPlacement='outside'
            className='table'
            classNames={classNames}
            selectedKeys={selectedKeys}
            sortDescriptor={sortDescriptor}
            topContent={topContent}
            topContentPlacement='outside'
            onSelectionChange={setSelectedKeys}
            onSortChange={setSortDescriptor}
        >
            <TableHeader columns={headerColumns}>
                {(column) => (
                    <TableColumn
                        key={column.uid}
                        align={column.uid === 'actions' ? 'center' : 'start' }
                        allowsSorting={column.sortable}
                        style={{ 'fontWeight': 'bold' }}
                    >
                        {column.name}
                    </TableColumn>
                )}
            </TableHeader>
            <TableBody emptyContent={'No se encontraron ' + type } items={sortedItems}>
                {(item) => (
                    <TableRow key={item.id}>
                        {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
}

AdminTable.propTypes = {
    columns: propTypes.array.isRequired,
    filtereableColumns: propTypes.array.isRequired,
    data: propTypes.array.isRequired,
    renderCell: propTypes.func.isRequired,
    type: propTypes.string.isRequired,
};