// Import modules.
import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@nextui-org/react';

// Import styles.
import './MainContent.css';

// Define properties.
MainContent.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
};


export default function MainContent({ isLoading, children }) {
    return (
        <div id='mainContent' className={`${isLoading ? 'is-loading' : ''} relative`}>
            {
                isLoading ? (
                    <Spinner label='Cargando' color='primary' labelColor='primary' />
                ) : (
                    <div className='container w-full'>{children}</div>
                )
            }
        </div>
    );
}
