import React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
};

FadeInDiv.propTypes = {
    children: PropTypes.node.isRequired,
    viewportAmount: PropTypes.number,
    delay: PropTypes.number,
};

export default function FadeInDiv({ children, viewportAmount = 0.8, delay = 0, ...props }) {
    return (
        <motion.div
            initial='hidden'
            transition={{ duration: 0.5, ease: 'easeOut', delay }}
            variants={variants}
            viewport={{ once: true, viewportAmount }}
            whileInView='visible'
            {...props}
        >
            {children}
        </motion.div>
    );
}