import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Accordion, AccordionItem, Button, useDisclosure } from '@nextui-org/react';
import petService from '../../services/pets.js';
import userService from '../../services/users.js';

import PageContent from '../../components/PageContent/PageContent.jsx';
import ResponseModal from './components/ResponseModal.jsx';

import Chat from '../../components/Icons/Chat.jsx';
import ChevronRight from '../../components/Icons/ChevronRight.jsx';
import Medical from '../../components/Icons/Medical.jsx';
import PawFilled from '../../components/Icons/PawFilled.jsx';
import MedicalRecordModal from './components/MedicalRecordModal.jsx';

import petNoPicture from '../../assets/images/pet-no-picture.png';
import './Profile.css';

const whatsAppURL = 'https://wa.me/';
const whatsAppMessage = 'Hola, Encontré a ';

const responses = {
    qrInactive: {
        title: 'QR inactivo',
        text: [
            'Este QR se encuentra inactivo, por lo tanto no se puede usar',
            'Si crees que esto es un error, por favor contactase con nosotros.',
        ],
    },
    qrNotFound: {
        title: 'QR no encontrado',
        text: [
            'Este QR no se encuentra registrado en nuestra base de datos',
            'Si crees que esto es un error, por favor contactase con nosotros.',
        ],
    },
    petNotFound: {
        title: 'Mascota no encontrada',
        text: [
            'La mascota asociada a este QR no se encuentra registrada en nuestra base de datos',
            'Si crees que esto es un error, por favor contactase con nosotros.',
        ],
    },
    ownerNotFound: {
        title: 'Dueño no encontrado',
        text: [
            'El dueño de la mascota asociada a este QR no se encuentra registrado en nuestra base de datos',
            'Si crees que esto es un error, por favor contactase con nosotros.',
        ],
    },
    subscriptionNotFound: {
        title: 'Suscripción no encontrada',
        text: [
            'La suscripción de la mascota asociada a este QR no se encuentra registrada en nuestra base de datos',
            'Si crees que esto es un error, por favor contactase con nosotros.',
        ],
    },
    notificationSent: {
        title: 'Notificación enviada',
        text: [
            'Se ha enviado una notificación al dueño de la mascota',
            'Gracias por ayudar a que las mascotas vuelvan a casa.',
        ],
    },
};


export default function Profile() {
    // Declare states.
    const [pet, setPet] = useState(null);
    const [medicalRecords, setMedicalRecords] = useState(null);
    const [user, setUser] = useState(null);
    const [notificationSent, setNotificationSent] = useState(false);

    // Utilities.
    const [userRole, setUserRole] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // Modal.
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState('');

    // Disclosures.
    const responseModal = useDisclosure();
    const medicalRecordModal = useDisclosure();

    // Get the QR token from the URL.
    const { token } = useParams();
    const isValidId = /^[a-zA-Z0-9]{8}$/.test(token);

    if (!isValidId) {
        window.location.href = '/panel-usuario';
    }


    // Check user session.
    useEffect(() => {
        /**
         * Check user session. The function is declared as async to use the await keyword.
         *
         * @returns {Promise<void>}
         */
        const checkSession = async () => {
            setIsLoading(true);

            try {
                const checkSessionResponse = await userService.checkSession();

                if (!checkSessionResponse.success) {
                    throw checkSessionResponse.errors;
                }

                const userSession = JSON.parse(atob(checkSessionResponse.data));

                setUserRole(userSession.role);
            } catch (error) {
                setIsLoading(false);
            }
        };

        checkSession();
        fetchPetData();
    }, []);


    /**
     * Fetch the pet by its token.
     *
     * @returns {void}
     */
    const fetchPetData = async () => {
        try {
            setIsLoading(true);
    
            const response = await petService.getByToken(token);
    
            if (!response.success) {
                throw response.error_code;
            }
    
            // Send notification to the owner.
            sendNotificationToOwner();
    
            // Calculate the age of the pet.
            const age = response.data.pet.birthday !== null ? Math.floor((new Date() - new Date(response.data.pet.birthday)) / (1000 * 60 * 60 * 24 * 365)) : null;
    
            response.data.pet.age = age;
    
            setPet(response.data.pet);
            setMedicalRecords(response.data.medicalRecords);
            setUser(response.data.user);
    
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);

            if (error) {
                setModalTitle(responses[error].title);
                setModalContent(responses[error].text);

                responseModal.onOpen();
            }
        }
    };


    /**
     * Get the user coordinates and send a notification to the owner.
     *
     * @returns {void}
     */
    const sendNotificationToOwner = () => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(async (position) => {
                if (!notificationSent) {
                    const response = await userService.sendNotificationToOwner(token, position.coords.latitude, position.coords.longitude);

                    if (!response.success) {
                        setModalTitle(responses[response.error].title);
                        setModalContent(responses.notificationSent.text);

                        responseModal.onOpen();
                    }

                    setNotificationSent(true);
                }
            });
        }
    };


    return (
        <div id='profilePage' className='page'>
            <PageContent withBackground isAwaiting={isLoading} userRole={userRole}>
                <div className='flex flex-col container'>
                    {pet && (
                        <>
                            <div className='pet-card flex flex-col p-2 mb-2 shadow-lg relative'>
                                <div id='photoContainer'>
                                    {pet.photo !== '' && (
                                        <img src={`${process.env.REACT_APP_ENDPOINT}img/pets/profile/${pet.photo}`} alt={pet.name} className='rounded-full'/>
                                    ) || (
                                        <img src={petNoPicture} alt='pet-profile-picture' className='rounded-full'/>
                                    )}
                                </div>

                                <div className='content'>
                                    <div className='p-2'>
                                        <h2 className='text-slate-50 text-center font-bold mb-2'>Hola! Soy <span className='color-dark-light'>{pet.name}</span></h2>
                                        <p className='text-slate-50 text-center mb-2'>Por favor, si estoy perdido contactá a <span className='color-dark-light font-bold'>{user.name}.</span> Su teléfono es <span className='color-dark-light font-bold'>{user.phone}.</span></p>
                                        <p className='text-center font-bold mb-2'>Te cuento sobre mí:</p>

                                        <div className='pet-info flex flex-col text-slate-50'>
                                            {pet.specie && (
                                                <div className='flex gap-2'>
                                                    <PawFilled className='paw-filled fill-light w-4 h-4' style={{ marginTop: '2px' }} />

                                                    <div className='flex flex-col'>
                                                        <p className='font-bold'>Especie</p>
                                                        <p>{pet.specie.charAt(0).toUpperCase() + pet.specie.slice(1)}</p>
                                                    </div>
                                                </div>
                                            )}

                                            {pet.sex && (
                                                <div className='flex gap-2'>
                                                    <PawFilled className='paw-filled fill-light w-4 h-4' style={{ marginTop: '2px' }} />

                                                    <div className='flex flex-col'>
                                                        <p className='font-bold'>Sexo</p>
                                                        <p>{pet.sex.charAt(0).toUpperCase() + pet.sex.slice(1)}</p>
                                                    </div>
                                                </div>
                                            )}

                                            {pet.breed && (
                                                <div className='flex gap-2'>
                                                    <PawFilled className='paw-filled fill-light w-4 h-4' style={{ marginTop: '2px' }} />

                                                    <div className='flex flex-col'>
                                                        <p className='font-bold'>Raza</p>
                                                        <p>{pet.breed.charAt(0).toUpperCase() + pet.breed.slice(1)}</p>
                                                    </div>
                                                </div>
                                            )}

                                            {pet.birthday && (
                                                <div className='flex gap-2'>
                                                    <PawFilled className='paw-filled fill-light w-4 h-4' style={{ marginTop: '2px' }} />

                                                    <div className='flex flex-col'>
                                                        <p className='font-bold'>Fecha de nacimiento</p>
                                                        <p>{pet.birthday}</p>
                                                    </div>
                                                </div>
                                            )}

                                            {pet.microchip && 
                                                <div className='flex gap-2'>
                                                    <PawFilled className='paw-filled fill-light w-4 h-4' style={{ marginTop: '2px' }} />

                                                    <div className='flex flex-col'>
                                                        <p className='font-bold'>Microchip</p>
                                                        <p>{pet.microchip}</p>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        {pet.clarification && (
                                            <Accordion
                                                classNames={{
                                                    base: 'px-0'
                                                }}
                                            >
                                                <AccordionItem
                                                    title='Más información'
                                                    indicator={({ isOpen }) => 
                                                        <ChevronRight className='w-4 h-4' style={{ transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)' }} />
                                                    }
                                                    classNames={{
                                                        trigger: 'flex justify-center items-center',
                                                        titleWrapper: 'flex-initial',
                                                        title: 'text-base font-bold',
                                                        indicator: 'rotate-90'
                                                    }}
                                                >
                                                    <div className='pet-info flex flex-col text-slate-50'>
                                                        {pet.clarification}
                                                    </div>
                                                </AccordionItem>
                                            </Accordion>
                                        )}
                                    </div>
                                </div>
                                

                                {user.phone && (
                                    <Button
                                        color='dark'
                                        className='w-full bg-dark-light color-light uppercase shadow-lg mx-auto mb-1 z-10'
                                        radius='sm'
                                        variant='solid'
                                        isLoading={isLoading}
                                        style={{ maxWidth: '300px' }}
                                        onPress={(() => window.location.href = `${whatsAppURL}${user.phone}?text=${whatsAppMessage}${pet.name}`)}
                                    >
                                        <Chat />

                                        Enviar WhatsApp
                                    </Button>
                                )}

                                {medicalRecords.length > 0 && (
                                    <Button
                                        color='dark'
                                        className='w-full bg-dark-light color-light uppercase shadow-lg mx-auto mb-2 z-10'
                                        radius='sm'
                                        variant='solid'
                                        onPress={medicalRecordModal.onOpen}
                                        isLoading={isLoading}
                                        style={{ maxWidth: '300px' }}
                                    >
                                        <Medical className='w-5 h-5 fill-light' />

                                        Ver Ficha Médica
                                    </Button>
                                )}
                                    
                            </div>
                        </>
                    ) || (
                        <>
                            <div className='pet-not-found flex flex-col color-light bg-primary-light p-2 mb-2 shadow-lg relative'>
                                <h2 className='text-center fw-500 mt-0'>Mascota no encontrada</h2>

                                <p className='text-center mb-1'>Para proteger a tu mascota seguí los siguientes pasos:</p>

                                <div className='steps py-2 px-4 mb-1'>
                                    <p><span className='mr-2'>1.</span>Creá tu cuenta en <a href='www.patatag.com' className='fw-500'>www.patatag.com</a></p>
                                    <p><span className='mr-2'>2.</span>Creá el perfil de tu mascota</p>
                                    <p><span className='mr-2'>3.</span>Vinculá el Tag a su perfil</p>
                                    <p><span className='mr-2'>4.</span>Activá su Tag</p>
                                </div>

                                <p className='text-center'>Más información en nuestro Instagram <a href='https://www.instagram.com/patatag.ok' target='_blank' className='fw-500' rel='noreferrer'>@patatag.ok</a></p>
                            </div>

                            <div className='flex flex-col items-center'>
                                <Button
                                    color='primary'
                                    radius='sm'
                                    shadow='lg'
                                    className='w-full btn-dark uppercase shadow-lg mb-2 z-10'
                                    onPress={() => window.location.href = '/login'}
                                    style={{ maxWidth: '300px' }}
                                >
                                    Iniciar sesión
                                </Button>

                                <Button
                                    color='dark'
                                    radius='sm'
                                    shadow='lg'
                                    className='w-full btn-dark uppercase shadow-lg mb-2 z-10'
                                    onPress={() => window.location.href = '/registro'}
                                    style={{ maxWidth: '300px' }}
                                >
                                    Crear una cuenta
                                </Button>
                            </div>
                        </>
                    )}
                </div>

                <ResponseModal disclosure={responseModal} title={modalTitle} content={modalContent} />
                <MedicalRecordModal disclosure={medicalRecordModal} medicalRecords={medicalRecords} />
            </PageContent>
        </div>
    );
}
