// Import modules.
import React, { useState, useEffect } from 'react';
import { Button, Input, ModalBody, ModalFooter, ModalHeader, useDisclosure } from '@nextui-org/react';
import accountService from '../../services/account.js';
import inputService from '../../services/inputs.js';
import userService from '../../services/users.js';

// Import components.
import PageContent from '../../components/PageContent/PageContent.jsx';
import GenericModal from '../../components/Modal/Modal.jsx';

// Import styles.
import './RecoverPassword.css';

export default function RecoverPassword() {
    // Declare states.
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(null);
    const [emailSent, setEmailSent] = useState(false);

    const [modalStatus, setModalStatus] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [modalMessage, setModalMessage] = useState('');

    // Utilities.
    const [userRole, setUserRole] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // Disclosures.
    const responseModalDisclosure = useDisclosure();


    // Check session and fetch data.
    useEffect(() => {
        /**
         * Check user session. The function is declared as async to use the await keyword.
         *
         * @returns {Promise<void>}
         */
        const checkSession = async () => {
            try {
                const response = await userService.checkSession();

                if (!response.success) {
                    throw response.errors;
                }

                const userSession = JSON.parse(atob(response.data));

                setUserRole(userSession.role);
            } catch (error) {
                console.error('Error checking session:', error);
            }
        };

        checkSession();
    }, []);


    /**
     * Handle the recover password request.
     *
     * @returns {Promise<void>}
     */
    const handleRecoverPassword = async () => {
        setIsLoading(true);

        try {
            const response = await accountService.recoverPassword(email);

            if (!response.success) {
                throw response.errors;
            }

            // Show modal.
            setEmailSent(true);
            setModalStatus('success');
            setModalTitle('Siguiente paso');
            setModalMessage('Si el email ingresado es válido, se le enviará un correo con las instrucciones para recuperar su contraseña.');

            responseModalDisclosure.onOpen();

            setIsLoading(false);
        } catch (error) {
            console.error('Error recovering password:', error);
            
            // Show modal.
            setModalStatus('error');
            setModalTitle('Error');
            setModalMessage('Ha ocurrido un error al enviar el correo. Por favor, intenta de nuevo.');

            responseModalDisclosure.onOpen();

            setIsLoading(false);
        }
    };


    return (
        <div id='recoverPasswordPage' className='page'>
            <PageContent withBackground isAwaiting={false} userRole={userRole}>
                <form className='flex flex-col justify-center items-center z-10'>
                    <h1 className='main-title mb-4 z-10'>Recuperar Contraseña</h1>

                    <Input
                        type='text'
                        label='Email'
                        variant='underlined'
                        defaultValue=''
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onBlur={(e) => {
                            setEmail(e.target.value);
                            setEmailError(inputService.validateEmail(email));
                        }}
                        isInvalid={emailError !== null}
                        errorMessage={emailError}
                        isDisabled={isLoading || emailSent}
                        className='mb-4'
                        style={{ maxWidth: '300px' }}
                    />

                    <Button
                        color='primary'
                        radius='sm'
                        className='w-full uppercase shadow-lg mt-2 z-10'
                        onClick={handleRecoverPassword}
                        isDisabled={email === '' || emailError !== null || emailSent}
                        isLoading={isLoading}
                        style={{ maxWidth: '300px' }}
                    >
                        Enviar correo
                    </Button>
                </form>

                {/* Response modal */}
                <GenericModal
                    disclosure={responseModalDisclosure}
                    status={modalStatus}
                >
                    <ModalHeader className='modal-header flex justify-center items-center text-center relative'>
                        <h3 className='uppercase'>{modalTitle}</h3>
                    </ModalHeader>

                    <ModalBody>
                        <p className='text-center' style={{margin: '1rem 1rem 2rem'}}>{modalMessage}</p>
                    </ModalBody>

                    <ModalFooter className='flex flex-col gap-2 pt-0'>
                        {emailSent && (
                            <Button
                                color='primary'
                                className='w-full uppercase shadow-lg mx-auto'
                                radius='sm'
                                onPress={() => window.location.href = '/login'}
                                style={{ maxWidth: '300px' }}
                            >
                                Iniciar sesión
                            </Button>
                        )}

                        <Button
                            color='default'
                            className='w-full uppercase shadow-lg mt-1 mx-auto'
                            radius='sm'
                            onPress={() => responseModalDisclosure.onClose()}
                            style={{ maxWidth: '300px' }}
                        >
                            Cerrar
                        </Button>
                    </ModalFooter>
                </GenericModal>
            </PageContent>
        </div>
    );
}
