// Import modules.
import React from 'react';
import PropTypes from 'prop-types';

// Define properties.
MapPoint.propTypes = {
    className: PropTypes.string,
};


export default function MapPoint({ className }) {
    return (
        <svg className={'map-point ' + className} width='114' height='100' viewBox='0 0 114 100' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M9.40004 96.8L36.5 87.4C38.1 86.8 39.2001 85.5 39.2001 84V32.9C39.2001 30.3 36.0001 28.5 33.1001 29.5L6.00005 38.9C4.40005 39.5 3.30005 40.8 3.30005 42.3V93.4C3.40005 96.1 6.50004 97.8 9.40004 96.8Z' stroke='#1D1E1E' strokeWidth='5.6133' strokeMiterlimit='10'/>
            <path d='M81.1 96.8L108.2 87.4C109.8 86.8 110.9 85.5 110.9 84V32.9C110.9 30.3 107.7 28.5 104.8 29.5L77.7 38.9C76.1 39.5 75 40.8 75 42.3V93.4C75 96.1 78.2 97.8 81.1 96.8Z' stroke='#1D1E1E' strokeWidth='5.6133' strokeMiterlimit='10'/>
            <path d='M68.9999 96.8L41.8999 87.4C40.2999 86.8 39.2 85.5 39.2 84V32.9C39.2 30.3 42.3999 28.5 45.2999 29.5L72.3999 38.9C73.9999 39.5 75.0999 40.8 75.0999 42.3V93.4C74.9999 96.1 71.8999 97.8 68.9999 96.8Z' stroke='#1D1E1E' strokeWidth='5.6133' strokeMiterlimit='10'/>
            <path d='M97.5 26.9C97.5 28.3 96.7 30.9 95.7 33.5C93.7 38.2 91.1 42.6 88.1 46.6C84.8 51.1 81.2 55.4 77.7 59.7C76.2 61.5 74.1 61.6001 72.5 59.8001C66.8 53.2001 61.4 46.4001 57.1 38.8001C55.4 35.7001 53.8 32.5 53 29C52 24.7 52.7 20.5 54.5 16.6C57.6 9.79998 62.8 5.60005 70.1 3.80005C79 1.60005 89.1 5.79998 93.9 13.6C96.3 17.3 97.5 21.2 97.5 26.9ZM65.5 25.8001C65.5 31.1001 69.9 35.5 75.2 35.4C80.5 35.3 84.7 31.0001 84.8 25.8001C84.8 20.6001 80.4 16.2 75.2 16.2C69.8 16.2 65.5 20.6001 65.5 25.8001Z' fill='white' stroke='#1D1E1E' strokeWidth='5.1405' strokeMiterlimit='10'/>
        </svg>
    );
}
