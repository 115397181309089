// Import modules.
import axios from 'axios';


/**
 * Make a request to login with Google.
 *
 * @param {string} token - The Google token.
 *
 * @return {object} - The response data.
 */
export const loginGoogle = async (token) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}google/login`, {
            credential: token,
        });

        if (response.data.success) {
            localStorage.setItem('token', response.data.data.token);
        }

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};


/**
 * Request to recover the password. Send an email to the user with a link to change the password.
 *
 * @param {string} email - The user email.
 *
 * @return {object} - The response data.
 */
export const recoverPassword = async (email) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_ENDPOINT}password/recover`, {
            email,
        });

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};


/**
 * Request to change the password.
 *
 * @param {string} token - The token to change the password.
 * @param {string} password - The new password.
 * @param {string} passwordConfirmation - The new password confirmation.
 *
 * @return {object} - The response data.
 */
export const changePassword = async (token, password, passwordConfirmation) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_ENDPOINT}password/change`, {
            token,
            password,
            password_confirmation: passwordConfirmation,
        });

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};

export default {
    loginGoogle,
    recoverPassword,
    changePassword
};