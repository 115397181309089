// Import modules.
import React from 'react';
// import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import userService from '../../services/users.js';

// Import components.
import Navbar from '../Navbar/Navbar.jsx';
import MainContent from '../MainContent/MainContent.jsx';
import Footer from '../Footer/Footer';
import PawRotatedBoldIcon from '../../components/Icons/PawRotatedBoldIcon.jsx';

// Import styles.
import './PageContent.css';

// Define properties.
PageContent.propTypes = {
    withBackground: PropTypes.bool,
    isAwaiting: PropTypes.bool,
    userRole: PropTypes.string,
    children: PropTypes.node.isRequired,
};


// const pagesWithoutRedirect = ['/', '/login'];


export default function PageContent({ withBackground = false, isAwaiting = false, userRole = null, children }) {
    // Declare states.
    // const [isLoading, setIsLoading] = useState(false || isAwaiting);
    // const [role, setRole] = useState(null);
    const isLoading = false || isAwaiting;

    // useEffect(() => {
    //     /**
    //      * Check user session. The function is declared as async to use the await keyword.
    //      *
    //      * @returns {Promise<void>}
    //      */
    //     const checkSession = async () => {
    //         try {
    //             const response = await userService.checkSession();

    //             if (!response.success) {
    //                 throw new Error(response.message);
    //             }

    //             setRole(response.data.role);

    //             setIsLoading(false);
    //         } catch (error) {
    //             // Remove user session.
    //             localStorage.removeItem('token');

    //             if (!pagesWithoutRedirect.includes(window.location.pathname)) {
    //                 window.location.href = '/login';
    //             }

    //             setIsLoading(false);
    //         }
    //     };

    //     checkSession();
    // }, []);

    return (
        <>
            <Navbar role={userRole} />

            <MainContent isLoading={isLoading || isAwaiting}>
                <div className='main-content-childrens relative z-10'>
                    {children}
                </div>

                {withBackground && (
                    <>
                        <PawRotatedBoldIcon className='paw-left absolute mb-4' />
                        <PawRotatedBoldIcon className='paw-right absolute mb-4' />
                    </>
                )}
            </MainContent>

            <Footer />
        </>
    );
}
