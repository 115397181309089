import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, DropdownSection, useDisclosure } from '@nextui-org/react'; 

import LinkTagModal from '../../pages/User/components/LinkTagModal.jsx';
import ViewTagModal from '../../pages/User/components/ViewTagModal.jsx';
import DeletePetModal from '../../pages/User/components/DeletePetModal.jsx';

import Chat from '../Icons/Chat';
import CreditCard from '../Icons/CreditCard';
import HandScanQR from '../Icons/HandScanQR';
import Medical from '../Icons/Medical';
import Pencil from '../Icons/Pencil';
import QRCodeIcon from '../Icons/QRCode.jsx';
import Trash from '../Icons/Trash.jsx';

import petNoPicture from '../../assets/images/pet-no-picture.png';
import './PetCard.css';


PetCard.propTypes = {
    pet: PropTypes.object.isRequired,
};

const whatsAppURL = 'https://wa.me/5493416411900';
const whatsAppMessage = 'Hola, necesito ayuda con mi mascota.';


export default function PetCard({ pet }) {
    // Disclosures.
    const viewTagModal = useDisclosure();
    const linkTagModal = useDisclosure();
    const deletePetModal = useDisclosure();

    let isProtected = '';

    if (pet.status === 'active') {
        if (pet.sex === 'macho') {
            isProtected = 'Está protegido';
        } else {
            isProtected = 'Está protegida';
        }
    } else {
        if (pet.sex === 'macho') {
            isProtected = 'No está protegido';
        } else {
            isProtected = 'No está protegida';
        }
    }

    return (
        <div className='pet-card flex justify-between items-center shadow-lg p-2' onClick={() => window.location.href = `/panel-usuario/mascota/${pet.id}/perfil`}>
            <div id='photoContainer'>
                {pet.photo !== '' && (
                    <img src={`${process.env.REACT_APP_ENDPOINT}img/pets/profile/${pet.photo}`} alt={pet.name} className='rounded-full'/>
                ) || (
                    <img src={petNoPicture} alt='pet-profile-picture' className='rounded-full'/>
                )}
            </div>


            <div className='flex flex-col w-full p-2 mt-3'>
                <div className='flex flex-col items-center mb-1'>
                    <h2 className='name'>{pet.name}</h2>
                    <p className='color-light'>{isProtected}</p>
                </div>

                <Dropdown
                    classNames={{
                        trigger: 'trigger bg-dark-light text-white uppercase shadow-sm',
                        base: 'pet-dropdown',
                        content: 'content text-white shadow-sm p-0',
                        heading: 'text-white',
                        separator: 'bg-light'
                    }}
                >
                    <DropdownTrigger>
                        <Button
                            radius='sm'
                        >
                            Acciones
                        </Button>
                    </DropdownTrigger>

                    <DropdownMenu variant='faded' aria-label='Dropdown menu with description'>
                        <DropdownSection title='Tag' showDivider>
                            {pet && pet.token && (
                                <DropdownItem
                                    key='viewTag'
                                    startContent={<QRCodeIcon className='icon' />}
                                    onPress={viewTagModal.onOpen}
                                >
                                    Ver Tag
                                </DropdownItem>
                            )}

                            {pet && !pet.token && (
                                <DropdownItem
                                    key='linkTag'
                                    startContent={<HandScanQR className='icon' />}
                                    onPress={linkTagModal.onOpen}
                                >
                                    Vincular Tag
                                </DropdownItem>
                            )}

                            {pet && pet.token && pet.subscription === null && pet.subscription < new Date() && (
                                <DropdownItem
                                    key='payTag'
                                    startContent={<CreditCard className='icon' />}
                                    onPress={() => window.location.href = `/panel-usuario/activar-qr/${pet.token}`}
                                >
                                    Activar Tag
                                </DropdownItem>
                            )}
                        </DropdownSection>

                        <DropdownSection title='Mascota'>
                            <DropdownItem
                                key='editPet'
                                startContent={<Pencil className='icon' />}
                                onPress={() => window.location.href = `/panel-usuario/mascota/${pet.id}`}
                            >
                                Editar Mascota
                            </DropdownItem>

                            <DropdownItem
                                key='medicalRecord'
                                startContent={<Medical className='icon fill-light' style={{ width: '18px', height: '18px' }} />}
                                onPress={() => window.location.href = `/panel-usuario/mascota/${pet.id}/ficha-medica`}
                            >
                                Ficha Médica
                            </DropdownItem>

                            <DropdownItem
                                key='support'
                                className='text-success'
                                startContent={<Chat className='icon fill-success' />}
                                onPress={() => window.open(`${whatsAppURL}?text=${whatsAppMessage}`, '_blank')}
                            >
                                Soporte
                            </DropdownItem>

                            <DropdownItem
                                key='deletePet'
                                className='text-danger'
                                color='danger'
                                startContent={<Trash className='icon' />}
                                onPress={deletePetModal.onOpen}
                            >
                                Eliminar Mascota
                            </DropdownItem>
                        </DropdownSection>
                    </DropdownMenu>
                </Dropdown>
            </div>

            <ViewTagModal disclosure={viewTagModal} token={pet.token} />
            <LinkTagModal disclosure={linkTagModal} petId={pet.id} />
            <DeletePetModal disclosure={deletePetModal} petId={pet.id} />
        </div>
    );
}
