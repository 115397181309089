// Import modules.
import React from 'react';
import PropTypes from 'prop-types';

// Define properties.
StarFilled.propTypes = {
    className: PropTypes.string,
};


export default function StarFilled({ className }) {
    return (
        <svg className={'star-filled ' + className} width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M11.3001 0.800049L14.8001 7.80005L22.6001 8.8999L16.9001 14.3999L18.3001 22.1001L11.3001 18.5L4.40009 22.1001L5.7001 14.3999L0.100098 8.8999L7.90009 7.80005L11.3001 0.800049Z' fill='#D6D5D5'/>
        </svg>        
    );
}
