// Import modules.
import React, { useState, useEffect } from 'react';
import { Autocomplete, AutocompleteItem, Button, Input } from '@nextui-org/react';
import inputService from '../../services/inputs.js';
import userService from '../../services/users.js';
import { countries } from '../../utils/helpers.js';

// Import components.
import PageContent from '../../components/PageContent/PageContent.jsx';

// Import styles.
import './Panel.css';


export default function Edit() {
    // Declare states.
    const [user, setUser] = useState(null);
    const [userId, setUserId] = useState('');
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(null);
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(null);
    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState(null);
    const [country, setCountry] = useState('');
    const [countryError, setCountryError] = useState(null);
    const [city, setCity] = useState('');
    const [cityError, setCityError] = useState(null);
    const [address, setAddress] = useState('');
    const [sameForm, setSameForm] = useState(true);

    // Utilities.
    const [userRole, setUserRole] = useState(null);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(true);


    // Check session and fetch data.
    useEffect(() => {
        /**
         * Check user session. The function is declared as async to use the await keyword.
         *
         * @returns {Promise<void>}
         */
        const checkSession = async () => {
            setIsPageLoading(true);

            try {
                const checkSessionResponse = await userService.checkSession();

                if (!checkSessionResponse.success) {
                    throw new Error(checkSessionResponse.message);
                }

                const userSession = JSON.parse(atob(checkSessionResponse.data));

                setUserId(userSession.id);
                setUserRole(userSession.role);

                const response = await userService.getUser(userSession.id);

                setUser(response.user);
                setName(response.user.name);
                setLastName(response.user.last_name);
                setEmail(response.user.email);
                setPhone(response.user.phone);
                setCountry(response.user.country);
                setCity(response.user.city);
                setAddress(response.user.address);
                setIsPageLoading(false);
            } catch (error) {
                setIsPageLoading(false);
            }
        };

        checkSession();
    }, []);


    // Handle form validation.
    useEffect(() => {
        // Set error messages.
        const nameError = inputService.validateNotEmpty(name);
        const emailError = inputService.validateEmail(email);
        const phoneError = inputService.validatePhone(phone);
        const countryError = inputService.validateNotEmpty(country);
        const cityError = inputService.validateNotEmpty(city);

        setNameError(nameError);
        setEmailError(emailError);
        setPhoneError(phoneError);
        setCountryError(countryError);
        setCityError(cityError);

        // Name should not be empty and there should not be any error.
        if (name !== null && email !== null && phone !== null && country !== null && city !== null && nameError === null && emailError === null && phoneError === null && countryError === null && cityError === null) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [name, email, phone, country, city]);
    
    // Handle form validation.
    useEffect(() => {
        // Should not be any error.
        if (nameError !== null || emailError !== null || phoneError !== null || countryError !== null || cityError !== null) {
            setIsDisabled(true);
        }
    }, [nameError, emailError, phoneError, countryError, cityError]);

    // Check if the submit button should be enabled.
    useEffect(() => {
        if (user !== null) {
            if (name !== user.name || lastName !== user.last_name || email !== user.email || phone !== user.phone || country !== user.country || city !== user.city || address !== user.address) {
                setSameForm(false);
            } else {
                setSameForm(true);
            }
        }
    }, [name, lastName, email, phone, country, city, address, user]);
    


    /**
     * Handle the update user request.
     *
     * @return void.
     */
    const handleUpdateUser = async () => {
        // Change the loading state.
        setIsLoading(true);

        // Update user request.
        try {
            const response = await userService.update(userId, name, lastName, email, phone, country, city, address);

            if (!response.success) {
                throw response.errors;
            }

            window.location.href = '/panel-usuario';
        } catch (errors) {
            // Change states.
            setIsLoading(false);

            setNameError(errors.name ? errors.name : null);
            setEmailError(errors.email ? errors.email : null);
            setPhoneError(errors.phone ? errors.phone : null);
            setCountryError(errors.country ? errors.country : null);
            setCityError(errors.city ? errors.city : null);
        }
    };


    return (
        <div id='PanelPage' className='page'>
            <PageContent withBackground isAwaiting={isPageLoading} userRole={userRole}>
                <div className='title w-full mb-4'>
                    <h1 className='main-title text-center mb-2 z-10'>Editar Mis Datos</h1>
                </div>

                <form className='flex flex-col justify-center items-center relative mb-8'>
                    <Input
                        type='text'
                        label='Nombre'
                        variant='underlined'
                        defaultValue=''
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        onBlur={(e) => {
                            setName(e.target.value);
                            setNameError(inputService.validateNotEmpty(name, 'Este campo es requerido.'));
                        }}
                        isInvalid={nameError !== null}
                        errorMessage={nameError}
                        isDisabled={isLoading}
                        className='mb-4'
                    />

                    <Input
                        type='text'
                        label='Apellido'
                        variant='underlined'
                        defaultValue=''
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        isDisabled={isLoading}
                        className='mb-4'
                    />

                    <Input
                        type='text'
                        label='Email'
                        variant='underlined'
                        defaultValue=''
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onBlur={(e) => {
                            setEmail(e.target.value);
                            setEmailError(inputService.validateEmail(email));
                        }}
                        isInvalid={emailError !== null}
                        errorMessage={emailError}
                        isDisabled={isLoading}
                        className='mb-4'
                    />

                    <Input
                        type='text'
                        label='Teléfono'
                        variant='underlined'
                        defaultValue=''
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        onBlur={(e) => {
                            setPhone(e.target.value);
                            setPhoneError(inputService.validatePhone(phone));
                        }}
                        isInvalid={phoneError !== null}
                        errorMessage={phoneError}
                        isDisabled={isLoading}
                        className='mb-4'
                    />

                    <Autocomplete
                        label='País'
                        variant='underlined'
                        disabledKeys={['selectOption']}
                        selectedKey={country}
                        onSelectionChange={(value) => setCountry(value)}
                        onBlur={(e) => {
                            setCountry(e.target.value);
                            setCountryError(inputService.validateNotEmpty(country, 'Este campo es requerido.'));
                        }}
                        isInvalid={countryError !== null}
                        errorMessage={countryError}
                        isDisabled={isLoading}
                        className='mb-4'
                    >
                        {countries.map((animal) => (
                            <AutocompleteItem
                                key={animal.value}
                            >
                                {animal.label}
                            </AutocompleteItem>
                        ))}
                    </Autocomplete>

                    <Input
                        type='text'
                        label='Ciudad'
                        variant='underlined'
                        defaultValue=''
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        onBlur={(e) => {
                            setCity(e.target.value);
                            setCityError(inputService.validateNotEmpty(city, 'Este campo es requerido.'));
                        }}
                        isInvalid={cityError !== null}
                        errorMessage={cityError}
                        isDisabled={isLoading}
                        className='mb-4'
                    />

                    <Input
                        type='text'
                        label='Dirección'
                        variant='underlined'
                        defaultValue=''
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        onBlur={(e) => {
                            setAddress(e.target.value);
                        }}
                        isDisabled={isLoading}
                        className='mb-4'
                    />

                    <Button
                        color='primary'
                        className='w-full uppercase shadow-lg mt-4 mx-auto'
                        radius='sm'
                        onClick={handleUpdateUser}
                        isDisabled={isDisabled || sameForm}
                        isLoading={isLoading}
                        style={{ maxWidth: '300px' }}
                    >
                        Guardar cambios
                    </Button>

                    <Button
                        color='default'
                        className='w-full uppercase shadow-lg mt-2 mx-auto'
                        radius='sm'
                        onClick={() => window.location.href = '/panel-usuario'}
                        isDisabled={isLoading}
                        style={{ maxWidth: '300px' }}
                    >
                        Cancelar
                    </Button>
                </form>
            </PageContent>
        </div>
    );
}

// #TODO: Estaría bueno agregar un modal de success.
// #TODO: Estaría bueno agregar un regex para el teléfono con una bandera.
// #TODO: EStaría bueno agregar un debouncing para el input de email