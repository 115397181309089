// Import modules.
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, AutocompleteItem, Button, DatePicker, Input, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Textarea } from '@nextui-org/react';
import { I18nProvider } from '@react-aria/i18n';
import { getLocalTimeZone, today } from '@internationalized/date';
import { medicalRecordTypes, medicalRecordVaccines, medicalRecordSurgery, medicalRecordsDiseases, medicalRecordsAccidents } from '../../../utils/helpers.js';
import inputService from '../../../services/inputs.js';
import medicalRecordService from '../../../services/medicalRecords.js';

// Import icons.
import Medical from '../../../components/Icons/Medical.jsx';
import Plus from '../../../components/Icons/Plus.jsx';

// Define properties.
AddMedicalRecordModal.propTypes = {
    disclosure: PropTypes.object.isRequired,
    petId: PropTypes.object,
    petSpecie: PropTypes.string,
    callback: PropTypes.func
};


export default function AddMedicalRecordModal({ disclosure, petId, petSpecie, callback }) {
    // Declare states.
    const [type, setType] = useState(null);
    const [typeLabel, setTypeLabel] = useState('Otro');
    const [typeError, setTypeError] = useState('');
    const [description, setDescription] = useState(null);
    const [descriptionItems, setDescriptionItems] = useState([]);
    const [descriptionError, setDescriptionError] = useState('');
    const [date, setDate] = useState(null);
    const [dateError, setDateError] = useState('');
    const [observations, setObservations] = useState(null);
    const [observationsError, setObservationsError] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);


    // Handle form validation.
    useEffect(() => {
        // Set error messages.
        const typeError = inputService.validateNotEmpty(type);
        const descriptionError = inputService.validateNotEmpty(description);
        const dateError = inputService.validateNotEmpty(date);
        const observationsError = inputService.validateNotEmpty(observations);

        setTypeError(typeError);
        setDescriptionError(descriptionError);
        setDateError(dateError);
        setObservationsError(observationsError);

        // Type and decryption should not be empty and there should not be any error.
        if (type !== null  && typeError === null && description !== null && descriptionError === null) { 
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [type, description]);


    // Handle form validation.
    useEffect(() => {
        // Should not be any error.
        if (typeError !== null || descriptionError !== null) {
            setIsDisabled(true);
        }
    }, [typeError, descriptionError]);


    /**
     * Handles the type change.
     *
     * @param {string} type - The type of the input.
     *
     * @returns {void}
     */
    const handleTypeChange = (type) => {
        // Set the type state.
        setType(type);

        const medicalRecordType = medicalRecordTypes.find((item) => item.value === type);

        setTypeLabel(medicalRecordType.label);

        // Set the type label state.
        if (type === 'accidente') {
            setDescriptionItems(medicalRecordsAccidents);
        } else if (type === 'cirugía') {
            setDescriptionItems(medicalRecordSurgery);
        } else if (type === 'enfermedad') {
            setDescriptionItems(medicalRecordsDiseases);
        } else if (type === 'vacuna') {
            setDescriptionItems(medicalRecordVaccines);
        } else {
            setDescriptionItems([]);
        }            
    };


    /**
     * Handle the add medical record event.
     *
     * @returns {void}
     */
    const handleAddMedicalRecord = async () => {
        // Change loading state.
        setIsLoading(true);

        try {
            const response = await medicalRecordService.addMedicalRecord(petId, type, description, date, observations);

            if (!response.success) {
                throw response.errors;
            }

            // Close the modal.
            disclosure.onClose();

            await callback();

            // Reset the form.
            setType(null);
            setDescription(null);
            setDate(null);
            setObservations(null);

            // Change loading state.
            setIsLoading(false);
        } catch (error) {
            // Change loading state.
            setIsLoading(false);

            console.error(error);
        }
    };


    return (
        <>
            <Modal
                backdrop='opaque' 
                isOpen={disclosure.isOpen} 
                onOpenChange={disclosure.onOpenChange}
                placement='center'
                classNames={{
                    wrapper: 'modal',
                    backdrop: 'bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20'
                }}
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className='modal-header flex justify-center items-center gap-4 relative'>
                                <h3 className='uppercase'>Agregar registro</h3>
                                <Medical />
                            </ModalHeader>

                            <ModalBody>
                                {/* Type */}
                                <Autocomplete
                                    label='Tipo de registro'
                                    variant='underlined'
                                    disabledKeys={['selectOption']}
                                    selectedKey={type}
                                    onSelectionChange={handleTypeChange}
                                    onBlur={() => {
                                        setTypeError(inputService.validateNotEmpty(type, 'Este campo es requerido.'));
                                    }}
                                    isInvalid={typeError !== null}
                                    errorMessage={typeError}
                                    isDisabled={isLoading}
                                    className='mb-2'
                                >
                                    {medicalRecordTypes && medicalRecordTypes.map((type) => (
                                        <AutocompleteItem
                                            key={type.value}
                                        >
                                            {type.label}
                                        </AutocompleteItem>
                                    ))}
                                </Autocomplete>

                                {type === 'accidente' || type === 'cirugía' || type === 'enfermedad' || type === 'vacuna' ? (
                                    <Autocomplete
                                        label={typeLabel}
                                        variant='underlined'
                                        disabledKeys={['selectOption']}
                                        selectedKey={description}
                                        onSelectionChange={(value) => setDescription(value)}
                                        onBlur={() => {
                                            setDescriptionError(inputService.validateNotEmpty(description, 'Este campo es requerido.'));
                                        }}
                                        isInvalid={descriptionError !== null}
                                        errorMessage={descriptionError}
                                        isDisabled={isLoading}
                                        className='mb-2'
                                    >
                                        {descriptionItems && descriptionItems.map((item) => {
                                            if (item.specie === undefined || item.specie === 'both' || item.specie === petSpecie) {
                                                return (
                                                    <AutocompleteItem
                                                        key={item.value}
                                                    >
                                                        {item.label}
                                                    </AutocompleteItem>
                                                );
                                            }
                                        })}
                                    </Autocomplete>
                                ) : (
                                    <Input
                                        label={typeLabel}
                                        variant='underlined'
                                        value={description}
                                        onChange={(event) => setDescription(event.target.value)}
                                        onBlur={() => {
                                            setDescriptionError(inputService.validateNotEmpty(description, 'Este campo es requerido.'));
                                        }}
                                        isInvalid={descriptionError !== null}
                                        errorMessage={descriptionError}
                                        isDisabled={isLoading}
                                        className='mb-2'
                                    />
                                )}

                                {/* Date */}
                                <I18nProvider locale='es-ES'>
                                    <DatePicker
                                        label='Fecha'
                                        variant='underlined'
                                        showMonthAndYearPickers
                                        value={date}
                                        maxValue={today(getLocalTimeZone())}
                                        onChange={(date) => setDate(date)}
                                        isInvalid={dateError !== null}
                                        errorMessage={dateError}
                                        isDisabled={isLoading}
                                        className='mb-4'
                                    />
                                </I18nProvider>

                                {/* Observations */}
                                <div className='flex items-center gap-2 mb-3 w-full'>
                                    <Textarea
                                        label='Observaciones'
                                        variant='underlined'
                                        value={observations}
                                        onChange={(event) => setObservations(event.target.value)}
                                        isInvalid={observationsError !== null}
                                        errorMessage={observationsError}
                                        isDisabled={isLoading}
                                    />
                                </div>
                            </ModalBody>

                            <ModalFooter className='flex flex-col gap-2 pt-0'>
                                <Button
                                    color='primary'
                                    className='w-full uppercase shadow-lg mt-1 mx-auto'
                                    radius='sm'
                                    onPress={handleAddMedicalRecord}
                                    style={{ maxWidth: '300px' }}
                                    isDisabled={isDisabled}
                                    isLoading={isLoading}
                                >
                                    <Plus className='fill-light p-1'/>
                                    Agregar
                                </Button>

                                <Button
                                    color='default'
                                    className='w-full uppercase shadow-lg mt-1 mx-auto'
                                    radius='sm'
                                    onPress={onClose}
                                    style={{ maxWidth: '300px' }}
                                    isDisabled={isLoading}
                                >
                                    Cerrar
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
}
