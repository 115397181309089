// Import modules.
import axios from 'axios';


/**
 * Get all the subscriptions of a user.
 *
 * @param {string} id - The id of the user.
 *
 * @returns {Promise} - The axios promise.
 */
export const getPaymentHistory = async (id) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}users/${id}/subscriptions`);

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};


/**
 * Get all the active subscriptions of a user.
 *
 * @param {string} id - The id of the user.
 *
 * @returns {Promise} - The axios promise.
 */
export const getActiveSubscriptions = async (id) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}users/${id}/subscriptions/active`);

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};



/**
 * Get the current subscription of a user.
 *
 * @param {string} id - The id of the pet.
 * @param {string} token - The token of the QR.
 *
 * @returns {Promise} - The axios promise.
 */
export const get = async (id, token) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}users/${id}/subscriptions/${token}`);

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};


/**
 * Create a new subscription.
 *
 * @param {string} userId - The id of the user.
 * @param {string} token - The token of the QR.
 * @param {string} frequency - The frequency of the subscription.
 * @param {string} amount - The amount of the subscription.
 *
 * @returns {Promise} - The axios promise.
 */
export const create = async (userId, token, frequency, amount) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}subscriptions`, {
            userId: userId,
            token: token,
            frequency,
            amount,
        });

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};



/**
 * Cancel a subscription.
 *
 * @param {string} id - The id of the pet.
 *
 * @returns {Promise} - The axios promise.
 */
export const cancel = async (id) => {
    try {
        const response = await axios.delete(`${process.env.REACT_APP_API_ENDPOINT}subscriptions/${id}`);

        return response.data;
    } catch (error) {
        return error.response.data;
    }
};


export default {
    getPaymentHistory,
    getActiveSubscriptions,
    get,
    create,
    cancel,
};