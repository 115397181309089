// Import required modules.
import { React, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import {
    Button,
    Chip,
    Tooltip,
    useDisclosure
} from '@nextui-org/react';

// Import required components.
import AddQR from './AddQR';
import EditQR from './EditQR';
import DeleteQR  from './DeleteQR';
import Alert from '../../../components/Alert/Alert';
import NavbarAdmin from '../../../components/NavbarAdmin/NavbarAdmin';
import AdminTable from '../../../components/AdminTable/AdminTable';

// Import required icons.
import PencilIcon from '../../../components/Icons/Pencil.jsx';
import PlusIcon from '../../../components/Icons/Plus.jsx';
import TrashIcon from '../../../components/Icons/Trash.jsx';

// Import required styles.
import './QRs.css';

// Change the theme color.
// document.querySelector('meta[name="theme-color"]').setAttribute('content',  '#6a9eeb');


const columns = [
    { name: 'QR ID', uid: 'id', sortable: true },
    { name: 'Token QR', uid: 'tokenQr', sortable: true},
    { name: 'Mascota', uid: 'petName', sortable: true},
    { name: 'Estado', uid: 'status', sortable: false},
    { name: 'Acciones', uid: 'actions', sortable: false},
];

const filtereableColumns = [
    { name: 'QR ID', uid: 'id' },
    { name: 'Token QR', uid: 'tokenQr' },
    { name: 'Mascota', uid: 'petName' },
];

const statuses = {
    active: {
        description: 'Activo',
        color: 'success',
    },
    inactive: {
        description: 'Inactivo',
        color: 'warning',
    },
    payment_required: {
        description: 'Pago Requerido',
        color: 'warning',
    },
    null: {
        description: 'Error',
        color: 'danger',
    },
};


function QRs() {
    // Declare states.
    const [QRs, setQRs] = useState([]);
    const [selectedQR, setSelectedQR] = useState(null);

    // Alert.
    const [alertType, setAlertType] = useState();
    const [alertMessage, setAlertMessage] = useState();

    // Session states.
    const [sessionRole, setSessionRole] = useState(null);

    // Disclosures.
    const addModal = useDisclosure();
    const editModal = useDisclosure();
    const deleteModal = useDisclosure();


    useEffect(() => {
        try {
            axios.get(`${process.env.REACT_APP_ENDPOINT}check-session`)
                .then(response => {
                    if (response.data.success) {
                        setSessionRole(response.data.data.role);
                        fetchQRs();
                    } else {
                        setSessionRole(null);
                        window.location.href = '/iniciar-sesion';
                    }
                })
                .catch(() => {
                    setSessionRole(null);
                    window.location.href = '/iniciar-sesion';
                });
        } catch (error) {
            setSessionRole(null);
            window.location.href = '/iniciar-sesion';
        }
    }, []);


    /**
     * Fetch the list of QRs, transform the information and save it in the state.
     *
     * @return void
     */
    const fetchQRs = () => {
        // Fetch the QR list.
        axios.get(`${process.env.REACT_APP_API_ENDPOINT}qrs`)
            .then((response) => {
                // Replace the response object.
                response = response.data;

                // Declare the QRs array.
                const QRData = [];

                response.data.map((qr) => {
                    QRData.push({
                        id: qr.id,
                        tokenQr: qr.tokenQR,
                        status: qr.status,
                        petName: qr.petName !== null ? qr.petName : '',
                        actions: '',
                    });

                    console.log('qr.status: ' , qr.status);
                });

                setQRs(QRData);
            })
            .catch((error) => {
                console.log(error);
            });
    };


    /**
     * Renders the cell of the table according to the column.
     *
     * @param {Object} qr
     * @param {String} columnKey
     * 
     * @return {String} The cell value.
     */
    const renderCell = useCallback((qr, columnKey) => {
        const cellValue = qr[columnKey];

        switch (columnKey) {
            case 'status':
                return (
                    <Chip
                        className='capitalize'
                        size='sm'
                        variant='flat'
                        color={statuses[qr.status].color}
                    >
                        {statuses[qr.status].description}
                    </Chip>
                );
            case 'actions':
                return (
                    <div className=''>
                        {/* <Tooltip content='Editar'> */}
                        <Button
                            className='text-lg text-default-400 cursor-pointer active:opacity-50 p-1'
                            key='editQR'
                            variant='transparent'
                            onPress={() => {
                                setSelectedQR(qr);
                                editModal.onOpen('blur');
                            }}
                            // style={{ minWidth: 'unset' }}
                        >
                            <PencilIcon />
                        </Button>
                        {/* </Tooltip> */}

                        {/* <Tooltip color='danger' content='Eliminar'>                             */}
                        <Button
                            className='text-lg text-default-400 cursor-pointer active:opacity-50 p-1'
                            key='deleteQR'
                            variant='transparent'
                            onPress={() => {
                                setSelectedQR(qr);
                                deleteModal.onOpen('blur');
                            }}
                            // style={{ minWidth: 'unset' }}
                        >
                            <TrashIcon />
                        </Button>
                        {/* </Tooltip> */}
                    </div>
                );
            default:
                return cellValue;
        }
    }, []);



    return (
        <div id='panelQRs' className='panel-admin admin d-flex position-relative'>
            <NavbarAdmin
                role={sessionRole}
            />

            <div id='mainContent' className='flex flex-col container mx-auto mt-14 p-4'>
                {/* Header */}
                <div className='header flex items-center mb-4 w-100'>
                    <h1 className='mr-4'><span className='uppercase'></span>Listado de QRs</h1>

                    <Button
                        key='addQR'
                        className='bg-secondary color-light uppercase shadow-md w-100 py-1 px-6'
                        onPress={() => addModal.onOpen('blur')}
                        radius='sm'
                    >
                        <PlusIcon className='fill-light' />
                        Añadir QR
                    </Button>
                </div>

                {/* Alert */}
                {alertMessage &&
                    <Alert
                        type={alertType}
                        messages={alertMessage}
                    />
                }

                <AdminTable
                    columns={columns}
                    filtereableColumns={filtereableColumns}
                    data={QRs}
                    renderCell={renderCell}
                    type='QRs'
                />
            </div>

            {/* Modal: Agregar QR */}
            <AddQR
                isOpen={addModal.isOpen}
                onClose={addModal.onClose}
                fetchQRs={fetchQRs}
                setAlertType={setAlertType}
                setAlertMessage={setAlertMessage}
            />

            {/* Modal: Editar QR */}
            {selectedQR && <EditQR 
                isOpen={editModal.isOpen}
                onClose={editModal.onClose}
                selectedQR={selectedQR}
                fetchQRs={fetchQRs}
                setAlertType={setAlertType}
                setAlertMessage={setAlertMessage}
            />}

            {/* Modal: Delete QR */}
            {selectedQR && <DeleteQR
                isOpen={deleteModal.isOpen}
                onClose={deleteModal.onClose}
                selectedQR={selectedQR}
                fetchQRs={fetchQRs}
                setAlertType={setAlertType}
                setAlertMessage={setAlertMessage}
            />}
        </div>
    );
}

export default QRs;
