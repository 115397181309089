// Import modules.
import React from 'react';
import PropTypes from 'prop-types';

// Define properties.
CheckCircled.propTypes = {
    className: PropTypes.string,
};


export default function CheckCircled({ className = '' }) {
    return (
        <svg className={'check-circled ' + className} width='96' height='96' viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M45.4602 0.667969C47.3202 0.667969 49.1801 0.667969 51.0401 0.667969C52.6301 0.867969 54.2302 1.00802 55.8202 1.27802C65.7602 2.93801 74.4202 7.21801 81.5502 14.338C92.2402 25.028 96.9502 38.008 95.5802 53.048C94.6602 63.138 90.7102 72.068 83.9602 79.648C74.9902 89.718 63.7102 95.138 50.2502 95.798C40.8602 96.258 32.0302 94.068 24.0002 89.168C11.6502 81.638 4.07015 70.718 1.40015 56.458C1.07015 54.678 0.900156 52.858 0.660156 51.058C0.660156 49.198 0.660156 47.338 0.660156 45.478C0.740156 44.808 0.810152 44.138 0.890152 43.468C2.03015 33.228 5.94015 24.188 12.8401 16.528C20.1701 8.39802 29.2502 3.31796 40.0502 1.40796C41.8402 1.08796 43.6602 0.907969 45.4602 0.667969ZM87.1801 48.278C87.2001 26.878 69.7901 9.44801 48.3102 9.33801C26.9501 9.22801 9.34016 26.818 9.32016 48.258C9.30016 69.658 26.7102 87.088 48.1902 87.198C69.5502 87.308 87.1601 69.718 87.1801 48.278Z' fill='#8CAE56'/>
            <path d='M41.6309 56.2082C41.9809 55.7182 42.1409 55.3982 42.3709 55.1682C50.1609 47.3582 57.9609 39.5582 65.7609 31.7682C67.6309 29.8982 70.0109 29.6582 71.8509 31.1382C73.6609 32.5882 74.0009 35.1882 72.6109 37.0482C72.3309 37.4182 72.0109 37.7582 71.6809 38.0882C62.8509 46.9182 54.0209 55.7482 45.1909 64.5782C42.7909 66.9782 40.4409 66.9682 38.0109 64.5482C33.5209 60.0582 29.0209 55.5682 24.5409 51.0682C22.4809 48.9982 22.5009 46.1882 24.5609 44.4582C26.1309 43.1382 28.3309 43.0881 29.9509 44.3481C30.2909 44.6181 30.6109 44.9181 30.9109 45.2181C34.2209 48.5281 37.5309 51.8282 40.8309 55.1482C41.0709 55.3882 41.2409 55.6982 41.6109 56.2082H41.6309Z' fill='#8CAE56'/>
        </svg>        
    );
}
