import React from 'react';
import { Accordion, AccordionItem } from '@nextui-org/react';

import FadeInDiv from '../Animated/FadeInDiv.jsx';
import ChevronRight from '../Icons/ChevronRight.jsx';
import PawFilled from '../Icons/PawFilled';

import './FAQs.css';

const whatsAppURL = 'https://wa.me/5493416411900';
const whatsAppMessage = 'Hola, necesito ayuda con mi mascota.';


const questions = [
    {
        question: '¿Qué es un Tag?',
        answer: <>
            <p>Un Tag es una identificación QR que contiene toda la información de tu mascota y tus datos de contacto.</p>
            <p>Es muy importante que tu mascota siempre lo lleve consigo. En caso de extravío, quien la encuentre podrá escanear el QR para ver su información y ponerse en contacto rápidamente contigo.</p>
        </>
    },
    {
        question: '¿Cómo obtengo un Tag?',
        answer: <>
            <p>Nuestros Tags se entregan gratuitamente en veterinarias, petshops y protectoras de animales en tu ciudad.</p>
            <p>Si todavía no tenés uno, escribinos a nuestro <a className='underlined-link underlined-link-dark fw-bold' href={`${whatsAppURL}?text=${whatsAppMessage}`}>WhatsApp</a> y estaremos encantados de ayudarte.</p>
        </>
    },
    {
        question: '¿Cómo obtengo Tags con el logo de mi organización?',
        answer: <p>Si querés Tags personalizados con tu logo, escribinos por <a className='underlined-link underlined-link-dark fw-bold' href={`${whatsAppURL}?text=${whatsAppMessage}`}>WhatsApp</a>. Estaremos encantados de diseñar y fabricar Tags que te representen y ayuden a mantener seguras a las mascotas de tu comunidad.</p>
    },
    {
        question: '¿Qué pasos seguir para proteger a mi mascota?',
        answer: <>
            <p>1. Creá tu cuenta y completá el perfil de tu mascota.</p>
            <p>2. Vinculá el Tag al perfil de tu mascota.</p>
            <p>3. Activá el Tag para que esté listo para su uso.</p>

            <p>¡Y eso es todo! Con estos simples pasos, tu mascota estará protegida y, en caso de extravío, su regreso a casa será mucho más fácil.</p>
        </>
    },
    {
        question: '¿Qué pasa si mi mascota se extravía?',
        answer: <>
            <p>Mantené la calma. Tu mascota va a aparecer. Nos esforzaremos al máximo para encontrarla.</p>

            <p><span className='fw-bold'>• Difusión viral:</span> Publicaremos un video en redes sociales y lo compartiremos con nuestra comunidad para viralizar la búsqueda.</p>
            <p><span className='fw-bold'>• Publicidad en redes sociales:</span> Realizaremos campañas publicitarias enfocadas en la zona donde se extravió, aumentando la visibilidad y la posibilidad de encontrarla.</p>
            <p><span className='fw-bold'>• Ubicación GPS:</span> Te informaremos su ubicación tan pronto como su Tag sea escaneado.</p>
            <p><span className='fw-bold'>• Seguimiento constante:</span> Te mantendremos informado(a) en cada paso hasta que tu mascota vuelva contigo.</p>

            <p>Si se perdió tu mascota, avisanos enseguida por <a className='underlined-link underlined-link-dark fw-bold' href={`${whatsAppURL}?text=${whatsAppMessage}`}>WhatsApp</a>. Actuar rápido es fundamental.</p>
        </>
    },
    {
        question: '¿Cómo gestiono la ficha médica de mi mascota?',
        answer: <>
            <p>Desde su perfil, accedé a la ficha médica y registrá todos sus antecedentes clínicos (accidentes, cirugías, vacunas, enfermedades, alergias, etc.). Esta información está siempre disponible, facilitando un seguimiento detallado y organizado de la salud de tu mascota.</p>
            <p>Además, podés compartir la ficha médica con veterinarios cuando sea necesario, garantizando que tu mascota reciba la mejor atención posible.</p>
        </>
    },
    {
        question: '¿Qué precio tiene PataTag?',
        answer: <p>El Tag es gratuito. El precio de PataTag lo decidís vos. Nuestra prioridad es que tu mascota esté segura y que vos estés tranquilo(a). Qu¿eremos que puedas acceder a nuestros servicios sin preocuparte por el costo, por eso te damos la libertad de elegir el precio que mejor se ajuste a tus posibilidades.</p>
    },
    {
        question: '¿Cómo contactarme si necesito ayuda?',
        answer: <p>Estamos acá para ayudarte con todo lo que tu mascota necesite. Escribinos en cualquier momento por <a className='underlined-link underlined-link-dark fw-bold' href={`${whatsAppURL}?text=${whatsAppMessage}`}>WhatsApp</a>, y recibirás atención personalizada.</p>
    },
    {
        question: '¿Dónde obtener más información?',
        answer: <p>Seguinos en nuestro Instagram <a className='underlined-link underlined-link-dark fw-bold' href='https://www.instagram.com/patatag.ok' target='_blank' rel='noopener noreferrer'>@patatag.ok</a> para obtener más información.</p>
    }
];


export default function FAQs() {
    return (
        <div id='faq' className='faq bg-primary py-8 px-2'>
            {/* Title */}
            <FadeInDiv>
                <h2 className='title text-center my-4'><span className='text-white bold'>Preguntas</span> Frecuentes</h2>
            </FadeInDiv>

            {/* Preguntas */}
            <Accordion
                variant='light'
                className='accordion px-2 mx-auto'
            >
                {questions && questions.map((question) => {
                    return (
                        <AccordionItem
                            key={question.key}
                            aria-label={question.question}
                            startContent={<PawFilled className='paw-filled q-6 h-6' />}
                            title={question.question}
                            indicator={<ChevronRight className='q-6 h-6' />}
                            classNames={{
                                heading: 'py-3',
                                trigger: 'py-0',
                            }}
                        >
                            <div className='answer'>{question.answer}</div>
                        </AccordionItem>
                    );
                })}
            </Accordion>
        </div>
    );
}
