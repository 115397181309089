// Import modules.
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter } from '@nextui-org/react';
import CheckCircled from '../../components/Icons/CheckCircled';

// Define properties.
MessageModal.propTypes = {
    disclosure: PropTypes.object.isRequired,
    isAddModal: PropTypes.bool,
};


export default function MessageModal({ disclosure, isAddModal = true }) {
    return (
        <>
            <Modal
                backdrop='opaque' 
                isOpen={disclosure.isOpen} 
                onOpenChange={disclosure.onOpenChange}
                placement='center'
                classNames={{
                    wrapper: 'modal modal-success',
                    backdrop: 'bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20'
                }}
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className='modal-header flex justify-center items-center text-center relative'>
                                <h3 className='uppercase'>Mascota {isAddModal ? 'agregada' : 'editada'} con éxito</h3>
                            </ModalHeader>

                            <ModalBody>
                                <CheckCircled className='mx-auto' />

                                <p className='text-center'>En breve será redireccionado al panel de usuario</p>
                            </ModalBody>

                            <ModalFooter className='flex flex-col gap-2 pt-0'>
                                <Button
                                    color='default'
                                    className='w-full uppercase shadow-lg mt-1 mx-auto'
                                    radius='sm'
                                    onPress={onClose}
                                    style={{ maxWidth: '300px' }}
                                >
                                    Cerrar
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
}
