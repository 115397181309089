// Import modules.
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Accordion, AccordionItem, Button } from '@nextui-org/react';
import userService from '../../services/users.js';
import subscriptionService from '../../services/subscriptions.js';
                        
import PageContent from '../../components/PageContent/PageContent.jsx';

import './PaymentHistory.css';

const status = {
    approved: 'Pagado',
    inactive: 'Inactivo',
    pending: 'Pendiente',
    cancelled: 'Cancelado',
    finished: 'Finalizado',
};


export default function PaymentHistory() {
    // Declare states.
    const [payments, setPayments] = useState();

    // Utilities.
    const [userRole, setUserRole] = useState(null);
    const [isPageLoading, setIsPageLoading] = useState(true);
    
    // Get the user ID from the URL.
    const { id } = useParams();
    const isValidId = /^[0-9]{1,}$/.test(id);

    if (!isValidId) {
        window.location.href = '/panel-usuario';
    }


    // Check user session and fetch the medical records.
    useEffect(() => {
        /**
         * Check user session. The function is declared as async to use the await keyword.
         *
         * @returns {Promise<void>}
         */
        const checkSession = async () => {
            setIsPageLoading(true);

            try {
                const checkSessionResponse = await userService.checkSession();

                if (!checkSessionResponse.success) {
                    throw checkSessionResponse.errors;
                }

                const userSession = JSON.parse(atob(checkSessionResponse.data));

                setUserRole(userSession.role);

                const response = await subscriptionService.getPaymentHistory(userSession.id);

                if (!response.success) {
                    throw response.errors;
                }

                setPayments(response.data);
                setIsPageLoading(false);
            } catch (error) {
                setIsPageLoading(false);
            }
        };

        checkSession();
    }, []);


    return (
        <div id='paymentHistoryPage' className='page'>
            <PageContent withBackground isAwaiting={isPageLoading} userRole={userRole}>
                <div className='flex flex-col justify-between' style={{ minHeight: 'calc(100vh - 80px - 194px - 3rem)' }}>
                    <div className='flex flex-col'>
                        <div className='title w-full mb-4'>
                            <h1 className='main-title text-center mb-2'>Historial de Pagos</h1>
                        </div>
                
                        <div className='flex flex-col w-full' style={{ maxWidth: '500px', margin: '0 auto' }}>
                            {payments && payments.length === 0 && (
                                <div className='empty text-center'>No hay pagos registrados.</div>
                            )}

                            <Accordion
                                className='accordion mx-auto p-0'
                                style={{ maxWidth: '500px' }}
                            >
                                {payments && payments.map((record, index) => (
                                    <AccordionItem
                                        key={index}
                                        title={
                                            <div className='flex flex-col'>
                                                <div className='title'>Pago de Suscripción</div>
                                                <span className={'status ' + record.status}>{status[record.status]}</span>
                                            </div>
                                        }
                                        className='item'
                                        classNames={{ indicator: 'indicator' }}
                                    >
                                        <div className='payment'>
                                            <div className='date flex justify-between'>
                                                <span className='fw-600 color-primary'>Fecha</span>
                                                {record.date && record.date.slice(0, 10)}
                                            </div>

                                            <div className='description flex justify-between'>
                                                <span className='fw-600 color-primary'>Token QR</span>
                                                {record.token}
                                            </div>

                                            <div className='description flex justify-between'>
                                                <span className='fw-600 color-primary'>Mascota</span>
                                                {record.name}
                                            </div>

                                            {/* <div className='description flex justify-between'>
                                        <span className='fw-600 color-primary'>Comprobante</span>
                                        <Ticket />
                                    </div> */}
                                        </div>
                                    </AccordionItem>
                                ))}
                            </Accordion>
                        </div>
                    </div>

                    <Button
                        color='default'
                        className='w-full uppercase shadow-lg mt-2 mx-auto'
                        radius='sm'
                        onClick={() => window.location.href = '/panel-usuario'}
                        style={{ maxWidth: '300px' }}
                    >
                        Volver atrás
                    </Button>
                </div>
            </PageContent>
        </div>
    );
}
