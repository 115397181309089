// Import required modules.
import { React, useEffect, useState } from 'react';
import axios from 'axios';

// Import required components.
import Button from '../../components/Button/Button';
import NavbarAdmin from '../../components/NavbarAdmin/NavbarAdmin';
import Footer from '../../components/Footer/Footer';

// Import required icons.
// import PawIcon from '../../components/Icons/PawIcon';
import PawRotatedBoldIcon from '../../components/Icons/PawRotatedBoldIcon';
import QRCode from '../../components/Icons/QRCode.jsx';
// import UserIcon from '../../components/Icons/UserIcon';

// Import required styles.
import './AdminPanel.css';

// Change the theme color.
// document.querySelector('meta[name="theme-color"]').setAttribute('content',  '#6a9eeb');

const navLinks = [
    {
        id: 'qrs',
        to: '/panel-admin/qrs',
        title: 'QRs',
        icon: <QRCode />,
        roles: ['admin'],
    },
    // {
    //     id: 'usuarios',
    //     to: '/panel-administrador/usuarios',
    //     title: 'Usuarios',
    //     icon: <UserIcon />,
    //     roles: ['admin'],
    // },
    // {
    //     id: 'mascotas',
    //     to: '/panel-administrador/mascotas',
    //     title: 'Mascotas',
    //     icon: <PawIcon />,
    //     roles: ['admin'],
    // },
];


function AdminPanel() {
    // Session states.
    const [sessionRole, setSessionRole] = useState(null);


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_ENDPOINT}check-session`)
            .then(response => {
                if (response.data.success) {
                    setSessionRole(response.data.data.role);
                } else {
                    setSessionRole(null);
                }
            })
            .catch(() => {
                setSessionRole(null);
            });
    }, []);


    return (
        <div id='panelAdministrador'>
            <NavbarAdmin
                role={sessionRole}
            />
            
            <div id='mainContent' className='flex flex-col container mx-auto mt-14 p-4 relative'>
                {navLinks.map((link) => (
                    <Button
                        key={link.id}
                        id={link.id}
                        to={link.to}
                        type='light'
                        icon={link.icon}
                        text={`Panel de ${link.title}`}
                        className='nav-link w-full'
                    />
                ))}

                {/* Icons */}
                <PawRotatedBoldIcon className='absolute paw-rotated-bold-icon-1 z-1' />
            </div>
            
            <Footer />
        </div>
    );
}

export default AdminPanel;
