// Import required modules.
import { React, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
    Button as NextUIButton,
    Navbar as NavbarNextUI,
    NavbarBrand,
    NavbarContent,
    NavbarMenu,
    NavbarMenuToggle,
} from '@nextui-org/react';

// Import required components.
import Button from '../Button/Button';

// Import required images.
import logo from '../../assets/images/logo/patatag-logo.png';

// Import required styles.
import './NavbarAdmin.css';

const navLinks = [
    {
        id: 'volver',
        to: '/',
        title: 'Volver al sitio',
        roles: ['', 'user', 'admin'],
    },
    {
        id: 'panelUsuario',
        to: '/panel-usuario',
        title: 'Panel de Usuario',
        roles: ['admin'],
    },
    {
        id: 'panelAdministrador',
        to: '/panel-administrador',
        title: 'Panel de Administrador',
        roles: ['admin'],
    },
    {
        id: 'qrs',
        to: '/panel-administrador/qrs',
        title: 'QRs',
        roles: ['admin'],
    },
    {
        id: 'usuarios',
        to: '/panel-administrador/usuarios',
        title: 'Usuarios',
        roles: ['admin'],
    },
    {
        id: 'mascotas',
        to: '/panel-administrador/mascotas',
        title: 'Mascotas',
        roles: ['admin'],
    },
];


function NavbarAdmin({ role }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <NavbarNextUI
            id='navbarAdmin'
            isBordered
            isMenuOpen={isMenuOpen}
            onMenuOpenChange={setIsMenuOpen}
            className='bg-secondary-light relative'
            classNames={{
                header: 'bg-primary-light',
            }}
        >
            {/* Desktop Menu Detal */}
            <NavbarContent id='desktopMenu' className='flex align-center'>
                <Link to='/'>
                    <NavbarBrand>
                        <img
                            id='logoDesktop'
                            src={logo}
                            alt='Patatag Logo'
                            className=''
                        />
                    </NavbarBrand>
                </Link>

                <div className='flex flex-row justify-end items-center'>
                    {navLinks.map((navLink) => {
                        if (navLink.roles.includes(role)) {
                            return (
                                <Link
                                    to={navLink.to}
                                    className='desktop-nav-item'
                                    key={navLink.id}
                                >
                                    <NextUIButton
                                        key={navLink.id}
                                        className='color-dark font-semibold p-3'
                                    >
                                        {navLink.title === 'QRs' ? navLink.title : navLink.title.toUpperCase()}
                                    </NextUIButton>
                                </Link>
                            );
                        }

                        return null;
                    })}
                </div>
            </NavbarContent>
            
            {/* Logo mobile */}
            <NavbarContent className='flex' justify='center'>
                <Link to='/'>
                    <NavbarBrand>
                        <img
                            id='logo'
                            src={logo}
                            alt='Patatag Logo'
                            className='absolute left-1/2 transform -translate-x-1/2 z-10'
                        />
                    </NavbarBrand>
                </Link>
            </NavbarContent>

            {/* Hamburger menu */}
            <NavbarContent
                id='hamburgerMenu'
                className='color-light'
                justify='end'
            >
                <NavbarMenuToggle aria-label={isMenuOpen ? 'Close menu' : 'Open menu'} />
            </NavbarContent>

            {/* Menu detail */}
            <NavbarMenu>
                <div className='flex flex-col justify-center text-center mt-14'>
                    {navLinks.map((navLink) => {
                        if (navLink.roles.includes(role)) {
                            return (
                                <Button
                                    key={navLink.id}
                                    to={navLink.to}
                                    type='secondary'
                                    text={navLink.title}
                                    onClick={() => setIsMenuOpen(false)}
                                    className='mb-2'
                                />
                            );
                        }

                        return null;
                    })}
                </div>
            </NavbarMenu>
        </NavbarNextUI>
    );
}

export default NavbarAdmin;

NavbarAdmin.propTypes = {
    role: PropTypes.string.isRequired,
};
