import React from 'react';


export default function PawFilled({ ...props }) {
    return (
        <svg
            className={'paw-filled '}
            width='31'
            height='27'
            viewBox='0 0 31 27'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <path d='M21.0001 26.6002C20.6001 26.6002 20.2001 26.6001 19.8001 26.5001C18.9001 26.3001 18.0001 26.1 17.1001 25.9C16.1001 25.7 15.2001 25.8001 14.3001 26.0001C13.6001 26.2001 13.0001 26.4002 12.3001 26.6002C11.6001 26.8002 10.9001 26.9003 10.1001 26.7003C7.30008 26.1003 6.10008 22.6 8.10008 20.4C8.50008 20 8.90006 19.6003 9.30006 19.2003C10.2001 18.3003 10.9001 17.2001 11.4001 16.0001C11.7001 15.3001 11.9001 14.6001 12.2001 14.0001C12.7001 12.8001 13.5001 11.9001 14.7001 11.5001C16.5001 10.9001 18.3001 11.5999 19.2001 13.2999C19.6001 13.9999 19.8001 14.7 20.1001 15.4C20.1001 15.5 20.2001 15.6002 20.2001 15.6002C20.8001 17.3002 21.9001 18.6002 23.3001 19.6002C23.9001 20.1002 24.4001 20.6 24.7001 21.4C25.6001 24.4 23.2001 26.7002 21.0001 26.6002Z' />
            <path d='M16.1001 5.70034C16.1001 3.80034 17.0001 2.30005 18.6001 1.40005C19.9001 0.700049 21.1001 0.600146 22.4001 1.50015C22.6001 1.70015 22.8001 1.80024 23.0001 2.10024C23.7001 3.10024 24.0001 4.20005 23.7001 5.40005C23.3001 7.10005 22.4001 8.30015 20.9001 9.00015C20.0001 9.40015 19.1001 9.40015 18.3001 9.00015C17.4001 8.60015 16.8001 7.90005 16.5001 6.90005C16.2001 6.60005 16.1001 6.20034 16.1001 5.70034Z' />
            <path d='M0.300049 13.8998C0.300049 12.4998 0.600055 11.5997 1.40005 10.7997C2.20005 9.9997 3.10007 9.7999 4.20007 9.9999C5.50007 10.1999 6.50005 10.9999 7.30005 11.9999C7.80005 12.6999 8.10007 13.4998 8.20007 14.3998C8.30007 15.7998 7.90007 17.0001 6.70007 17.7001C5.70007 18.3001 4.70007 18.2999 3.70007 17.9999C1.40007 17.2999 0.300049 15.0998 0.300049 13.8998Z' />
            <path d='M30.3 13.0002C30.4 14.8002 29 17.1004 26.6 17.7004C25.7 17.9004 24.8 17.8001 24 17.4001C23.6 17.2001 23.3 16.9003 23.1 16.6003C22.4 15.7003 22.2 14.7002 22.5 13.5002C23 11.5002 24.2 10.2003 26.2 9.60026C27.3 9.30026 28.3 9.59997 29.2 10.3C29.8 10.8 30.2 11.6001 30.3 12.4001C30.4 12.7001 30.3 12.8002 30.3 13.0002Z' />
            <path d='M14.0001 6.1001C14.0001 6.7001 13.8001 7.5998 13.2001 8.2998C12.4001 9.2998 11.4001 9.8 10.1001 9.5C8.20011 9.1 7.20011 7.9001 6.60011 6.1001C6.20011 4.9001 6.3001 3.8002 6.9001 2.7002C7.5001 1.7002 8.40011 1.1001 9.60011 1.1001C10.6001 1.1001 11.5001 1.5001 12.3001 2.1001C13.4001 3.1001 14.0001 4.3001 14.0001 6.1001Z' />
        </svg>
    );
}
