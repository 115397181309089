import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter } from '@nextui-org/react';
import subscriptionService from '../../../services/subscriptions.js';

import Danger from '../../../components/Icons/DangerIcon.jsx';

CancelSubscriptionModal.propTypes = {
    disclosure: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    petName: PropTypes.string.isRequired,
};


export default function CancelSubscriptionModal({ disclosure, id, petName }) {
    const [isLoading, setIsLoading] = useState(false);


    /**
     * Handle the delete subscription action.
     *
     * @returns {void}
     */
    const handleCancelSubscription = async () => {
        // Change loading state.
        setIsLoading(true);

        const response = await subscriptionService.cancel(id);

        if (response.success) {
            disclosure.onClose();

            window.location.reload();
        }
    };


    return (
        <>
            <Modal
                backdrop='opaque' 
                isOpen={disclosure.isOpen} 
                onOpenChange={disclosure.onOpenChange}
                placement='center'
                classNames={{
                    wrapper: 'modal-danger',
                    backdrop: 'bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20'
                }}
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className='modal-header flex justify-center items-center gap-4 relative'>
                                <h3 className='uppercase text-center'>Cancelar suscripción</h3>
                            </ModalHeader>

                            <ModalBody>
                                <Danger className='fill-danger mx-auto' />

                                <p className='text-center'>¿Estás seguro de que deseas cancelar esta suscripción?</p>
                                <p className='text-center'>{petName} quedará sin protección.</p>
                            </ModalBody>

                            <ModalFooter className='flex flex-col gap-2 pt-0'>
                                <Button
                                    color='danger'
                                    className='w-full uppercase shadow-lg mt-1 mx-auto'
                                    radius='sm'
                                    onPress={handleCancelSubscription}
                                    style={{ maxWidth: '300px' }}
                                    isLoading={isLoading}
                                >
                                    Cancelar
                                </Button>

                                <Button
                                    color='default'
                                    className='w-full uppercase shadow-lg mt-1 mx-auto'
                                    radius='sm'
                                    onPress={onClose}
                                    style={{ maxWidth: '300px' }}
                                    isDisabled={isLoading}
                                >
                                    Cerrar
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
}
