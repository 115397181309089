// Import modules.
import { React, useState, useEffect } from 'react';
import axios from 'axios';
import { GoogleLogin } from '@react-oauth/google';
import { Button, Input } from '@nextui-org/react';
import { Link } from 'react-router-dom';
import accountService from '../../services/account.js';
import userService from '../../services/users.js';

// Import components.
import PageContent from '../../components/PageContent/PageContent.jsx';

// Import icons.
import Eye from '../../components/Icons/Eye.jsx';
import EyeSlash from '../../components/Icons/EyeSlash.jsx';

axios.defaults.withCredentials = true;


export default function Login() {
    // Declare states.
    const [email, setEmail] = useState(null);
    const [emailError, setEmailError] = useState('');
    const [password, setPassword] = useState(null);
    const [passwordError, setPasswordError] = useState('');
    const [passwordIsVisible, setPasswordIsVisible] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    // Handle form validation.
    useEffect(() => {
        // Set error messages.
        const emailError = userService.validateEmail(email);
        const passwordError = userService.validateNotEmpty(password, 'Este campo es requerido.');

        setEmailError(emailError);
        setPasswordError(passwordError);

        // Check if the submit button should be enabled.
        if (email !== null && password !== null && emailError === null && passwordError === null) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [email, password]);

    // Handle form validation.
    useEffect(() => {
        if (emailError !== null || passwordError !== null) {
            setIsDisabled(true);
        }
    }, [emailError, passwordError]);


    /**
     * Handle the login request.
     *
     * @return void.
     */
    const handleLogIn = async () => {
        // Change the loading state.
        setIsDisabled(true);
        setIsLoading(true);

        // Login request.
        try {
            const response = await userService.login(email, password);

            if (!response.success) {
                throw response.errors;
            }

            window.location.href = '/panel-usuario';
        } catch (errors) {
            // Set error messages.
            setEmailError(errors.email ? errors.email[0] : null);
            setPasswordError(errors.password ? 'Usuario o contraseña incorrectos' : null);
    
            // Change states.
            setIsLoading(false);
        }
    };


    /**
     * Handle the Google login error.
     *
     * @param {Object} error The error object.
     *
     * @return void.
     */
    const handleError = (error) => {
        console.error('error: ', error);
    };


    /**
     * Handle the Google login success.
     *
     * @param {Object} credentialResponse The credential response object.
     *
     * @return void.
     */
    const handleSuccess = async (credentialResponse) => {
        if (credentialResponse.credential) {
            try {
                const userLoginResponse = await accountService.loginGoogle(credentialResponse.credential);
                console.log('userLoginResponse', userLoginResponse);

                if (!userLoginResponse.success) {
                    throw userLoginResponse.errors;
                }

                const userResponse = await userService.login(userLoginResponse.data.email, null, 'google');
                console.log('userResponse', userResponse);

                if (!userResponse.success) {
                    throw userResponse.errors;
                }
    
                window.location.href = '/panel-usuario';
            } catch (errors) {
                console.error('errors: ', errors);
            }
        }
    };


    return (
        <div id='loginPage' className='page'>
            <PageContent withBackground isAwaiting={false} userRole={null}>
                <form id='loginForm' className='flex flex-col justify-center items-center z-10'>
                    <h1 className='main-title mb-4 z-10'>Iniciar Sesión</h1>

                    <Input
                        type='email'
                        label='Mail'
                        variant='underlined'
                        defaultValue=''
                        onChange={(e) => setEmail(e.target.value)}
                        onBlur={(e) => {
                            setEmail(e.target.value);
                            setEmailError(userService.validateEmail(email));
                        }}
                        isInvalid={emailError !== null}
                        errorMessage={emailError}
                        className='mb-2 z-10'
                    />

                    <Input
                        type={passwordIsVisible ? 'text' : 'password'}
                        label='Contraseña'
                        variant='underlined'
                        defaultValue=''
                        onChange={(e) => setPassword(e.target.value)}
                        onBlur={(e) => {
                            setPassword(e.target.value);
                            setPasswordError(userService.validateNotEmpty(password, 'Este campo es requerido.'));
                        }}
                        isInvalid={passwordError !== null}
                        errorMessage={passwordError}                
                        endContent={
                            <button className='focus:outline-none' type='button' onClick={() => setPasswordIsVisible(!passwordIsVisible)}>
                                {passwordIsVisible ? (
                                    <EyeSlash className='text-2xl text-default-400 pointer-events-none w-5 h-5' />
                                ) : (
                                    <Eye className='text-2xl text-default-400 pointer-events-none w-5 h-5' />
                                )}
                            </button>
                        }
                        className='mb-3 z-10'
                    />

                    <p className='text-sm text-gray-500 mb-6 z-10'>
                        <Link to='/recuperar-contraseña' className='underlined-link underlined-link-dark'>Olvidé mi contraseña</Link>
                    </p>

                    <Button
                        color='primary'
                        radius='sm'
                        className='w-full uppercase shadow-lg mb-2 z-10'
                        onPress={handleLogIn}
                        isDisabled={isDisabled}
                        isLoading={isLoading}
                        style={{ maxWidth: '300px' }}
                    >
                        Iniciar Sesión
                    </Button>

                    <Button
                        color='dark'
                        radius='sm'
                        shadow='lg'
                        className='w-full btn-dark uppercase shadow-lg mb-2 z-10'
                        onPress={() => window.location.href = '/registro'}
                        isDisabled={isLoading}
                        style={{ maxWidth: '300px' }}
                    >
                        Crear una cuenta
                    </Button>

                    {/* Google Login */}
                    <div
                        style={{
                            borderRadius: '8px',
                            zIndex: '10'
                        }}
                    >
                        <GoogleLogin
                            useOneTap
                            onError={handleError}
                            onSuccess={handleSuccess}
                            size='large'
                            radius='lg'
                            text='Continuar con Google'
                            width={300}
                            style={{ maxWidth: '300px' }}
                        />
                    </div>
                </form>
            </PageContent>
        </div>
    );
}

// #TODO: Estaría bueno que al apretar enter dentro del form podamos emular el click en el botón de login.
// #TODO: Estaría bueno poder utilizar un modal para mostrar el success.
// #TODO: Revisar si funciona el auth con google de manera correcta. No se puede probar en local.
//        Eliminar los console.log.