// Import modules.
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter } from '@nextui-org/react';
import QRCode from 'react-qr-code';

// Import icons.
import { QRCode as QRCodeIcon } from '../../../components/Icons/QRCode.jsx';

// Define properties.
ViewTagModal.propTypes = {
    disclosure: PropTypes.object.isRequired,
    token: PropTypes.string,
};

const petProfileURL = 'https://patatag.com/perfil/';


export default function ViewTagModal({ disclosure, token }) {
    return (
        <>
            <Modal
                backdrop='opaque' 
                isOpen={disclosure.isOpen} 
                onOpenChange={disclosure.onOpenChange}
                placement='center'
                classNames={{
                    backdrop: 'bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20'
                }}
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className='modal-header flex justify-center items-center gap-4 relative'>
                                <h3 className='uppercase'>Ver Tag</h3>
                                <QRCodeIcon />
                            </ModalHeader>

                            <ModalBody>
                                <QRCode
                                    id='qrCode'
                                    size={120}
                                    value={petProfileURL + token}
                                    className='mx-auto mb-1'
                                />

                                <p className='text-center'>Este es el QR de tu mascota</p>
                            </ModalBody>

                            <ModalFooter className='pt-0'>
                                <Button
                                    color='default'
                                    className='w-full uppercase shadow-lg mt-2 mx-auto'
                                    radius='sm'
                                    onPress={onClose}
                                    style={{ maxWidth: '300px' }}
                                >
                                    Cerrar
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
}
